import { useMutation, UseMutationOptions } from 'react-query';

import { getValidationTableDetailsCsv } from '@zero5/garage-api';

import { handleError } from '@/utils/handleError';

type Response = unknown;
type Params = {
  companyId: number;
  fromDate: number;
  toDate: number;
};

const useExportValidationHistoryMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'exportValidationHistory',
    async (params) => {
      const response = await getValidationTableDetailsCsv(params.companyId, params);

      return response;
    },
    {
      ...options,
      onError: (error) => {
        handleError(error, 'Error while exporting validation history.');
      },
    },
  );
};

export default useExportValidationHistoryMutation;
