import {
  TableEvent as EventTableResponse,
  EventData as EventResponse,
  GetCountEventResponseType,
  GetEventsResponseType,
} from '@zero5/garage-api';

import { Event } from '@/api/models/events';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import {  formatTimeStampToString, getDaysInMonth } from '@/utils/date/helpers';
import getDayWithSuffix from '@/utils/getDayWithSuffix';

// eslint-disable-next-line import/prefer-default-export
export const mapEvent = (eventResponse: EventTableResponse): Event => {
  const from = formatTimeStampToString(eventResponse.eventStartTime, 'MM/dd/yyyy');
  const to = formatTimeStampToString(eventResponse.eventEndTime, 'MM/dd/yyyy');
  const startTime = formatTimeStampToString(eventResponse.eventStartTime, 'h:mm a');
  const endTime = formatTimeStampToString(eventResponse.eventEndTime, 'h:mm a');
  const reservedSpots = eventResponse.requestedValetSpaces + eventResponse.requestedParkingSpaces;
  return {
    eventId: eventResponse.eventId,
    eventExternalId: eventResponse.eventExternalId || emptyFieldStub,
    name: eventResponse.eventName || emptyFieldStub,
    host: eventResponse.companyName || emptyFieldStub,
    date: `${from} - ${to}`,
    startTime: eventResponse.eventStartTime,
    endTime: eventResponse.eventEndTime,
    time: `${startTime} - ${endTime}`,
    status: eventResponse.status || emptyFieldStub,
    reservedSpots: reservedSpots || emptyFieldStub,
    eventStartTime: eventResponse.eventStartTime,
    contactFirstName: eventResponse.contactFirstName || emptyFieldStub,
    contactLastName: eventResponse.contactLastName || emptyFieldStub,
    contactEmail: eventResponse.contactEmail || emptyFieldStub,
    contactPhone: eventResponse.contactPhone || emptyFieldStub,
    valetSpaces: eventResponse.requestedValetSpaces,
    parkingSpaces: eventResponse.requestedParkingSpaces,
    parkingPrice: eventResponse.parkingPrice,
    valetPrice: eventResponse.valetPrice,
    gracePeriod: eventResponse.gracePeriod,
    isPrivate: eventResponse.isPrivate,
  };
};

export function isFieldEmpty(field: string): boolean {
  return field === emptyFieldStub;
}

export const mapEventsResponse = (
  activitiesResponse: GetEventsResponseType,
) => activitiesResponse.events.map(mapEvent);

export const mapCountPendingEventsResponse = (
  response: GetCountEventResponseType,
) => ({
  pendingCount: response.pendingCount || emptyFieldStub,
  scheduledCount: response.scheduledCount || emptyFieldStub,
  upcomingCount: response.upcomingCount || emptyFieldStub,
});

export const mapNearestEvent = (event: EventResponse | null)=>{
  if (!event){
    return emptyFieldStub;
  }

  const dayInMonth = getDaysInMonth(event.eventStartTime);
  return getDayWithSuffix(dayInMonth) + formatTimeStampToString(event.eventStartTime, 'MMMM');
};
