import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationTableSummary } from '@zero5/garage-api';

import { validationHistoryQueryKeys } from './queryKeys';
import { mapAccountValidationSummary } from './mappings';

type Response = ReturnType<typeof mapAccountValidationSummary>;
type Key = ReturnType<typeof validationHistoryQueryKeys.accountValidationSummary>;
type Params = {
  companyId: number;
  fromDate: number;
  toDate: number;
};

const useAccountValidationSummaryQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    validationHistoryQueryKeys.accountValidationSummary(params),
    async () => {
      const response = await getValidationTableSummary(params.companyId, {
        fromDate: params.fromDate,
        toDate: params.toDate,
      });

      return mapAccountValidationSummary(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useAccountValidationSummaryQuery;
