import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import QRCode from 'qrcode.react';

import {
  Button,
  Checkbox,
  Input,
  InputPhone,
  InputPrice,
  Modal,
  ModalProps,
  Row,
} from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';
import schemas from '@zero5/ui/lib/utils/validation/schemas';
import { EventStatus } from '@zero5/garage-api';
import toast from '@zero5/ui/lib/utils/toast';
import { centsToCost, costToCents } from '@zero5/ui/lib/utils/formatters/formatPrice';

import { Event as EventType } from '@/api/models/events';
import useUpdateEventReqMutation from '@/api/event/useUpdateEventReqMutation';

import { validateNumber } from '@/utils/validateNumber';

import CalendarImage from '@/assets/images/calendar.svg';

import DatePicker from '../common/DatePicker';
import TimePicker from '../common/SelectTimePicker';

interface Props extends Omit<ModalProps, 'children'> {
  currentEvent: EventType;
}

const EventEditModal: React.FC<Props> = ({ currentEvent, onClose, ...props }) => {
  return (
      <StyledModal
          title={currentEvent?.status === EventStatus.CONFIRMED ? 'Approved Event' : 'Requested Event'}
          onClose={onClose}
          fullScreenSize="580px"
          {...props}>
        <Content currentEvent={currentEvent} onClose={onClose} />
      </StyledModal>
  );
};

type ContentProps = Pick<Props, 'currentEvent' | 'onClose'>;

const validationSchema = Yup.object().shape({
  valetSpaces: schemas.digitsSchema
    .label('Valet Spaces')
    .required(),
  parkingSpaces: schemas.digitsSchema
    .label('Parking Spaces')
    .required(),
  valetPrice: Yup.string()
    .required()
    .label('Valet Prices')
    .matches(/^\d+(.\d{1,2})?$/g, 'accept only numbers')
    .required('Valet Prices is reqiured')
    .nullable()
    .max(10, 'Too Long!'),
  parkingPrice: Yup.string()
    .required()
    .label('Parking Prices')
    .matches(/^\d+(.\d{1,2})?$/g, 'accept only numbers')
    .required('Parking Prices is reqiured')
    .nullable()
    .max(10, 'Too Long!'),
});

const Content: React.FC<ContentProps> = ({ currentEvent, onClose }) => {
  const updateMutation = useUpdateEventReqMutation({
    onSuccess: () => {
      onClose();
    },
    onError: (error: Error) => {
      const message = error.message === 'Invalid email or password' ? 'Invalid old password!' : error.message;
      toast('error', message);
    },
  });
  const formik = useFormik({
    initialValues: {
      eventId: currentEvent.eventId,
      eventName: currentEvent.name,
      date: currentEvent.date,
      time: currentEvent.time,
      startTime: currentEvent.startTime,
      endTime: currentEvent.endTime,
      host: currentEvent.host,
      valetSpaces: currentEvent.valetSpaces,
      parkingSpaces: currentEvent.parkingSpaces,
      firstName: currentEvent.contactFirstName,
      lastName: currentEvent.contactLastName,
      email: currentEvent.contactEmail,
      phoneNumber: currentEvent.contactPhone,
      parkingPrice: currentEvent.parkingPrice,
      valetPrice: currentEvent.valetPrice,
      isPrivate: currentEvent.isPrivate,
    },
    onSubmit: async ({
      eventId,
      valetPrice,
      valetSpaces,
      parkingSpaces,
      parkingPrice,
    }) => {
      await updateMutation.mutateAsync({
        eventId,
        requiredValetSpaces: +valetSpaces,
        requiredParkingSpaces: +parkingSpaces,
        valetPrice: valetPrice ? Number(valetPrice) : 0,
        parkingPrice: parkingPrice ? Number(parkingPrice) : 0,
      });
    },
    validationSchema,
  });

  const transformedInputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formik.setFieldValue(e.target.name, value);
    }, [formik],
  );

  const transformToNumberChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement>) => {
    formik.setFieldValue(e.target.name, validateNumber(e.target.value));
  };

  const isEventApproved = useMemo(()=>{
    return currentEvent?.status === EventStatus.CONFIRMED
    || currentEvent?.status === EventStatus.APPROVED_ADMIN;
  }, [currentEvent]);

  return (
      <Wrapper data-test="event-edit-modal">
        <ImageWrapper>
          {currentEvent?.status === EventStatus.CONFIRMED
            ?
              <QrCodesMainWrapper>
                <QrCodeWrapper data-test="event-qr-regular-parking">
                  <h4>REGULAR PARKING</h4>
                  <QRCode
                      size={150}
                      value={`${process.env.PAY_PAGE_EVENT}/${currentEvent?.eventExternalId}/get-regular-code`} />
                </QrCodeWrapper>
                <QrCodeWrapper data-test="event-qr-valet-parking">
                  <h4>VALET PARKING</h4>
                  <QRCode
                      size={150}
                      value={`${process.env.PAY_PAGE_EVENT}/${currentEvent?.eventExternalId}/get-valet-code`} />
                </QrCodeWrapper>
                <QrCodeWrapper data-test="event-qr-vendor-parking">
                  <h4>VENDOR PARKING</h4>
                  <QRCode
                      size={100}
                      value={`${process.env.PAY_PAGE_EVENT}/${currentEvent?.eventExternalId}/get-vendor-code`} />
                </QrCodeWrapper>
              </QrCodesMainWrapper>
            : <StyledImage src={CalendarImage} /> }
        </ImageWrapper>
        <form onSubmit={formik.handleSubmit}>
          <ContentWrapper>
            <SectionTitle>
              Event Information
            </SectionTitle>
            <Grid>
              <StyledInput
                  columns={6}
                  label="Event Name"
                  id="eventName"
                  name="eventName"
                  onChange={formik.handleChange}
                  value={formik.values.eventName}
                  disabled
              />
              <StyledInput
                  columns={6}
                  label="Host"
                  id="host"
                  name="host"
                  onChange={formik.handleChange}
                  value={formik.values.host}
                  disabled
              />
              <StyledDatePicker
                  columns={6}
                  pickerProps={{
                    selectsRange: true,
                    startDate: new Date(formik.values.startTime),
                    endDate: new Date(formik.values.endTime),
                    // selected: new Date(formik.values.date),
                    onChange: () => {},
                    placeholderText: 'mm/dd/yyyy',
                    disabled: true,
                  }}
                  label="Date"
              />
              <StyledTimePicker
                  columns={6}
                  disabled
                  startTime={formik.values.startTime}
                  endTime={formik.values.endTime}
                  onChangeStartTime={(hours) => formik.setFieldValue('startTime', hours)}
                  onChangeEndTime={(hours) => formik.setFieldValue('endTime', hours)}
                  error={Boolean(
                    (formik.touched.endTime && formik.errors.endTime)
                      || (formik.touched.startTime && formik.errors.startTime),
                  )}
                  helperText={formik.errors.endTime || formik.errors.startTime}
              />
              <StyledInput
                  oneColumnOrder={1}
                  columns={6}
                  label="Required Valet Spaces"
                  name="valetSpaces"
                  onChange={transformToNumberChangeHandler}
                  value={formik.values.valetSpaces}
                  error={Boolean(formik.touched.valetSpaces && formik.errors.valetSpaces)}
                  helperText={formik.errors.valetSpaces}
                  disabled={isEventApproved}
              />
              <StyledInput
                  oneColumnOrder={3}
                  columns={6}
                  label="Required Parking Spaces"
                  id="parkingSpaces"
                  name="parkingSpaces"
                  onChange={transformToNumberChangeHandler}
                  value={formik.values.parkingSpaces}
                  error={Boolean(formik.touched.parkingSpaces && formik.errors.parkingSpaces)}
                  helperText={formik.errors.parkingSpaces}
                  disabled={isEventApproved}
              />
              <StyledInputPrice
                columns={6}
                oneColumnOrder={2}
                label="Valet Price (per Car)"
                id="valetPrice"
                name="valetPrice"
                value={centsToCost(formik.values.valetPrice ?? undefined)}
                placeholder="0.00"
                onChangeValue={(value) => formik.setFieldValue('valetPrice', costToCents(value))}
                error={Boolean(formik.touched.valetPrice && formik.errors.valetPrice)}
                helperText={formik.errors.valetPrice}
                disabled={isEventApproved}
              />
              <StyledInputPrice
                columns={6}
                oneColumnOrder={4}
                label="Parking Price (per Car)"
                id="parkingPrice"
                name="parkingPrice"
                value={centsToCost(formik.values.parkingPrice ?? undefined)}
                placeholder="0.00"
                onChangeValue={(value) => formik.setFieldValue('parkingPrice', costToCents(value))}
                error={Boolean(formik.touched.parkingPrice && formik.errors.parkingPrice)}
                helperText={formik.errors.parkingPrice}
                disabled={isEventApproved}
              />
            </Grid>
            <SectionTitle>
              Settings
            </SectionTitle>
            <SettingContent>
              <CheckboxLabel>
                <StyledCheckbox
                    checked={formik.values.isPrivate}
                    disabled
                />
              </CheckboxLabel>
              <CheckboxDescription>
                Apply default event parking fee for the entire lot
              </CheckboxDescription>
            </SettingContent>
            <SectionTitle>
              Contact Information
            </SectionTitle>
            <Grid>
              <StyledInput
                  columns={6}
                  label="First Name"
                  onChange={transformedInputChangeHandler}
                  value={formik.values.firstName}
                  disabled
                  transform="capitalize"
              />
              <StyledInput
                  columns={6}
                  label="Last Name"
                  onChange={transformedInputChangeHandler}
                  value={formik.values.lastName}
                  disabled
                  transform="capitalize"
              />
              <StyledInput
                  columns={6}
                  label="Email"
                  onChange={transformedInputChangeHandler}
                  value={formik.values.email}
                  disabled
                  transform="lowercase"
              />
              <StyledInputPhone
                  columns={6}
                  label="Phone Number"
                  value={formik.values.phoneNumber}
                  disabled
              />
            </Grid>
            <ButtonsRow justifyContent="flex-end">
              {(currentEvent?.status === EventStatus.REQUESTED)
              && (
                  <StyledButton
                      variant="contained"
                      type="submit"
                      loading={formik.isSubmitting}
                      color="primary"
                  >
                    Confirm
                  </StyledButton>
              )}
              {/* <StyledButton
              variant="contained"
              type="submit"
              disabled={(currentEvent?.status !== EventStatus.REQUEST_PENDING)
            || Boolean(formik.errors.valetSpaces && formik.touched.valetPrice)
            || Boolean(formik.errors.valetPrice && formik.touched.valetPrice)
            || Boolean(formik.errors.parkingPrice && formik.touched.parkingPrice)
            || Boolean(formik.errors.parkingSpaces && formik.touched.parkingSpaces)}
              loading={formik.isSubmitting}
              color="primary"
            >
              Confirm

            </StyledButton> */}
              <Button
                variant="text"
                color="primary"
                onClick={onClose}
                data-test="event-edit-modal-cancel-btn"
              >
                Cancel
              </Button>
            </ButtonsRow>
          </ContentWrapper>
        </form>
      </Wrapper>
  );
};

const StyledModal = styled(Modal)`
  @media (max-width: 1220px) {
    margin: auto 30px;
  }

  @media (max-width: 800px) {
    margin: 30px auto;
  }

  @media (max-width: 580px) {
    margin: auto;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 380px) minmax(420px, 700px);

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QrCodesMainWrapper = styled.div`
  align-items: center;
  text-align: center;
`;
const QrCodeWrapper = styled.div`
  margin-bottom: 60px;
`;

const StyledImage = styled.img``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 1px solid ${vars.palette.border};

  @media (max-width: 800px) {
    padding-left: 0;
    padding-top: 20px;
    border-left: none;
    border-top: 1px solid ${vars.palette.border};
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
`;

const columnStyles = css<{ columns: number; oneColumnOrder?: number; }>`
  grid-column: span ${({ columns }) => columns};
  grid-row: span 1;

  @media (max-width: 520px) {
    grid-column: span 12;
    ${({ oneColumnOrder }) => (oneColumnOrder ? `order: ${oneColumnOrder};` : '')}
  }
`;

const StyledInput = styled(Input)`
  ${columnStyles}
`;

const StyledInputPhone = styled(InputPhone)`
  ${columnStyles}
`;

const StyledInputPrice = styled(InputPrice)`
  ${columnStyles}
`;

const StyledDatePicker = styled(DatePicker)`
  ${columnStyles}
`;

const StyledTimePicker = styled(TimePicker)`
  ${columnStyles}
  display: flex;
  align-items: flex-end;
  & > span {
    line-height: 22px;
    padding: 9px 6px;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
  min-width: 90px;
`;

const ButtonsRow = styled(Row)`
  margin-top: auto;
`;

const SettingContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const CheckboxDescription = styled.div`

  @media (max-width: 420px) {
    min-width: unset;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

export default EventEditModal;
