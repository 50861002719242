import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';

import {
  BigCategoryCard,
  CategoryCard,
  Row,
  RowsMap,
  Skeleton,
  Table,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useRevenueDetailsQuery from '@/api/revenue/useRevenueDetailsQuery';
import useRevenueCardsQuery from '@/api/revenue/useRevenueCardsQuery';

import { selectRevenueSettings } from '@/store/selectors/settings';
import { settingsActions } from '@/store/actions/settings';

import Role, { useFindCurrentAction } from '@/components/common/Role';
import Page from '@/components/common/page';
import Reports from '@/components/revenue/Reports';
import Forecasted from '@/components/revenue/Forecasted';
import Invoices from '@/components/revenue/Invoices';
import RevenueTrendChart from '@/components/revenue/RevenueTrend';

import useDateModule from '@/utils/date/useDateModule';
import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';
import { disputeTooltipText } from '@/utils/text/disputeTooltipText';

interface Props {}

enum TabsEnum {
  FORECASTED_REVENUE,
  REVENUE_REPORTS,
  INVOICES,
}

const getFirstAvailable = <T extends unknown>(items: Array<{ available: boolean; item: T; }>) => {
  return items.find(({ available }) => available);
};

const Revenue: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const findCurrentAction = useFindCurrentAction();
  const { formatTimeStampToString } = useDateModule();

  const {
    getEndOfMonth,
    getEndOfWeek,
    getMonthAndYear,
    getStartOfMonth,
    getStartOfWeek,
  } = useDateModule();

  const [tab, setTab] = React.useState<TabsEnum | null>(() => {
    const tabsAndRoles = [
      {
        available: findCurrentAction('revenue:forecasted') !== 'h',
        item: TabsEnum.FORECASTED_REVENUE,
      },      
      {
        available: findCurrentAction('revenue:reports') !== 'h',
        item: TabsEnum.REVENUE_REPORTS,
      },
      // {
      //   available: findCurrentAction('revenue:invoices') !== 'h',
      //   item: TabsEnum.INVOICES,
      // },
    ];

    const availableTab = getFirstAvailable(tabsAndRoles);

    return typeof availableTab === 'undefined' ? null : availableTab.item;
  });

  let styles = {
    default_tab:{
      // color: 'red',
      // backgroundColor: 'yellow',
      // fontWeight: 400,
      minWidth: '100px',
    },
    // active_tab:{
    //   color: 'green',
    // },
  };

  const {
    revenueHistoryDateRange,
    revenueHistoryFormat,
    trendDateRange,
  } = useSelector(selectRevenueSettings);

  const revenueHistoryDateRangeChangeHandler = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setRevenueSettings({
      revenueHistoryDateRange: newDateRange,
    }));
  }, [dispatch]);

  const trendDateRangeChangeHandler = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setRevenueSettings({
      trendDateRange: newDateRange,
    }));
  }, [dispatch]);

  const {
    data: revenueCards,
    isLoading: revenueCardsLoading,
  } = useRevenueCardsQuery();

  const revenueDetailsQuery = useRevenueDetailsQuery();

  useEffect(() => {
    const { start, end } = revenueHistoryDateRange;
    if (revenueHistoryFormat === 'weekly') {
      revenueHistoryDateRangeChangeHandler({
        start: start && getStartOfWeek(start),
        end: end && getEndOfWeek(end),
      });
    }
    if (revenueHistoryFormat === 'monthly') {
      revenueHistoryDateRangeChangeHandler({
        start: start && getStartOfMonth(start),
        end: end && getEndOfMonth(end),
      });
    }
  }, [revenueHistoryFormat]);

  const cardsData = [
    {
      title: 'Total Revenue (fcst)',
      data:  formatPrice(revenueCards?.total || 0),
      // changes: revenueInformationData?.total.percentage || 0,
      subtext: getMonthAndYear(new Date()),
      widgetId: 'revenue:stats:totalRevenue',
    },
    {
      title: 'Revenue (fcst)',
      data: formatPrice(revenueCards?.revenue || 0),
      // changes: revenueInformationData?.detached.percentage || 0,
      subtext: getMonthAndYear(new Date()),
      widgetId: 'revenue:stats:revenue',
    },
    {
      title: 'Disputes and Refunds',
      data: formatPrice(Math.abs(revenueCards?.disputes || 0)),
      // changes: revenueInformationData?.events.percentage || 0,
      subtext: getMonthAndYear(new Date()),
      widgetId: 'revenue:stats:disputes',
      tooltipText: disputeTooltipText,
    },
    {
      title: 'Pending Payments ',
      data: formatPrice(revenueCards?.pending || 0),
      // changes: revenueInformationData?.permits.percentage || 0,
      subtext: getMonthAndYear(new Date()),
      widgetId: 'revenue:stats:pending',
      // tooltipText: ' ',
    },
  ];

  const revenueDetailsRowsMap = React.useMemo<RowsMap<{
    revenueStream: string;
    amount: number;
  }>>(() => [
    {
      id: 'revenueStream',
      title: 'Revenue Stream',
      data: ({ revenueStream }) => revenueStream,
      disableSorting: true,
    },
    {
      id: 'amount',
      title: 'Amount',
      data: ({ amount }) => formatPrice(amount),
      disableSorting: true,
    },
  ], []);

  const revenueDetailsData = React.useMemo(() => [
    {
      revenueStream: 'Transient',
      amount: revenueDetailsQuery.data?.transient || 0,
    },
    {
      revenueStream: 'Permit (forecasted)',
      amount: revenueDetailsQuery.data?.permit || 0,
    },
    {
      revenueStream: 'Event (forecasted)',
      amount: revenueDetailsQuery.data?.event || 0,
    },
    {
      revenueStream: 'Validation (forecasted)',
      amount: revenueDetailsQuery.data?.validation || 0,
    },
  ], [
    revenueDetailsQuery.data?.event,
    revenueDetailsQuery.data?.permit,
    revenueDetailsQuery.data?.transient,
    revenueDetailsQuery.data?.validation,
  ]);

  const headerContent = useMemo(
    () => (
      <Row wrap="wrap" gap="10px">
        <DateText>
          {formatTimeStampToString(new Date().getTime(), 'MMMM, yyyy')}
        </DateText>
      </Row>
    ),
    [],
  );

  return (
    <Page>
      <Role widgetId="revenue:stats" action="r">
        <CardsWrapper>
          {cardsData.map(({ widgetId, ...props }) => (
              <Role widgetId={widgetId} action="r">
                <CategoryCard key={props.title} loading={revenueCardsLoading} {...props} />
              </Role>
          ))}
        </CardsWrapper>
      </Role>
      <Role widgetId="revenue:trends" action="r">
        <ChartsWrapper>
          <RevenueTrendChart
            dateRange={trendDateRange}
            onDateRangeChange={trendDateRangeChangeHandler}
          />
          <BigCategoryCard
            title="Revenue Details"
            gap="10px"
            data-test="revenue-trend-stream"
            headerContent={headerContent}
          >
            {revenueDetailsQuery.isLoading ? (
              <Skeleton height="234px" variant="rect" />
            ) : (
              <RevenueDetailsTable
                data={revenueDetailsData}
                rowsMap={revenueDetailsRowsMap}
                withoutPagination
              />
            )}
          </BigCategoryCard>
        </ChartsWrapper>
      </Role>
      <StyledTabs
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(ignoredEvent, newValue) => setTab(newValue)}
      >
        <Role widgetId="revenue:forecasted" action="r">
          <Tab label="Forecasted Revenue" style={styles.default_tab} />
        </Role>        
        <Role widgetId="revenue:reports" action="r">
          <Tab label="Revenue Reports" style={styles.default_tab} />
        </Role>
        <Role widgetId="revenue:invoices" action="r">
          <Tab label="Invoices" style={styles.default_tab} />
        </Role>
      </StyledTabs>
      {tab === TabsEnum.FORECASTED_REVENUE && (
        <Forecasted />
      )} 
      {tab === TabsEnum.REVENUE_REPORTS && (
        <Reports />
      )}
      {tab === TabsEnum.INVOICES && (
        <Invoices />
      )}
    </Page>
  );
};

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 30px / 2));
  grid-gap: 30px;
  margin-bottom: 35px;
  min-height: 400px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }

  @media (max-width: 520px) {
    min-height: 300px;
  }
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;
`;

const RevenueDetailsTable = styled(Table)`
  th:first-child, td:first-child {
    text-align: left;
  }

  th:last-child, td:last-child {
    text-align: right;
  }
` as typeof Table;

const DateText = styled.span`
 color: #A2A2A2;
 font-size: 14px;
`;

export default flow( 
  withGarage,
  withRole('revenue'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(Revenue);
