import React from 'react';
import styled from 'styled-components';

import {
  RowsMap,
  Table,
  TableContent,
} from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useAccountValidationDetailsQuery from '@/api/validationHistory/useAccountValidationDetailsQuery';
import { AccountValidationDetailsItem } from '@/api/validationHistory/models';

import Price from '@/components/common/Price';

import useDateModule from '@/utils/date/useDateModule';

interface Props {
  companyId: number;
  fromDate: number;
  toDate: number;
}

const DetailsSection: React.FC<Props> = ({
  fromDate,
  toDate,
  companyId,
}) => {
  const {
    formatFullDate,
  } = useDateModule();

  const [forecastedRevenueDetailsPage, setForecastedRevenueDetailsPage] = React.useState(0);
  const [forecastedRevenueDetailsRowsPerPage, setForecastedRevenueDetailsRowsPerPage] = React.useState(10);

  const accountValidationSummaryQuery = useAccountValidationDetailsQuery({
    fromDate,
    toDate,
    companyId,
    take: forecastedRevenueDetailsRowsPerPage,
    skip: forecastedRevenueDetailsPage * forecastedRevenueDetailsRowsPerPage,
  });

  const revenueDetailsRowsMap = React.useMemo(() => {
    const details: RowsMap<AccountValidationDetailsItem> = [
      {
        title: 'Date',
        id: 'date',
        data: ({ date }) => formatFullDate(date),
        disableSorting: true,
        HeadCellProps: {
          width: 194,
          align: 'left',
        },
        CellProps: {
          align: 'left',
        },
      },
      {
        title: 'Validation Type',
        id: 'type',
        data: ({ type }) => type,
        disableSorting: true,
        HeadCellProps: {
          width: 100,
          align: 'left',
        },
        CellProps: {
          align: 'left',
        },
      },
      {
        title: 'Note',
        id: 'note',
        data: ({ note }) => note,
        disableSorting: true,
        HeadCellProps: {
          width: 169,
          align: 'left',
        },
        CellProps: {
          align: 'left',
        },
      },
      {
        title: 'Amount',
        id: 'amount',
        data: ({ amount }) => <Price>{amount}</Price>,
        disableSorting: true,
        HeadCellProps: {
          width: 116,
          align: 'right',
        },
        CellProps: {
          align: 'right',
        },
      },
      {
        title: 'Total Amount',
        id: 'totalAmount',
        data: ({ totalAmount }) => formatPrice(totalAmount),
        disableSorting: true,
        HeadCellProps: {
          width: 165,
          align: 'right',
        },
        CellProps: {
          align: 'right',
        },
      },
    ];

    return details;
  }, [formatFullDate]);

  return (
    <StyledTableContent
      isLoading={accountValidationSummaryQuery.isLoading}
      data={accountValidationSummaryQuery.data?.rows}
    >
      <StyledTransactionsTable
        rowsMap={revenueDetailsRowsMap}
        data={accountValidationSummaryQuery.data?.rows!}
        page={forecastedRevenueDetailsPage}
        onPageChange={(e, page) => setForecastedRevenueDetailsPage(page)}
        onRowsPerPageChange={(e) => setForecastedRevenueDetailsRowsPerPage(Number(e.target.value))}
        count={accountValidationSummaryQuery.data?.rowsCount!}
        isLoading={accountValidationSummaryQuery.isFetching}
        minWidth="1008px"
        data-test="forecasted-report-modal-details"
      />
    </StyledTableContent>
  );
};

const TransactionsTable = styled(Table)`
  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  
  & thead tr {
    background: #E7E5E5;
  }
  
  & tr:nth-of-type(2n) {
    background: #E7E5E5;
  }
`;

const StyledTableContent = styled(TableContent)`
  min-height: 200px;
`;

const StyledTransactionsTable = styled(TransactionsTable)`
  margin-top: 10px;
` as typeof Table;

export default DetailsSection;