import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

import {
  Button, DataCard, formatPhone, Row, SimpleRowsMap, SimpleTable,
} from '@zero5/ui';

import { PermitGarage } from '@/api/permit/models';
import { Garage } from '@/api/models/garages';
import { mapPermitOwnerName } from '@/api/permit/mappings';
import useDeactivatePermitMutation from '@/api/permit/useDeactivatePermitMutation';
import useSetAutoRenewFlagPermitMutation from '@/api/permit/useSetAutoRenewFlagPermitMutation';

import { getPrettyCost } from '@/components/utils/getPrettyCost';
import Switch from '@/components/Switch';
import Role, { useFindCurrentAction } from '@/components/common/Role';

import useDateModule from '@/utils/date/useDateModule';

import { permitStatusToView } from '@/containers/Permit';

import Status from '../common/Status';

interface Props {
  permit: PermitGarage;
  garage: Garage;
  onClose: () => void;
}

const PermitInformation: React.FC<Props> = ({ permit, garage, onClose }) => {
  const [isAutoRenewEnabled, setIsAutoRenewEnabled] = useState(permit.permit.isAutoRenewEnabled);
  const findCurrentAction = useFindCurrentAction();
  const { formatTimeStampToString } = useDateModule();
  const isDeactivated = useMemo(() => (
    permit.permit.status === 'DEACTIVATED'
  ), [permit]);
  const canChangeAutoRenew = useMemo(() => (
    !isDeactivated && !permit.permit.isMarkedForRemoval
  ), [permit, isDeactivated]);

  const permitInfoRowsMap = React.useMemo<SimpleRowsMap<{
    isEnabledState: boolean;
    permit: PermitGarage['permit'];
    garage: Garage;
  }>>(() => [
    {
      title: 'Garage Name',
      data: ({ garage: { label } }) => label,
    },
    {
      title: 'Garage Address',
      data: ({ garage: { address } }) => address,
    },
    {
      title: 'Permit Type',
      data: ({ permit: { type } }) => type.name,
    },
    {
      title: 'Permit Status',
      data: ({ permit: { status } }) => {
        const view = permitStatusToView(status);
        return (
          <Status color={view.color}>
            {view.text}
          </Status>
        );
      },
    },
    {
      title: 'Permit Date',
      data: ({ permit: { startTime, endTime } }) => (
        `${formatTimeStampToString(startTime, 'MM/dd/yyyy')} - ${formatTimeStampToString(endTime, 'MM/dd/yyyy')}`
      ),
    },
    {
      title: 'Auto Renew',
      data: ({ isEnabledState }) => (
        <SwitchWrapper>
          <Switch
            label=""
            value={isEnabledState}
            onClick={() => setIsAutoRenewEnabled((prev) => !prev)}
            disabled={!canChangeAutoRenew || (findCurrentAction('permit') !== 'w')}
          />
        </SwitchWrapper>
      ),
    },
  ], [formatTimeStampToString, canChangeAutoRenew, findCurrentAction]);

  const driverInfoRowsMap = React.useMemo<SimpleRowsMap<PermitGarage['driver']>>(() => [
    {
      title: 'Name',
      data: (driver) => (driver ? `${driver.firstName} ${driver.lastName}` : '-'),
    },
    {
      title: 'Email',
      data: (driver) => driver?.email ?? '-',
    },
    {
      title: 'Phone Number',
      data: (driver) => (driver?.phone ? formatPhone(driver.phone) : '-'),
    },
  ], []);

  const ownerInfoRowsMap = React.useMemo<SimpleRowsMap<PermitGarage>>(() => [
    // {
    //   title: 'Owner Type',
    //   data: ({ owner }) => owner.type,
    // },
    {
      title: 'Owner Name',
      data: ({ owner }) => mapPermitOwnerName(owner),
    },
    {
      title: 'Permit Rate',
      data: ({ permit: { cost } }) => getPrettyCost(cost),
    },
    {
      title: 'Billing Date',
      data: ({ billing }) => billing?.billingDate !== undefined
        ? formatTimeStampToString(billing.billingDate, 'MM/dd/yyyy')
        : undefined,
    },
  ], []);

  const vehicleInfoRowsMap = React.useMemo<SimpleRowsMap<PermitGarage['vehicle']>>(() => [
    {
      title: 'License Plate',
      data: ({ lpNum }) => lpNum,
    },
    {
      title: 'State',
      data: ({ lpState }) => lpState,
    },
    {
      title: 'Vehicle Make',
      data: ({ make }) => make,
    },
    {
      title: 'Vehicle Model',
      data: ({ model }) => model,
    },
    {
      title: 'Vehicle Color',
      data: ({ color }) => color,
    },
  ], []);

  const deactivatePermitMutation = useDeactivatePermitMutation({
    onSuccess: () => onClose?.(),
  });

  const setAutoRenewFlagMutation = useSetAutoRenewFlagPermitMutation({
    onSuccess: () => onClose?.(),
  });

  const isButtonsDisabled = useMemo(() => (
    deactivatePermitMutation.isLoading || setAutoRenewFlagMutation.isLoading
  ), [deactivatePermitMutation.isLoading, setAutoRenewFlagMutation.isLoading]);

  return (
    <>
      {permit.permit.qr ? (
        <QRCodeWrapper data-test="permit-info-modal-qr">
          <QRCode size={100} value={permit.permit.qr} />
        </QRCodeWrapper>
      ) : null }
      <PermitDataCard label="Permit Info" direction="column">
        <SimpleTable
          rowsMap={permitInfoRowsMap}
          item={{
            permit: permit.permit,
            garage,
            isEnabledState: isAutoRenewEnabled,
          }}
          align="left"
        />
      </PermitDataCard>
      <PermitDataCard label="Owner Info" direction="column" data-test="permit-info-modal-owner-info">
        <SimpleTable rowsMap={ownerInfoRowsMap} item={permit} align="left" />
      </PermitDataCard>
      <StyledDataCard label="Driver Info" direction="column" data-test="permit-info-modal-driver-info">
        <SimpleTable rowsMap={driverInfoRowsMap} item={permit.driver!} align="left" />
      </StyledDataCard>
      <StyledDataCard label="Vehicle Info" direction="column" data-test="permit-info-modal-vehicle-info">
        <SimpleTable rowsMap={vehicleInfoRowsMap} item={permit.vehicle} align="left" />
      </StyledDataCard>
      {
        isDeactivated ? (
          <Row justifyContent="flex-end">
            <Button
              variant="text"
              color="primary"
              disabled={isButtonsDisabled}
              onClick={onClose}
              data-test="permit-info-modal-close-btn"
            >
              Close
            </Button>
          </Row>
        ) : (
          <Row justifyContent="flex-end">
            <Role widgetId="permit:deactivate" action="w">
              <StyledButton
                variant="contained"
                color="secondary"
                disabled={isButtonsDisabled}
                onClick={() => deactivatePermitMutation.mutate({ permitId: permit.permit.permitId })}
                data-test="permit-info-modal-deactivate-btn"
              >
                Deactivate
              </StyledButton>
            </Role>
            <Role widgetId="permit:deactivate" action="w">
              <StyledButton
                variant="contained"
                color="primary"
                disabled={isButtonsDisabled || permit.permit.isAutoRenewEnabled === isAutoRenewEnabled}
                onClick={() => setAutoRenewFlagMutation.mutate({
                  permitId: permit.permit.permitId,
                  isAutoRenewEnabled,
                })}
                data-test="permit-info-modal-save-btn"
              >
                Save
              </StyledButton>
            </Role>
            <Button
              variant="text"
              color="primary"
              disabled={isButtonsDisabled}
              onClick={onClose}
              style={{ justifyContent: 'flex-end' }}
              data-test="permit-info-modal-close-btn-security-role"
            >
              Close
            </Button>
          </Row>
        )
      }
    </>
  );
};

const QRCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 150px;
  justify-content: center;
`;

const StyledDataCard = styled(DataCard)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  th {
    width: 150px;
  }

  @media (max-width: 550px) {
    th {
      width: 100px;
    }
  }
`;

const PermitDataCard = styled(StyledDataCard)`
  table {
    border-collapse: collapse;
  }

  th {
    height: 28px;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default PermitInformation;
