import { useMutation, UseMutationOptions } from 'react-query';

import { createValidation } from '@zero5/garage-api';

type Response = unknown;
type Params = {
  email: string;
  workTime: number;
  licensePlate?: string;
};

export const queryKey = 'createValidationReceipt';

const useCreateValidationReceiptMutation = (
  options?: UseMutationOptions<Response, Error, Params, [typeof queryKey]>,
) => {
  return useMutation(
    [queryKey],
    async (params: Params) => {
      const response = await createValidation(params);
      return response;
    },
    {
      ...options,
    },
  );
};

export default useCreateValidationReceiptMutation;
