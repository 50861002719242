import React from 'react';

import StatusRectangle from '@/components/common/StatusRectangle';

interface Props {
  service: 'TRANSIENT' | 'EVENT' | 'PERMIT' | 'VALIDATION' | 'DISPUTE' | 'DISPUTE_WON' | 'REFUND';
}

const mapServiceToView = {
  TRANSIENT: {
    label: 'Transient',
    color: '#CBE2D1',
  },
  EVENT: {
    label: 'Event',
    color: '#D0D0E8',
  },
  PERMIT: {
    label: 'Permit',
    color: '#F6D9CB',
  },
  VALIDATION: {
    label: 'Validation',
    color: '#F6DFE8',
  },
  DISPUTE: {
    label: 'Dispute',
    color: '#F7EEC9',
  },
  DISPUTE_WON: {
    label: 'Dispute Won',
    // TODO: The color is now the same as DISPUTE. Maybe we should change it
    color: '#F7EEC9',
  },
  REFUND: {
    label: 'Refund',
    // TODO: The color is now the same as DISPUTE. Maybe we should change it
    color: '#F7EEC9',
  },
};

const Service: React.FC<Props> = ({ service }) => {
  return (
    <StatusRectangle color={mapServiceToView[service].color} label={mapServiceToView[service].label} />
  );
};

export default Service;
