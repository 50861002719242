import { useQuery, UseQueryOptions } from 'react-query';

import { searchWatchlistVehicles } from '@zero5/garage-api';

import { watchlistQueryKeys } from './queryKeys';
import { mapWatchlistTableItems } from './mappings';

type Response = ReturnType<typeof mapWatchlistTableItems>;
type Key = ReturnType<typeof watchlistQueryKeys.watchlistVehicleSearch>;
type Params = {
  lpNum: string;
};

const useSearchWatchlistVehiclesQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    watchlistQueryKeys.watchlistVehicleSearch(params.lpNum),
    async () => {
      const response = await searchWatchlistVehicles({
        lpNum: params.lpNum,
      });

      return mapWatchlistTableItems(response.watchlistVehicles);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useSearchWatchlistVehiclesQuery;
