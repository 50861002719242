import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationTypesAvailableNow } from '@zero5/garage-api';

import { validationQueryKeys } from './queryKeys';

type Response = Array<unknown>;
type Key = ReturnType<typeof validationQueryKeys.validationTypesAvailableNow>;

export const key = 'get-validation-trend';

const useValidationTypesAvailableNowQuery = (
  options?: UseQueryOptions<Response, Error, Response, Key>,
) => {
  return useQuery(
    validationQueryKeys.validationTypesAvailableNow(),
    async () => {
      const response = await getValidationTypesAvailableNow();

      // add mapping here if access to the data is needed
      return response.validationTypes;
    },
    {
      ...options,
      refetchOnWindowFocus: false,
    },
  );
};

export default useValidationTypesAvailableNowQuery;
