export const mapTransactionTypeToTitle = {
  ONLINE: 'Online',
  PAY_STATION: 'Pay Station',
  DRIVER_INVOICE: 'Online',
  COMPANY_INVOICE: 'Online',
};

// export const mapPaymentMethodToTitle = {
//   ONLINE: 'Online',
//   PAY_STATION: 'Pay Station',
// };
