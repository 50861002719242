import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getTotalRevenueDay } from '@zero5/payment-api';

import useDateModule from '@/utils/date/useDateModule';

import { queryGroupName } from './queryGroupName';

type Response = number;

export const queryKey: QueryKey = 'TotalRevenueToday';

const useTotalRevenueTodayQuery = (
  options?: UseQueryOptions<Response, unknown, Response, [typeof queryGroupName, typeof queryKey]>,
) => {
  const { getTimestampOfTodayStart, getTimestampOfTodayEnd } = useDateModule();

  return useQuery<Response, unknown, Response, [typeof queryGroupName, typeof queryKey]>(
    [queryGroupName, queryKey],
    async () => {
      const from = getTimestampOfTodayStart();
      const until = getTimestampOfTodayEnd();

      const response = await getTotalRevenueDay({ from, until });

      return response.revenueValue;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useTotalRevenueTodayQuery;
