import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
  BigCategoryCard,
  LineChart,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';

import useRevenueTrendQuery from '@/api/revenue/useRevenueTrendQuery';

import useDateModule from '@/utils/date/useDateModule';

interface Props {
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
}

const RevenueTrendChart: React.FC<Props> = ({ dateRange }) => {
  const { formatMonthAndDay, formatTimeStampToString } = useDateModule();

  const {
    data: revenueTrendData,
    isLoading: revenueTrendLoading,
  } = useRevenueTrendQuery({
    from: dateRange.start.getTime(),
    until: dateRange.end.getTime(),
  });

  const revenueValueArray = revenueTrendData?.map(({ value }) => value) || [];
  const revenueDateArray = revenueTrendData?.map(({ date }) => formatMonthAndDay(date)) || [];

  const headerContent = useMemo(
    () => (
      <DateText>
        {formatTimeStampToString(new Date().getTime(), 'MMMM, yyyy')}
      </DateText>
    ),
    [formatTimeStampToString],
  );

  return (
    <BigCategoryCard
      title="Revenue Trend"
      gap="10px"
      data-test="revenue-trend"
      headerContent={headerContent}
    >
      <LineChart
        data={revenueValueArray}
        labels={revenueDateArray}
        isLoading={revenueTrendLoading}
        priceFormat
      />
    </BigCategoryCard>
  );
};

const DateText = styled.span`
  color: #A2A2A2;
  font-size: 14px;
  line-height: 36px;
`;

export default RevenueTrendChart;