import {
  EventParkingFeeType as EventParkingFeeTypeResponse,
  GarageHoursOpenWithFreeTime,
  GarageOptions as GarageOptionsResponse } from '@zero5/garage-api';
import { WeekDay } from '@zero5/garage-api';

import {
  EventParkingFeeType, GarageDay, GarageHoursPolicy, GarageOptions,
} from '../models/garages';

const serverDayToDay: Record<WeekDay, GarageDay> = {
  MONDAY: GarageDay.MONDAY,
  TUESDAY: GarageDay.TUESDAY,
  WEDNESDAY: GarageDay.WEDNESDAY,
  THURSDAY: GarageDay.THURSDAY,
  FRIDAY: GarageDay.FRIDAY,
  SATURDAY: GarageDay.SATURDAY,
  SUNDAY: GarageDay.SUNDAY,
};

const dayToServerDay: Record<GarageDay, WeekDay> = {
  MONDAY: WeekDay.MONDAY,
  TUESDAY: WeekDay.TUESDAY,
  WEDNESDAY: WeekDay.WEDNESDAY,
  THURSDAY: WeekDay.THURSDAY,
  FRIDAY: WeekDay.FRIDAY,
  SATURDAY: WeekDay.SATURDAY,
  SUNDAY: WeekDay.SUNDAY,
};

export const mapGarageHoursPolicy = (response: GarageHoursOpenWithFreeTime): GarageHoursPolicy => ({
  id: response.id,
  garageId: response.garageId,
  day: serverDayToDay[response.day],
  start: response.start,
  end: response.end,
  cost: response.cost,
  isClosed: response.isClosed,
  freeTimeMin: response.freeTimeMin,
});

export const unmapGarageHoursPolicy = (data: GarageHoursPolicy): GarageHoursOpenWithFreeTime => ({
  id: data.id,
  garageId: data.garageId,
  day: dayToServerDay[data.day],
  start: data.start,
  end: data.end,
  cost: data.cost,
  isClosed: data.isClosed,
  freeTimeMin: data.freeTimeMin,
});

// eslint-disable-next-line max-len
export const mapGarageHoursPolicies = (response: Array<GarageHoursOpenWithFreeTime>) => response.map(mapGarageHoursPolicy);

export const unmapGarageHoursPolicies = (data: Array<GarageHoursPolicy>) => data.map(unmapGarageHoursPolicy);

const eventParkingFeeServerToEventParkingFee: Record<EventParkingFeeTypeResponse, EventParkingFeeType> = ({
  HOURLY_RATE: EventParkingFeeType.HOURLY_RATE,
  FLAT_FEE: EventParkingFeeType.FLAT_FEE,
});

export const mapGarageOptions = (response: GarageOptionsResponse): GarageOptions => ({
  maximumDailyFee: response.maximumDailyFee,
  isMaximumDailyFeeApplicable: response.isMaximumDailyFeeApplicable,
  eventParkingFeeType: eventParkingFeeServerToEventParkingFee[response.eventParkingFeeType],
  eventParkingFee: response.eventParkingFee,
  isEventParkingFeeApplicable: response.isEventParkingFeeApplicable,
  eventBeforeGraceMin: response.eventBeforeGraceMin,
  isFreeTimeMinApplicable: response.isFreeTimeMinApplicable,
  eventAfterGraceMin: response.eventAfterGraceMin,
  freeTimeMin: response.freeTimeMin,
  isMultipleFreePeriods: response.isMultipleFreePeriods ?? true,
  gracePeriodMin: response.gracePeriodMin,
  isGracePeriodMinApplicable: response.isGracePeriodMinApplicable,
});
