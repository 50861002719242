import { ValidationTypeData as ValidationTypelib } from '@zero5/garage-api';


import { ValidationType } from '../models/ValidationType';

export const mapValidationType = (validationTypeLib: ValidationTypelib): ValidationType =>{
  const { costCents, maxValidationTimeMin, ...restValidationTypeLib } = validationTypeLib;

  const hours = Math.round(maxValidationTimeMin / 60);

  return {
    ...restValidationTypeLib,
    price: costCents,
    hours,
  };
};

export const mapValidationTypeList = (validationTypeLibList: ValidationTypelib[]): ValidationType[] =>{
  return validationTypeLibList.map(mapValidationType);
};