import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLElement> {}

const PasswordInfo: React.FC<Props> = (props) => {
  return (
    <Wrapper {...props}>
      <PasswordInfoTitle>The password has to contain at least:</PasswordInfoTitle>
      <PasswordInfoList>
        <PasswordInfoListItem>8 characters</PasswordInfoListItem>
        <PasswordInfoListItem>1 letter in upper and lower case</PasswordInfoListItem>
        <PasswordInfoListItem>1 number</PasswordInfoListItem>
        <PasswordInfoListItem>1 special character</PasswordInfoListItem>
      </PasswordInfoList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const PasswordInfoTitle = styled.p`
  margin: 0 0 10px 0;
`;

const PasswordInfoList = styled.ul`
  padding-left: 25px;
  margin: 0;
`;

const PasswordInfoListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export default PasswordInfo;
