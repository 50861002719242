import { createActionCreators } from 'immer-reducer';
import assertNever from 'assert-never';

import { AnswerCallResult, CallSession, SessionResultType } from '@zero5/client-admin-api';
import toast from '@zero5/ui/lib/utils/toast';

import { IncomingCall } from '@/controlCenter/api/call/models';
import { mapCall } from '@/controlCenter/api/call/mappings';

import { AppThunk } from '..';
import { CallReducer, CurrentCall } from '../reducers/call';

export const callActions = createActionCreators(CallReducer);

export const acceptCall = (call: IncomingCall): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  if (state.call.acceptingCallId) {
    toast('error', 'Other call is accepting');
    return;
  }

  try {
    const { requestId } = call;
    dispatch(callActions.setAcceptingCallId(requestId));

    const response = await CallSession.acceptCall({ sessionId: requestId });
    switch (response) {
      case AnswerCallResult.CallHasAlreadyAnswered: {
        toast('info', 'Call has already answered.');
        dispatch(callActions.removeCall(requestId));
      }
        break;

      case AnswerCallResult.Failed: {
        toast('error', 'Call failed.');
        dispatch(callActions.removeCall(requestId));
      }
        break;

      case AnswerCallResult.Success: {
        const sessionResult = await CallSession.getCallMeta({
          sessionId: requestId,
        });

        switch (sessionResult.type) {
          case SessionResultType.SESSION_NOT_FOUND:
          case SessionResultType.SESSION_ENDED: {
            toast('error', 'Call failed.');
            dispatch(callActions.removeCall(requestId));
          }
            break;

          case SessionResultType.SESSION_OK: {
            const currentCall: CurrentCall = {
              meetingCredentials: sessionResult.payload,
              incomingCall: call,
            };

            dispatch(callActions.setCurrentCall(currentCall));
            dispatch(callActions.removeCall(requestId));
          }
            break;

          default:
            assertNever(sessionResult);
        }
      }
        break;

      default:
        assertNever(response);
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(callActions.setAcceptingCallId(null));
  }
};

export const endCall = (requestId: string): AppThunk => async (dispatch, getState) => {
  if (getState().call.currentCall?.incomingCall.requestId !== requestId) {
    if (getState().call.acceptingCallId === requestId) {
      dispatch(callActions.setAcceptingCallId(null));
    }
    return;
  }
  try {
    dispatch(handleEndedCall());
    await CallSession.endCall({ sessionId: requestId });
  } catch (error) {
    console.error(error);
  }
};

export const handleEndedCall = (): AppThunk => async (dispatch) => {
  dispatch(callActions.setCurrentCall(null));
  dispatch(getCallsQueue());
};

export const getCallsQueue = (): AppThunk => async (dispatch) => {
  const response = await CallSession.getIncomingCalls();
  const incomingCalls: Array<IncomingCall> = response.map((call) => mapCall(call.sessionId, call.payload));
  dispatch(callActions.replaceQueue(incomingCalls));
};
