import {
  Company as CompanyResponse,
  CompanyGarageSigns,
  CompanyToGarageSigns,
  DriverData,
  GaragePermits,
  GetPermitReceiptsAggCountResponseType,
  GetPermitsAggregatedCountResponseType,
  GetPermitTypesCompanyLimitsResponseType,
  Permit,
  PermitOwner as PermitOwnerResponse,
  PermitPaymentType as PermitPaymentTypeResponse,
  PermitStatus as PermitStatusResponse,
  PermitTypeData as PermitTypeResponse,
  PermitTypeHoursOpen,
  WeekDay,
  PermitTypeLimitInfo,
  TenantTypeName as TenantTypeNameResponse,
  VehicleData,
} from '@zero5/permit-api';

import { ArrayElement } from '@/utils/types';
import { formatTimeStampToString } from '@/utils/date/helpers';

import { mapGarage } from '../garage/mappings';

import {
  Company,
  PermitOwner,
  PermitPaymentType,
  PermitStatus,
  PermitTrend,
  PermitType,
  PermitTypeHoursOpenDay,
  TenantTypeName,
} from './models';

const mapPermitTypeHoursOpenDay: Record<WeekDay, PermitTypeHoursOpenDay> = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};

const mapPermitTypeHoursOpen = (response: PermitTypeHoursOpen) => ({
  id: response.id,
  day: mapPermitTypeHoursOpenDay[response.day],
  start: response.start,
  end: response.end,
});

export const mapTenantTypeName: Record<TenantTypeNameResponse, TenantTypeName> = {
  Corporate: 'Corporate',
  Entertainment: 'Entertainment',
  Retail: 'Retail',
  Hotel: 'Hotel',
  Restaurant: 'Restaurant',
  Management: 'Management',
  Security: 'Security',
  Public: 'Public',
};

export const mapPermitType = (type: PermitTypeResponse): PermitType => ({
  id: type.id,
  garageId: type.garageId,
  name: type.name,
  isEnabled: type.isEnabled,
  price: type.price,
  schedule: type.schedule.map(mapPermitTypeHoursOpen),
  eligibleTenantTypes: type.eligibleTenantTypes.map((t) => mapTenantTypeName[t]),
  maxPermits: type.maxPermits,
});

export const mapPermitTypes = (types: Array<PermitTypeResponse>) => types.map(mapPermitType);

export const mapAggregatedCount = (response: GetPermitsAggregatedCountResponseType): PermitTrend => {
  const values = response.permits.map((permit) => permit.value);
  const dates = response.permits.map((permit) => formatTimeStampToString(permit.date, 'MMM, yyyy'));
  return { values, dates };
};

export const mapReceiptAggregatedCount = (response: GetPermitReceiptsAggCountResponseType): PermitTrend => {
  const values = response.permitReceipts.map((permit) => permit.value);
  const dates = response.permitReceipts.map((permit) => formatTimeStampToString(permit.date, 'MM/dd'));
  return { values, dates };
};

export const mapPermitPaymentType: Record<PermitPaymentTypeResponse, PermitPaymentType> = {
  PREPAID: 'PREPAID',
  INVOICE: 'INVOICE',
};

export const mapPermitStatus: Record<PermitStatusResponse, PermitStatus> = {
  ACTIVE: 'ACTIVE',
  PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  EXPIRED: 'EXPIRED',
  DEACTIVATED: 'DEACTIVATED',
};

export const mapPermit = (response: Permit) => ({
  permitId: response.permitId,
  type: mapPermitType(response.type),
  payerId: response.payerId,
  paymentId: response.paymentId,
  vehicleId: response.vehicleId,
  driverId: response.driverId,
  garageId: response.garageId,
  paymentType: mapPermitPaymentType[response.paymentType],
  startTime: response.startTime,
  endTime: response.endTime,
  status: mapPermitStatus[response.status],
  lastTransactionId: response.lastTransactionId,
  qr: response.qr,
  isEnabled: response.isEnabled,
  isAutoRenewEnabled: response.isAutoRenewEnabled,
  isMarkedForRemoval: response.isMarkedForRemoval,
  cost: response.cost,
});

export const mapVehicle = (response: VehicleData) => ({
  vehicleId: response.vehicleId,
  lpNum: response.lpNum,
  lpState: response.lpState,
  make: response.make,
  model: response.model,
  color: response.color,
});

export const mapCompanyGarageSigns = (response: CompanyGarageSigns) => ({
  validationReceipt: response.validationReceipt,
  created: response.created,
  updated: response.updated,
});

export const mapCompanyToGarageSigns = (response: CompanyToGarageSigns) => {
  const newMap: Record<string, ReturnType<typeof mapCompanyGarageSigns>> = {};
  Object.entries(response).forEach(([key, value]) => {
    newMap[key] = mapCompanyGarageSigns(value);
  });
  return newMap;
};

export const mapCompany = (response: CompanyResponse): Company => ({
  companyId: response.companyId,
  externalCompanyId: response.externalCompanyId,
  payerId: response.payerId,
  name: response.name,
  address: response.address,
  typeName: response.typeName,
  phone: response.phone,
  email: response.email,
  secret: response.secret,
  created: response.created,
  updated: response.updated,
  signs: mapCompanyToGarageSigns(response.signs),
  deleted: response.deleted,
  leaseStartDate: response.leaseStartDate,
  payer: response.payer,
});

export const mapDriver = (response: DriverData) => ({
  driverId: response.driverId,
  cognitoUserId: response.cognitoUserId,
  email: response.email,
  isActive: response.isActive,
  isEmailVerified: response.isEmailVerified,
  firstName: response.firstName,
  lastName: response.lastName,
  streetAddress: response.streetAddress,
  streetAddress2: response.streetAddress2,
  city: response.city,
  zip: response.zip,
  country: response.country,
  phone: response.phone,
});

type PermitBilling = GaragePermits['permits'][number]['billing'];
export const mapBilling = (response: PermitBilling) => ({
  billingDate: response.billingDate,
});

export const mapPermitOwnerName = (owner: PermitOwner) => {
  if (owner.type === 'company') {
    return owner.company.name;
  }

  if (owner.type === 'driver') {
    return `${owner.driver.firstName} ${owner.driver.lastName}`;
  }
};

export const mapPermitOwner = (response: PermitOwnerResponse): PermitOwner => {
  if (response.type === 'company') {
    return {
      type: 'company',
	    company: mapCompany(response.company),
    };
  } else {
    return {
      type: 'driver',
	    driver: mapDriver(response.driver),
    };
  }
};

export const mapGaragePermit = (response: ArrayElement<GaragePermits['permits']>) => ({
  permit: mapPermit(response.permit),
  vehicle: mapVehicle(response.vehicle),
  owner: mapPermitOwner(response.owner),
  driver: response.driver ? mapDriver(response.driver) : null,
  billing: mapBilling(response.billing),
});

export const mapGaragePermits = (response: GaragePermits) => ({
  garage: mapGarage(response.garage),
  permits: response.permits.map(mapGaragePermit),
  totalCount: response.totalCount,
});

export const mapPermitTypesCompanyLimit = (
  response: ArrayElement<GetPermitTypesCompanyLimitsResponseType['limits']>,
) => ({
  permitTypeId: response.permitType.id,
  maxPermits: response.companyMaxPermits,
  activePermits: response.companyActivePermits,
});

export const mapPermitTypesCompanyLimits = (response: GetPermitTypesCompanyLimitsResponseType['limits']) =>
  response.map(mapPermitTypesCompanyLimit);

export const mapPermitTypeLimitInfo = () => ({});

export const mapPermitTypeForTenantType = (response: PermitTypeLimitInfo) => ({
  permitType: mapPermitType(response.permitType),
  permitTypeActivePermits: response.permitTypeActivePermits,
  permitTypeFreeLimitSlots: response.permitTypeFreeLimitSlots,
});

export const mapPermitTypesForTenantType = (response: Array<PermitTypeLimitInfo>) =>
  response.map(mapPermitTypeForTenantType);
