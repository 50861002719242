import React from 'react';
import styled from 'styled-components';

import {
  Row,
  Skeleton,
} from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useAccountValidationSummaryQuery from '@/api/validationHistory/useAccountValidationSummaryQuery';

import SummaryTable from './SummaryTable';

interface Props {
  companyId: number;
  fromDate: number;
  toDate: number;
}

const SummarySection: React.FC<Props> = ({
  fromDate,
  toDate,
  companyId,
}) => {
  const accountValidationSummaryQuery = useAccountValidationSummaryQuery({
    fromDate,
    toDate,
    companyId,
  });

  const totalPrice = React.useMemo(() => {
    return accountValidationSummaryQuery.data?.rows.reduce((sum, item) => sum + item.totalAmount, 0);
  }, [accountValidationSummaryQuery.data?.rows]);

  if (accountValidationSummaryQuery.isLoading) {
    return <TransactionsSkeleton width="100%" height="310px" variant="rect" />;
  }

  return (
    <StyledSummarySection>
      <TitleTextRow>
        <MediumLevelTitle>Validation Summary</MediumLevelTitle>
      </TitleTextRow>
      <RowWithOffset>
        <BigText>
          {formatPrice(totalPrice || 0)}
        </BigText>
      </RowWithOffset>
      <TableHiddenScroll>
        <SummaryTable data={accountValidationSummaryQuery.data!.rows} />
      </TableHiddenScroll>
    </StyledSummarySection>
  );
};

const MediumLevelTitle = styled.div`
  font-weight: normal;
`;

const BigText = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
`;

const RowWithOffset = styled(Row)`
  margin-bottom: 20px;
`;

const SummarySectionWrapper = styled.div`
  padding: 15px 20px;
  background: #E7E5E5;
  border-radius: 5px;
`;

const TableHiddenScroll = styled.div`
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar { width: 0; };
`;

const TitleTextRow = styled(RowWithOffset)`
  gap: 20px;
  flex-wrap: wrap;
`;

const StyledSummarySection = styled(SummarySectionWrapper)`
  margin-bottom: 30px;
`;

const TransactionsSkeleton = styled(Skeleton)`
  margin-bottom: 30px;
`;

export default SummarySection;