import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateEntryActivity, UpdateEntryActivityArgs } from '@zero5/client-admin-api';

import { loadImage } from '@/utils/image';

import { queryKey as unresolvedCountQueryKey } from '@/controlCenter/api/activity/useGetUnresolvedIssues';

import { IssueEntryActivitiesWithAmount, mapUpdatedIssueActivity } from './mappings';
import { 
  queryKey as getIssueActivitiesQueryKey, 
  Params as getIssueActivitiesKeyParams,
} from './useGetIssueActivitiesQuery';


type Response = ReturnType<typeof mapUpdatedIssueActivity>;

type Params = UpdateEntryActivityArgs & {
  keyParams: getIssueActivitiesKeyParams;
};

const useUpdateEntryActivityMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'updateEntryActivity',
    async (params) => {
      const response = await updateEntryActivity({
        activityId: params.activityId,
        data: params.data,
        licensePlate: params.licensePlate,
      });

      const mappedActivity = mapUpdatedIssueActivity(response);

      loadImage(mappedActivity.frontImg || '');
      loadImage(mappedActivity.rearImg || '');

      return mappedActivity;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.setQueryData<IssueEntryActivitiesWithAmount>(
          [...getIssueActivitiesQueryKey, params[1].keyParams],
          (prev) => {
            if (prev) {
              const updatedActivity = params[0];
              const index = prev.activities.findIndex(activity => activity.activityId === updatedActivity.activityId);
              prev.activities[index] = params[0];
            }
            return prev!;
          });
        queryClient.invalidateQueries(unresolvedCountQueryKey);

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useUpdateEntryActivityMutation;
