import { DateRange } from '@zero5/ui/lib/Chart';

import { RevenueTrendAggregationPeriod } from '@/api/revenue/models';

const isAggregationAvailable = (aggregation: RevenueTrendAggregationPeriod, range: DateRange) => {
  const monthlyInMilliseconds = 91 * 24 * 60 * 60 * 1000;
  const weeklyInMilliseconds = 14 * 24 * 60 * 60 * 1000;

  const rangeInMilliseconds = range.end.getTime() - range.start.getTime();

  switch (aggregation) {
    case 'daily':
      return true;
    case 'weekly':
      return rangeInMilliseconds >= weeklyInMilliseconds;
    case 'monthly':
      return rangeInMilliseconds >= monthlyInMilliseconds;
  }
};

export default isAggregationAvailable;