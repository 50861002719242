import React from 'react';
import styled from 'styled-components';

import { InfoButton, Modal, RowsMap, Table, TableContent } from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import useTablePagination from '@zero5/ui/lib/utils/hooks/useTablePagination';

import { AccountValidationHistory } from '@/api/validationHistory/models';
import useValidationsByTenantQuery from '@/api/validationHistory/useValidationsByTenantQuery';

import useDateModule from '@/utils/date/useDateModule';

import AccountValidationHistoryModal from './modals/accountValidationHistoryModal/AccountValidationHistoryModal';
import AccountType from './components/AccountType';

interface Props {}

const AccountValidations: React.FC<Props> = ({}) => {
  const {
    formatMonthYear,
    getTimestampOfMonthEnd,
  } = useDateModule();

  const [
    page,
    rowsPerPage,
    changePageHandler,
    changeRowsPerPageHandler,
    ,
    ,
    order,
    orderBy,
    changeSortingHandler,
  ] = useTablePagination();

  const [validationHistoryModalOpen, closeValidationHistoryModal, openValidationHistoryModal] = useToggle(false);
  const [validationHistoryModalData, setValidationHistoryModalData] = React.useState<{
    companyId: number;
    fromDate: number;
    toDate: number;
  }>();

  const allValidationsQuery = useValidationsByTenantQuery({
    order,
    sortBy: orderBy as 'timestamp' | 'amount' | 'tenantName' | 'tenantType' | 'validationCount',
    skip: page * rowsPerPage,
    take: rowsPerPage,
  });

  const validationHistoryRowsMap = React.useMemo<RowsMap<AccountValidationHistory>>(() => [
    {
      title: 'Date',
      id: 'timestamp',
      data: ({ date }) => formatMonthYear(date),
      CellProps: {
        align: 'left',
      },
      HeadCellProps: {
        align: 'left',
      },
    },
    {
      title: 'Account',
      id: 'tenantName',
      data: ({ tenantName }) => tenantName,
      CellProps: {
        align: 'left',
      },
      HeadCellProps: {
        align: 'left',
      },
    },
    {
      title: 'Account Type',
      id: 'tenantType',
      data: ({ tenantType }) => <AccountType type={tenantType} />,
      CellProps: {
        align: 'left',
      },
      HeadCellProps: {
        align: 'left',
      },
    },
    {
      title: 'No. of Validations',
      id: 'validationCount',
      data: ({ validationCount }) => validationCount,
      CellProps: {
        align: 'right',
      },
      HeadCellProps: {
        align: 'right',
      },
    },
    {
      title: 'Total Amount',
      id: 'amount',
      data: ({ amount }) => formatPrice(amount),
      CellProps: {
        align: 'right',
      },
      HeadCellProps: {
        align: 'right',
      },
    },
    {
      title: 'Info',
      id: 'info',
      disableSorting: true,
      data: ({ companyId, date }) => (
        <InfoButton
          onClick={() => {
            setValidationHistoryModalData({
              companyId,
              fromDate: date,
              toDate: getTimestampOfMonthEnd(date),
            });
            openValidationHistoryModal();
          }}
        />
      ),
    },
  ], [formatMonthYear, getTimestampOfMonthEnd, openValidationHistoryModal]);

  return (
    <>
      <TableContent
        isLoading={allValidationsQuery.isLoading}
        data={allValidationsQuery.data?.rows || []}
      >
        <Table
          rowsMap={validationHistoryRowsMap}
          data={allValidationsQuery.data?.rows!}
          count={allValidationsQuery.data?.rowsCount!}
          onPageChange={changePageHandler}
          onRowsPerPageChange={changeRowsPerPageHandler}
          onSortChange={changeSortingHandler}
          isLoading={allValidationsQuery.isFetching}
        />
      </TableContent>
      <StyledModal
        open={validationHistoryModalOpen}
        onClose={closeValidationHistoryModal}
        fullScreenSize="620px"
      >
        <AccountValidationHistoryModal
          data={validationHistoryModalData!}
          onClose={closeValidationHistoryModal}
        />
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  max-width: 1060px;
  width: 100%;
`;

export default AccountValidations;