import { Z5DateInterface, z5DateModule } from '@zero5/time-module';
import { DateRange } from '@zero5/ui/lib/Chart';

export type Time = 'day' | 'month' | 'week' | 'hour' | 'minute';

type AcceptableDate = Date | number | string;

const acceptableDateToTimestamp = (date: AcceptableDate) => {
  if (date instanceof Date) return date.getTime();

  return new Date(date).getTime();
};

export const getMonthAndYear = (date: Date | number) => {
  return formatTimeStampToString(date, 'MMMM yyyy');
};

export const getDiffDays = (start: Date, end: Date) => {
  if (start !== null && end !== null) {
    const diff = Math.abs(start.getTime() - end.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  }
  return 7;
};

export const formatDateChart = (date: string) => {
  return date.replace(/^\d{4}-/, '').replace('-', '/');
};

export const formatSlashDateChart = (date: string) => {
  return date.replaceAll('/', '-').replace(/[-]\d{4}/, '').replace('-', '/');
};

export const convertDateToTimezoneUtc = (date: Date) => {
  return z5DateModule.convertLocalToConfiguredTimezone(date).getTimestamp();
};

export const addHoursToTimeStamp = (date: number, hours: number) => {
  return z5DateModule.fromUtc(date).addHours(hours).getTimestamp();
};

export const getHour = (date: number) => z5DateModule.fromUtc(date).format('H');

export const convertDateRangeToTimeZone = (dateRange: DateRange): { from: number; until: number; } => {
  const from = z5DateModule.convertLocalToConfiguredTimezone(dateRange.start).getTimestamp();
  const until = z5DateModule.convertLocalToConfiguredTimezone(dateRange.end).getTimestamp();
  return { from, until };
};

export const convertDateRangeToTimeZoneWithStartEndOfDay = (dateRange: DateRange): { from: number; until: number; } => {
  const from = z5DateModule.fromUtc(dateRange.start.getTime()).dayStart().getTimestamp();
  const until = z5DateModule.fromUtc(dateRange.end.getTime()).dayEnd().getTimestamp();
  return { from, until };
};

export const getStartOfMonth = (date: Date) => {
  return new Date(z5DateModule.fromUtc(date.getTime()).monthStart().getTimestamp());
};

export const getEndOfMonth = (date: Date) => {
  return new Date(z5DateModule.fromUtc(date.getTime()).monthEnd().getTimestamp());
};

export const getStartOfWeek = (date: Date) => {
  return z5DateModule.convertLocalToConfiguredTimezone(date).weekStart().getTimezoneTimeAsLocalDate();
};

export const getEndOfWeek = (date: Date) => {
  return z5DateModule.convertLocalToConfiguredTimezone(date).weekEnd().getTimezoneTimeAsLocalDate();
};

export const getStartOfDay = (date: Date) => {
  return new Date(z5DateModule.fromUtc(date.getTime()).dayStart().getTimestamp());
};

export const getEndOfDay = (date: Date) => {
  return new Date(z5DateModule.fromUtc(date.getTime()).dayEnd().getTimestamp());
};

export const getCurrentTimeOrEndOfDay = (date: Date) => {
  const now = new Date();
  const startOfToday = getStartOfDay(now);
  const endOfToday = getEndOfDay(now);

  if (date.getTime() < endOfToday.getTime() && date.getTime() >= startOfToday.getTime()) {
    return now;
  }

  return getEndOfDay(date);
};

export const getStartOfDayUTC = (date: Date) => {
  return z5DateModule.fromUtc(date.getTime()).dayStart().getTimestamp();
};

export const getEndOfDayUTC = (date: Date) => {
  return z5DateModule.fromUtc(date.getTime()).dayEnd().getTimestamp();
};

export const formatTimeStampToString = (date: number | Date, pattern: string) => {
  return (date instanceof Date)
    ? z5DateModule.convertLocalToConfiguredTimezone(date).format(pattern)
    : z5DateModule.fromUtc(date).format(pattern);
};

export const getTimeZoneDate = (date: Date | number) => {
  return (date instanceof Date)
    ? z5DateModule.convertLocalToConfiguredTimezone(date).getTimezoneTimeAsLocalDate()
    : z5DateModule.fromUtc(date).getTimezoneTimeAsLocalDate();
};

export const getTimeZoneWeekDay = (date: Date) => {
  return z5DateModule.convertLocalToConfiguredTimezone(date).getWeekDay();
};

export const addTime = (date: Date, time: Time,
  count: number) => {
  let prevDate = z5DateModule.convertLocalToConfiguredTimezone(date);
  switch (time) {
    case 'minute':
      prevDate = prevDate.addMinutes(count);
      break;
    case 'hour':
      prevDate = prevDate.addHours(count);
      break;
    case 'day':
      prevDate = prevDate.addDays(count);
      break;
    case 'week':
      prevDate = prevDate.addWeeks(count);
      break;
    case 'month':
      prevDate = prevDate.addMonths(count);
      break;
    default:
      break;
  }
  return prevDate.getTimezoneTimeAsLocalDate();
};

export const setTime = (date: Date | number, time: Time,
  count: number) => {
  let prevDate = (date instanceof Date)
    ? z5DateModule.convertLocalToConfiguredTimezone(date)
    : z5DateModule.fromUtc(date);
  switch (time) {
    case 'minute':
      prevDate = prevDate.setMinutes(count);
      break;
    case 'hour':
      prevDate = prevDate.setHours(count);
      break;
    default:
      break;
  }
  return prevDate.getTimezoneTimeAsLocalDate();
};

export const hoursToMilliseconds = (hours: number) => {
  return hours * 60 * 60 * 1000;
};

const acceptLocalTime = (timestamp: number) => z5DateModule.fromUtc(timestamp);

const monthAndDayFormatter = (module: Z5DateInterface) => module.format('MM/dd');

const fullDateFormatter = (module: Z5DateInterface) => module.format('MM/dd/yyyy h:mm a');

const fullMonthAndDateFormatter = (module: Z5DateInterface) => module.format('MMM dd');

const fullMonthDateYearFormatter = (module: Z5DateInterface) => module.format('MMM d, yyyy');

const monthYearFormatter = (module: Z5DateInterface) => module.format('MMM yyyy');

// eslint-disable-next-line max-len
export const formatMonthAndDay = (date: AcceptableDate) => monthAndDayFormatter(acceptLocalTime(acceptableDateToTimestamp(date)));

export const formatFullDate = (date: AcceptableDate) =>
  fullDateFormatter(acceptLocalTime(acceptableDateToTimestamp(date)));

export const formatFullMonthAndDate = (date: AcceptableDate) => fullMonthAndDateFormatter(
  acceptLocalTime(acceptableDateToTimestamp(date)),
);

export const formatFullMonthDateYear = (date: AcceptableDate) => fullMonthDateYearFormatter(
  acceptLocalTime(acceptableDateToTimestamp(date)),
);

export const formatMonthYear = (date: AcceptableDate) => monthYearFormatter(
  acceptLocalTime(acceptableDateToTimestamp(date)),
);

export const getTimestampOfTodayStart = () => acceptLocalTime(Date.now()).dayStart().getTimestamp();

export const getTimestampOfTodayEnd = () => acceptLocalTime(Date.now()).dayEnd().getTimestamp();

export const getTimestampOfCurrentMonthStart = () => acceptLocalTime(Date.now()).monthStart().getTimestamp();

export const getTimestampOfMonthEnd = (date: AcceptableDate) =>
  acceptLocalTime(acceptableDateToTimestamp(date)).monthEnd().getTimestamp();

export const getTimestampOfCurrentMonthEnd = () => getTimestampOfMonthEnd(Date.now());

export const getTimestampOfCurrentWeekStart = () => acceptLocalTime(Date.now()).weekStart().getTimestamp();

export const getTimestampOfCurrentWeekEnd = () => acceptLocalTime(Date.now()).weekEnd().getTimestamp();

export const setHours = (date: AcceptableDate, hours: number) => {
  return acceptLocalTime(acceptableDateToTimestamp(date)).setHours(hours).getTimestamp();
};

export const setMinutes = (date: AcceptableDate, minutes: number) => {
  return acceptLocalTime(acceptableDateToTimestamp(date)).setMinutes(minutes).getTimestamp();
};

export const getMonth = (date: AcceptableDate) => {
  return acceptLocalTime(acceptableDateToTimestamp(date)).getTimezoneTimeAsLocalDate().getMonth();
};

export const getDaysInMonth = (date: AcceptableDate) => {
  return acceptLocalTime(acceptableDateToTimestamp(date)).getTimezoneTimeAsLocalDate().getDate();
};
