import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import flow from 'lodash/flow';

import {
  Button,
  ButtonIcon,
  CategoryCard,
  ConfirmationModal,
  formatPhone,
  Row,
  RowsMap,
  Table,
  TableCard,
  TableContent,
} from '@zero5/ui';

import { CompanyUser } from '@/api/models/users';
import useDeleteUserMutation from '@/api/mutations/useDeleteUserMutation';
import useCompanyUsersQuery from '@/api/queries/useCompanyUsersQuery';
import useSelfUserQuery from '@/api/queries/useSelfUserQuery';

import Page from '@/components/common/page';
import AddUpdateUserModal from '@/components/modals/AddUpdateUserModal';
import Status from '@/components/common/Status';
import Role, { useFindCurrentAction } from '@/components/common/Role';
import TableHead from '@/components/common/TableHead';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import { withAuth } from '@/utils/hocs/withAuth';
import { withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';

import DeleteIcon from '@/assets/icons/delete.inline.svg';
import InfoIcon from '@/assets/icons/dots.inline.svg';

interface Props {}

const statusToView: Record<string, {
  label: string;
  color: string;
}> = {
  active: {
    label: 'Active',
    color: '#007AFF',
  },
  invite_sent: {
    label: 'Invite Sent',
    color: '#FF9F1C',
  },
  deactivated: {
    label: 'Deactivated',
    color: '#EF5164',
  },
};

enum UserRole {
  ADMIN = 1001,
  USER = 1002,
  SECURITY = 1003,
  TENANT_ADMIN = 2001,
  CONTROL_CENTER_ADMIN = 3001,
  CONTROL_CENTER_USER = 3002,
}

const caUsersTableFilter = (user: CompanyUser) => user.role?.id !== UserRole.CONTROL_CENTER_ADMIN
  && user.role?.id !== UserRole.CONTROL_CENTER_USER;

const ccUsersTableFilter = (user: CompanyUser) => user.role?.id === UserRole.CONTROL_CENTER_ADMIN
  || user.role?.id === UserRole.CONTROL_CENTER_USER;

const ManageUsers: React.FC<Props> = () => {
  const [currentUser, setCurrentUser] = useState<CompanyUser | null>(null);
  const [modalType, setModalType] = useState<'add' | 'update' | 'delete' | null>(null);

  const findCurrentAction = useFindCurrentAction();

  const usersTableFilter = useMemo(() => {
    if (findCurrentAction('controlCenter') !== 'h') {
      return ccUsersTableFilter;
    }
    return caUsersTableFilter;
  }, [findCurrentAction]);

  const companyUsers = useCompanyUsersQuery();
  const users = companyUsers.data?.filter(usersTableFilter);
  const {
    data: meData,
  } = useSelfUserQuery();
  const closeModal = React.useCallback(() => {
    setModalType(null);
  }, []);

  const deleteUserMutation = useDeleteUserMutation({
    onSuccess: closeModal,
  });

  const cardsData = [
    {
      title: 'Total',
      data: users?.length.toString() || emptyFieldStub,
    },
    {
      title: 'Admin Accounts',
      data: users?.filter(
        ({ role }) => role?.id === UserRole.ADMIN
        || role?.id === UserRole.TENANT_ADMIN
        || role?.id === UserRole.CONTROL_CENTER_ADMIN,
      ).length.toString() || emptyFieldStub,
    },
    {
      title: 'Security Accounts',
      // eslint-disable-next-line max-len
      data: users?.filter(
        ({ role }) => role?.id === UserRole.SECURITY,
      ).length.toString() || emptyFieldStub,
    },
    {
      title: 'User Accounts',
      data: users?.filter(
        ({ role }) => role?.id === UserRole.USER
        || role?.id === UserRole.CONTROL_CENTER_USER,
      ).length.toString() || emptyFieldStub,
    },
  ];

  const usersTableRowsMap = React.useMemo(
    () => {
      const usersRowsMap: RowsMap<CompanyUser> = [
        {
          title: 'Name',
          id: 'firstName',
          data: ({ firstName, lastName }) => `${firstName} ${lastName}`,
          getSortComparator: ({ firstName, lastName }) => `${firstName} ${lastName}`,
        },
        {
          title: 'Phone Number',
          id: 'phone',
          data: ({ phone }) => (phone ? formatPhone(phone) : emptyFieldStub),
          getSortComparator: ({ phone }) => (phone ? formatPhone(phone) : emptyFieldStub),
        },
        {
          title: 'Email',
          id: 'email',
          data: ({ email }) => email,
        },
        {
          title: 'Role',
          id: 'role',
          data: ({ role }) => role?.label || emptyFieldStub,
          getSortComparator: ({ role }) => role?.label || emptyFieldStub,
        },
        {
          title: 'Status',
          id: 'status',
          data: ({ status }) => (
            <Status color={statusToView[status]?.color}>
              {statusToView[status]?.label}
            </Status>
          ),
        },
      ];
      if (findCurrentAction('user') !== 'h') {
        usersRowsMap.push({
          title: 'Info',
          id: 'info',
          data: (user) => {
            return (
              <StyledButtonIcon
                Icon={InfoIcon}
                onClick={() => {
                  setCurrentUser(user);
                  setModalType('update');
                }}
                data-test="manage-users-info-icon"
              />
            );
          },
          disableSorting: true,
        });
      }
      if (findCurrentAction('user') === 'w') {
        usersRowsMap.push({
          title: 'Delete',
          id: 'delete',
          data: (user) => {
            if (user.email === meData?.user.email) {
              return null;
            }

            return (
              <StyledButtonIcon
                Icon={DeleteIcon}
                onClick={() => {
                  setCurrentUser(user);
                  setModalType('delete');
                }}
                data-test="manage-users-delete-icon"
              />
            );
          },
          disableSorting: true,
        });
      }
      return usersRowsMap;
    },
    [findCurrentAction, meData?.user.email],
  );

  return (
    <Page>
      <PageWrapper>
        <CardsWrapper>
          {cardsData.map((props) => (
            <CategoryCard key={props.title} {...props} />
          ))}
        </CardsWrapper>
        <Role widgetId="user" action="w">
          <StyledRow justifyContent="flex-end">
            <Button
              color="primary"
              onClick={() => setModalType('add')}
              variant="contained"
              data-test="create-account-btn"
            >
              Create Account
            </Button>
          </StyledRow>
        </Role>
        <TableCard>
          <TableHead
            title="User List"
            isHideShortcuts
          />
          <TableContent
            isLoading={companyUsers.isLoading}
            data={users}
          >
            <StyledTable
              rowsMap={usersTableRowsMap}
              data={users!}
              data-test="users-list"
            />
          </TableContent>
        </TableCard>
        <ConfirmationModal
          open={modalType === 'delete'}
            // text={`Are you sure you want to delete ${currentUser?.firstName} ${currentUser?.lastName}\'s account?`}
          text="Are you sure you want to delete"
          variableText={`${currentUser?.firstName} ${currentUser?.lastName}`}
          text2={'\'s account?'}
          onAccept={() => deleteUserMutation.mutate({ userId: currentUser!.userId })}
          onReject={closeModal}
          loading={deleteUserMutation.isLoading}
          acceptText={'Delete'}
        />
        <AddUpdateUserModal
          open={modalType === 'update'}
          onClose={closeModal}
          user={currentUser}
          modalType="update"
        />
        <Role widgetId="user" action="w">
          <AddUpdateUserModal
            open={modalType === 'add'}
            onClose={closeModal}
            modalType="add"
          />
        </Role>
      </PageWrapper>
    </Page>

  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 35px;
`;

const StyledTable = styled(Table)`
  margin-top: 10px;
  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

` as typeof Table;

const StyledButtonIcon = styled(ButtonIcon)`
  display: flex;
  margin: 0 auto;
`;

export default flow(
  withRole('user'),
  withAuth(),
  withLoading(),
)(ManageUsers);
