import { useQuery, UseQueryOptions } from 'react-query';

import { getWatchlistContacts } from '@zero5/garage-api';

import { watchlistQueryKeys } from './queryKeys';
import { mapWatchlistContacts } from './mappings';

type Response = ReturnType<typeof mapWatchlistContacts>;
type Key = ReturnType<typeof watchlistQueryKeys.watchlistContacts>;

const useWatchlistContactsQuery = (
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    watchlistQueryKeys.watchlistContacts(),
    async () => {
      const response = await getWatchlistContacts();

      return mapWatchlistContacts(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useWatchlistContactsQuery;
