import produce from 'immer';
import React from 'react';
import styled from 'styled-components';

import { Checkbox, Skeleton } from '@zero5/ui';
import { capitalize } from '@zero5/ui/lib/utils/formatters/capitalize';

import useGetGarageQuery from '@/api/garage/useGetGarageQuery';

import TimePicker from '@/components/common/TimePicker';

import useDateModule from '@/utils/date/useDateModule';

import { DayTimeData } from '../utils/types';

import Table, { HeadCell, TableCell } from './Table';

interface Props {
  data: Array<DayTimeData>;
  isLoading: boolean;
  onChange: (data: Array<DayTimeData>) => void;
}

const WeekTimeTable: React.FC<Props> = ({ data, isLoading, onChange }) => {
  const { setMinutes, setHours, formatTimeStampToString } = useDateModule();

  const setHoursAndMinutes = (str: string) => {
    const hours = Number(str.slice(0, 2));
    const minutes = Number(str.slice(2, 4));
    const dateWithHours = setHours(new Date(), hours);
    const dateWithMinutes = setMinutes(dateWithHours, minutes);
	  return new Date(dateWithMinutes);
  };

  const hoursMap: Record<string, DayTimeData> = {
    MONDAY: { day: 'MONDAY', startTime: '0000', endTime: '0000', applicable: false },
    TUESDAY: { day: 'TUESDAY', startTime: '0000', endTime: '0000', applicable: false },
    WEDNESDAY: { day: 'WEDNESDAY', startTime: '0000', endTime: '0000', applicable: false },
    THURSDAY: { day: 'THURSDAY', startTime: '0000', endTime: '0000', applicable: false },
    FRIDAY: { day: 'FRIDAY', startTime: '0000', endTime: '0000', applicable: false },
    SATURDAY: { day: 'SATURDAY', startTime: '0000', endTime: '0000', applicable: false },
    SUNDAY: { day: 'SUNDAY', startTime: '0000', endTime: '0000', applicable: false },
  };
  for (const dayData of data) {
    if (dayData.day in hoursMap) {
      hoursMap[dayData.day] = dayData;
    }
  }

  const fullData = Object.values(hoursMap);

  const garage = useGetGarageQuery();
  const timezone = garage.data?.timezone;

  return (
    <Table minWidth="485px">
      <tr>
        <HeadCell />
        <HeadCell>Start Time</HeadCell>
        <HeadCell>End Time</HeadCell>
        <HeadCell />
      </tr>
      {fullData.map(({
        day, startTime, endTime, applicable,
      }) => (
        <tr key={day}>
          <TableCell>{capitalize(day)}</TableCell>
          <TableCell colSpan={2}>
            <FlexRow>
            {isLoading  ? (
              <>
                <Skeleton variant="rect" height={36} />
                -
                <Skeleton variant="rect" height={36} />
              </>
            ) : (
              <>
                <StyledTimePicker
                  timezone={timezone}
                  pickerProps={{
                    disabled: !applicable,
                    selected: setHoursAndMinutes(startTime),
                    onChange: (date: unknown) => {
                      if (!(date instanceof Date)) return;
                      onChange(produce(fullData, (draft) => {
                        draft!.forEach((item) => {
                          if (item.day !== day) return;
                          item.startTime = formatTimeStampToString(date.getTime(), 'HHmm');
                        });
                      }));
                    },
                  }}
                />
                -
                <StyledTimePicker
                  timezone={timezone}
                  pickerProps={{
                    disabled: !applicable,
                    selected: setHoursAndMinutes(endTime),
                    injectTimes: [
                      new Date(new Date(new Date().setHours(23)).setMinutes(59)),
                    ],
                    onChange: (date: unknown) => {
                      if (!(date instanceof Date)) return;
                      onChange(produce(fullData, (draft) => {
                        draft!.forEach((item) => {
                          if (item.day !== day) return;
                          item.endTime = formatTimeStampToString(date.getTime(), 'HHmm');
                        });
                      }));
                    },
                  }}
                />
            </>
            )}
            </FlexRow>

          </TableCell>
          <TableCell>
            <CheckboxLabel>
              <StyledCheckbox
                onChange={(e, checked) => {
                  onChange(produce(fullData, (draft) => {
                    draft!.forEach((item) => {
                      if (item.day !== day) return;

                      item.applicable = !checked;
                    });
                  }));
                }}
                checked={!applicable}
              />
              Not Applicable
            </CheckboxLabel>
          </TableCell>
        </tr>
      ))}
    </Table>
  );
};

const CheckboxLabel = styled.label`
  white-space: nowrap;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

// const TimePickerRow = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 5px;
// `;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  & input { 
    text-align: center;
  }
`;

const FlexRow = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  & > * {
    min-width: 200px;
    flex-grow: 1;
  }
`;


export default WeekTimeTable;
