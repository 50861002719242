import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationTypeCompanyLimits } from '@zero5/garage-api';

import { mapValidationTypeCompanyLimits } from './mappings';
import { validationQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapValidationTypeCompanyLimits>;
type Key = ReturnType<typeof validationQueryKeys.validationTypeCompanyLimits>;

const useGetValidationTypesCompanyLimitsQuery = (
  companyId: number,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    validationQueryKeys.validationTypeCompanyLimits(companyId),
    async () => {
      const response = await getValidationTypeCompanyLimits(companyId);

      return mapValidationTypeCompanyLimits(response.limits);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetValidationTypesCompanyLimitsQuery;
