import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { setValidationTypeCompanyLimits } from '@zero5/garage-api';

import { validationQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  companyId: number;
  limits: {
    validationTypeGroupId: number;
    monthlyLimit: number;
  }[];
};

const useSetValidationTypeCompanyLimitsMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'setValidationTypeCompanyLimits',
    async ({ companyId, ...params }) => {
      const response = await setValidationTypeCompanyLimits(companyId, params);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        const companyId = params[1].companyId;
        const limits = params[1].limits;

        queryClient.invalidateQueries(validationQueryKeys.validationTypeCompanyLimits(companyId));
        limits.forEach(({ validationTypeGroupId }) => {
          queryClient.invalidateQueries(
            validationQueryKeys.validationTypeCompanyLimit(companyId, validationTypeGroupId),
          );
        });

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useSetValidationTypeCompanyLimitsMutation;
