import { useQuery, UseQueryOptions } from 'react-query';

import { getCountEvents } from '@zero5/garage-api';

import { mapCountPendingEventsResponse } from '@/api/mappings/event';

import useDateModule from '@/utils/date/useDateModule';

import { eventQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapCountPendingEventsResponse>;

export type QueryKey = ReturnType<typeof eventQueryKeys.eventCounts>;

const useCountPendingScheduledEventQuery = (
  options?: UseQueryOptions<Response, unknown, Response, QueryKey>,
) => {

  const { getTimestampOfCurrentMonthEnd, getTimestampOfCurrentMonthStart } = useDateModule();

  return useQuery(
    eventQueryKeys.eventCounts(),
    async () => {
      const now = new Date();
      const response = await getCountEvents({
        fromDate: getTimestampOfCurrentMonthStart(),
        toDate: getTimestampOfCurrentMonthEnd(),
        currentDate: now.getTime(),
      });
      return mapCountPendingEventsResponse(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useCountPendingScheduledEventQuery;
