import React from 'react';

import {
  CategoryCard,
  CardsBlock,
} from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useValidationHistoryCardsQuery from '@/api/validationHistory/useValidationHistoryCardsQuery';

import useDateModule from '@/utils/date/useDateModule';

interface Props {}

const Cards: React.FC<Props> = ({}) => {
  const {
    formatMonthYear,
    getTimestampOfCurrentMonthStart,
    getTimestampOfCurrentMonthEnd,
  } = useDateModule();

  const validationHistoryCardsQuery = useValidationHistoryCardsQuery({
    fromDate: getTimestampOfCurrentMonthStart(),
    toDate: getTimestampOfCurrentMonthEnd(),
  });

  const cardsData = [
    {
      title: 'Total Validation Revenue',
      data: validationHistoryCardsQuery.data ? formatPrice(validationHistoryCardsQuery.data.validationBalance) : '',
      subtext: formatMonthYear(Date.now()),
    },
    {
      title: 'Total No. of Validation',
      data: validationHistoryCardsQuery.data?.validationCount,
      subtext: formatMonthYear(Date.now()),
    },
  ];

  return (
    <CardsBlock>
      {cardsData.map((props) => (
        <CategoryCard key={props.title} loading={validationHistoryCardsQuery.isLoading} {...props} />
      ))}
    </CardsBlock>
  );
};

export default Cards;