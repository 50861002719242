/* eslint-disable max-len */
import { useQuery, UseQueryOptions } from 'react-query';

import { getNearestEvent } from '@zero5/garage-api';

import { mapNearestEvent } from '../mappings/event';

import { eventQueryKeys } from './queryKeys';

type Response = string;

export type QueryKey = ReturnType<typeof eventQueryKeys.nearestEvent>;


const useGetNearestEventQuery = (
  options?: UseQueryOptions<Response, unknown, Response,  QueryKey>,
) => {
  return useQuery<Response, unknown, Response,  QueryKey>(
    eventQueryKeys.nearestEvent(),
    async () => {
      const response = await getNearestEvent();

      return mapNearestEvent(response.event);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetNearestEventQuery;
