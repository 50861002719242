import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

import { LoadingIndicator, Row } from '@zero5/ui';

import useCompanyGarageSignsQuery from '@/api/queries/useCompanyGarageSignsQuery';
import useValidationTypesAvailableNowQuery from '@/api/validation/useValidationTypesAvailableNowQuery';

interface Props {}

const ValidationForEmployees: React.FC<Props> = ({}) => {
  const companyGarageSignQuery = useCompanyGarageSignsQuery();
  const validationTypesAvailableNowQuery = useValidationTypesAvailableNowQuery();

  const qrCodeLink = React.useMemo(() => {
    return `${process.env.PAY_PAGE_VALIDATION}/${companyGarageSignQuery.data?.signs.validationReceipt}`;
  }, [companyGarageSignQuery.data?.signs.validationReceipt]);

  const hasAvailableValidations = Boolean(validationTypesAvailableNowQuery.data?.length);

  if (companyGarageSignQuery.isLoading || validationTypesAvailableNowQuery.isLoading) {
    return <StyledLoadingIndicator />;
  }

  return (
    <>
      <Row justifyContent="center">
        {hasAvailableValidations && <QRCode size={150} value={qrCodeLink} />}
      </Row>
      <Row justifyContent="center">
        {hasAvailableValidations ? (
          <List>
            <li>This is NOT the validation code.</li>
            <li>SCAN this qr code to register your information and validate your parking.</li>
          </List>
        ) : (
          <Text>
            Validation is not available
          </Text>
        )}
      </Row>
    </>
  );
};

const StyledLoadingIndicator = styled(LoadingIndicator)`
  height: 170px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
`;

const List = styled.ul`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
`;

export default ValidationForEmployees;