import { Params as WatchlistParams } from './useWatchlistVehiclesQuery/types';

export const watchlistQueryKeys = {
  base: ['watchlist'] as const,
  watchlistVehicleLists: () => [...watchlistQueryKeys.base, 'list'] as const,
  watchlistVehicleList: (params: WatchlistParams) => [...watchlistQueryKeys.watchlistVehicleLists(), params] as const,
  watchlistVehicles: () => [...watchlistQueryKeys.base, 'item'],
  watchlistVehicle: (id: number) => [...watchlistQueryKeys.watchlistVehicles(), id],
  watchlistVehicleSearches: () => [...watchlistQueryKeys.base, 'search'],
  watchlistVehicleSearch: (lpNum: string) => [...watchlistQueryKeys.watchlistVehicleSearches(), lpNum],
  watchlistSettings: () => [...watchlistQueryKeys.base, 'settings'],
  watchlistContacts: () => [...watchlistQueryKeys.base, 'contacts'],
};
