import { useQuery, UseQueryOptions } from 'react-query';

import { getWatchlistVehicleTable } from '@zero5/garage-api';

import { watchlistQueryKeys } from '../queryKeys';
import { mapWatchlist } from '../mappings';

import { Params } from './types';

type Response = ReturnType<typeof mapWatchlist>;
type Key = ReturnType<typeof watchlistQueryKeys.watchlistVehicleList>;

const useWatchlistVehiclesQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    watchlistQueryKeys.watchlistVehicleList(params),
    async () => {
      const response = await getWatchlistVehicleTable(params);

      return mapWatchlist(response);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useWatchlistVehiclesQuery;
