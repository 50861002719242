import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationTrend } from '@zero5/garage-api';

import { validationHistoryQueryKeys } from './queryKeys';

type Response = Awaited<ReturnType<typeof getValidationTrend>>;
type Key = ReturnType<typeof validationHistoryQueryKeys.trend>;
type Params = {
  fromDate: number;
  toDate: number;
  aggregationPeriod: 'day' | 'week' | 'month';
};

const useValidationTrendQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    validationHistoryQueryKeys.trend(params),
    async () => {
      const response = await getValidationTrend(params);

      return response;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useValidationTrendQuery;
