import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Call, CallEdition, CurrentCall } from '@zero5/call-modal';

import useDateModule from '@/utils/date/useDateModule';

import { CallApiProvider } from './CallApiProvider';

export interface CaCallAdapterProps {
  currentCall: CurrentCall;
}

export const CaCallAdapter: React.FC<CaCallAdapterProps> = ({ currentCall }) => {
  const dispatch = useDispatch();

  const [
    callApiProvider,
    setCallApiProvider,
  ] = useState(new CallApiProvider(currentCall.incomingCall.requestId, dispatch));
  useEffect(
    () => setCallApiProvider(new CallApiProvider(currentCall.incomingCall.requestId, dispatch)),
    [currentCall.incomingCall.requestId, dispatch],
  );

  const { z5DateModule } = useDateModule();

  return (
    <Call
      edition={CallEdition.Internal}
      currentCall={currentCall}
      callApiProvider={callApiProvider}
      callInfo={{
        callerGarageTimezone: z5DateModule.getTimezone(),
      }}
    />
  );
};
