import React from 'react';
import styled from 'styled-components';

import {
  Card,
  SimpleTable,
  Image,
  SimpleRowsMap,
  Row,
  Button,
} from '@zero5/ui';
import { getGarageName, getGateType, getZone } from '@zero5/ui/lib/utils/formatters/formatGate';

import { checkKeySelect } from '@/utils/accessibility';
import useDateModule from '@/utils/date/useDateModule';

type ActivityBase = {
  mainLp?: string | null;
  deviceId: string;
  eventTime: number;
  frontImg: string | null;
  rearImg: string | null;
};

export interface Props<T> {
  activities: Array<T>;
  onActivitySelect: (activity: T) => void;
  hasMoreActivities?: boolean;
  onLoadMore: () => void;
  isLoading: boolean;
}

const ActivityList = <T extends ActivityBase>({
  activities,
  onActivitySelect,
  hasMoreActivities,
  onLoadMore,
  isLoading,
}: Props<T>) => {
  const { formatTimeStampToString } = useDateModule();

  const activitiesRowsMap = React.useMemo<SimpleRowsMap<T>>(() => [
    {
      title: 'License Plate',
      data: ({ mainLp }) => mainLp,
    },
    {
      title: 'Location',
      data: ({ deviceId }) => `Layout ${getZone(deviceId)}`,
    },
    {
      title: 'Gate',
      data: ({ deviceId }) => `${getGateType(deviceId)} ${getGarageName(deviceId).replace(/IN_|OUT_/, '')}`,
    },
    {
      title: 'Time',
      data: ({ eventTime }) => formatTimeStampToString(eventTime, 'MM/dd/yyyy HH:mm:ss'),
    },
  ], [formatTimeStampToString]);

  return (
    <>
      <ActivitiesWrapper>
        {activities.map((activity, idx) => (
          <ActivitiesItem data-test="activity-info"
          // eslint-disable-next-line react/no-array-index-key
            key={idx}
            onClick={() => onActivitySelect(activity)}
            onKeyPress={checkKeySelect(() => onActivitySelect(activity))}
          >
            <StyledImage
              width="220px"
              height="170px"
              src={activity.frontImg || activity.rearImg || ''}
              data-test="logo-image"
            />
            <StyledSimpleTable
              rowsMap={activitiesRowsMap}
              item={activity}
              align="left"
            />
          </ActivitiesItem>
        ))}
      </ActivitiesWrapper>
      {hasMoreActivities && (
        <StyledRow justifyContent="center">
          <LoadMoreButton
            variant="contained"
            color="primary"
            onClick={onLoadMore}
            loading={isLoading}
          >
            Load more
          </LoadMoreButton>
        </StyledRow>
      )}
    </>
  );
};

const ActivitiesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 20px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

const ActivitiesItem = styled(Card).attrs({
  tabIndex: 1,
  role: 'button',
})`
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 20px;
  align-items: center;
  padding: 30px 20px;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgb(48 205 154 / 20%);
  }

  @media (max-width: 1350px) {
    grid-row-gap: 30px;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    justify-items: center;
  }
`;

const StyledImage = styled(Image)`
  object-fit: cover;
`;

const StyledSimpleTable = styled(SimpleTable)`
  & td, & th {
    padding: 6px;
  }
  @media (max-width: 1350px) {
    width: max-content;
    th {
      width: 100px;
    }
  }
` as typeof SimpleTable;

const StyledRow = styled(Row)`
  margin-top: 20px;
`;

const LoadMoreButton = styled(Button)`
  width: 100px;
`;

export default ActivityList;
