import {
  ActivityData,
  GetLatestEntryActivitiesResponseType,
  IssueStatus,
  IssueActivityWithVehicleStatus,
} from '@zero5/client-admin-api';
import { ActivityDataWitType } from '@zero5/client-admin-api';
import { EntryActivity } from '@zero5/client-admin-api';

import { Activity, LPRStatus, ActivityStatus, IssueActivity } from './models';

export type ActivityResponse = ActivityDataWitType;
export const LPRStatusResponse = LPRStatus;
export const ActivityStatusResponse = ActivityStatus;

export type IssueEntryActivitiesWithAmount = {
  activities: IssueActivity[];
  amount: number;
};

export const mapLPRStatus = {
  [LPRStatusResponse.BLOCKED]: LPRStatus.BLOCKED,
  [LPRStatusResponse.INVALID]: LPRStatus.INVALID,
  [LPRStatusResponse.LOW_CONF_LEVEL]: LPRStatus.LOW_CONF_LEVEL,
  [LPRStatusResponse.MISSING]: LPRStatus.MISSING,
  [LPRStatusResponse.OK]: LPRStatus.OK,
};

export const mapActivityStatus = {
  [ActivityStatusResponse.ERROR]: ActivityStatus.ERROR,
  [ActivityStatusResponse.OK]: ActivityStatus.OK,
};

export const mapActivity = (activity: ActivityData): Activity => ({
  activityId: activity.activityId,
  garageId: activity.garageId,
  deviceId: activity.deviceId,
  frontConfLevel: activity.frontConfLevel,
  frontImg: activity.frontImg,
  frontLpNum: activity.frontLpNum,
  frontLpState: activity.frontLpNum,
  frontStatus: activity.frontStatus && mapLPRStatus[activity.frontStatus],
  rearConfLevel: activity.rearConfLevel,
  rearImg: activity.rearImg,
  rearLpNum: activity.rearLpNum,
  rearLpState: activity.rearLpState,
  rearStatus: activity.rearStatus && mapLPRStatus[activity.rearStatus],
  mainLp: activity.mainLp,
  mainState: activity.mainState,
  eventTime: activity.eventTime,
  status: mapActivityStatus[activity.status],
  vehicleType: activity.vehicleType,
  vehicleMake: activity.vehicleMake,
  vehicleModel: activity.vehicleModel,
  vehicleColor: activity.vehicleColor,
  frontLpResolutionStatus: activity.frontLpResolutionStatus,
  rearLpResolutionStatus: activity.rearLpResolutionStatus,
  mainLpResolutionStatus: activity.mainLpResolutionStatus,
});

export const mapEntryActivity = ({ data: activity, elasticLp }: EntryActivity): Activity => ({
  activityId: activity.activityId,
  garageId: activity.garageId,
  deviceId: activity.deviceId,
  frontConfLevel: activity.frontConfLevel,
  frontImg: activity.frontImg,
  frontLpNum: activity.frontLpNum,
  frontLpState: activity.frontLpNum,
  frontStatus: activity.frontStatus && mapLPRStatus[activity.frontStatus],
  rearConfLevel: activity.rearConfLevel,
  rearImg: activity.rearImg,
  rearLpNum: activity.rearLpNum,
  rearLpState: activity.rearLpState,
  rearStatus: activity.rearStatus && mapLPRStatus[activity.rearStatus],
  mainLp: elasticLp,
  mainState: activity.mainState,
  eventTime: activity.eventTime,
  status: mapActivityStatus[activity.status],
  vehicleType: activity.vehicleType,
  vehicleMake: activity.vehicleMake,
  vehicleModel: activity.vehicleModel,
  vehicleColor: activity.vehicleColor,
  frontLpResolutionStatus: activity.frontLpResolutionStatus,
  rearLpResolutionStatus: activity.rearLpResolutionStatus,
  mainLpResolutionStatus: activity.mainLpResolutionStatus,
});

export const mapActivities = (activities: Array<ActivityData>) => activities.map(mapActivity);

export const mapIssueActivity = (issueActivity: {
  entryActivity: EntryActivity;
  status: IssueStatus;
  isPermit: boolean;
}): IssueActivity => {
  const { entryActivity, status, isPermit } = issueActivity;
  const { data: activity } = entryActivity;
  return {
    activityId: activity.activityId,
    garageId: activity.garageId,
    deviceId: activity.deviceId,
    frontConfLevel: activity.frontConfLevel,
    frontImg: activity.frontImg,
    frontLpNum: activity.frontLpNum,
    frontLpState: activity.frontLpNum,
    frontStatus: activity.frontStatus && mapLPRStatus[activity.frontStatus],
    rearConfLevel: activity.rearConfLevel,
    rearImg: activity.rearImg,
    rearLpNum: activity.rearLpNum,
    rearLpState: activity.rearLpState,
    rearStatus: activity.rearStatus && mapLPRStatus[activity.rearStatus],
    mainLp: entryActivity.updatedMainLp,
    mainState: activity.mainState,
    eventTime: activity.eventTime,
    status: mapActivityStatus[activity.status],
    vehicleType: activity.vehicleType,
    vehicleMake: activity.vehicleMake,
    vehicleModel: activity.vehicleModel,
    vehicleColor: activity.vehicleColor,
    frontLpResolutionStatus: activity.frontLpResolutionStatus,
    rearLpResolutionStatus: activity.rearLpResolutionStatus,
    mainLpResolutionStatus: activity.mainLpResolutionStatus,
    issueStatus: status,
    isPermit,
  };
};

export const mapActivityWithType = (activity: ActivityDataWitType) =>
  mapActivity(activity.data);

// eslint-disable-next-line max-len
export const mapIssueEntryActivities = (activities: GetLatestEntryActivitiesResponseType): IssueEntryActivitiesWithAmount => ({
  activities: activities.activities.map(mapIssueActivity),
  amount: activities.amount,
});

export const mapUpdatedEntryActivity = (activity: EntryActivity) =>
  mapActivity(activity.data);

export const mapUpdatedIssueActivity = ({
  isPermit,
  issueActivity,
}: IssueActivityWithVehicleStatus) =>
  mapIssueActivity({
    ...issueActivity,
    status: issueActivity.status,
    isPermit,
  });
