import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { SignInStatus } from '@zero5/ui-utils';

export interface AuthState {
  signInStatus:  SignInStatus | null;
}

const initialState: AuthState = {
  signInStatus: null,
};

export class AuthReducer extends ImmerReducer<AuthState> {
  // used for clearing store data before signing user in
  signIn(status: SignInStatus | null) {
    this.draftState.signInStatus = status;
  }
}

export default createReducerFunction(AuthReducer, initialState);
