import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationTableDetails } from '@zero5/garage-api';

import { validationHistoryQueryKeys } from './queryKeys';
import { mapAccountValidationDetails } from './mappings';

type Response = ReturnType<typeof mapAccountValidationDetails>;
type Key = ReturnType<typeof validationHistoryQueryKeys.accountValidationDetails>;
type Params = {
  skip: number;
  take: number;
  fromDate: number;
  toDate: number;
  companyId: number;
};

const useAccountValidationDetailsQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    validationHistoryQueryKeys.accountValidationDetails(params),
    async () => {
      const response = await getValidationTableDetails(params.companyId, params);

      return mapAccountValidationDetails(response);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      ...options,
    },
  );
};

export default useAccountValidationDetailsQuery;
