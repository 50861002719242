import React from 'react';
import styled from 'styled-components';
import saveAs from 'file-saver';

import {
  RowsMap,
  Button,
  TableCard,
  TableContent,
  TableHead,
  InfoButton,
  Modal,
} from '@zero5/ui';
import Table, { Props as TableProps } from '@zero5/ui/lib/Table';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import { RevenueForecastedReport } from '@/api/revenue/models';
import useRevenueForecastedQuery from '@/api/revenue/useRevenueForecastedQuery';
import useForecastedRevenueDetailsReportCsvMutation from '@/api/revenue/useForecastedRevenueDetailsReportCsvMutation';

import useDateModule from '@/utils/date/useDateModule';

import { useFindCurrentAction } from '../common/Role';
import Price from '../common/Price';

import ForecastedReport from './modals/ForecastedReport';

interface Props {}

const Forecasted: React.FC<Props> = ({}) => {
  const findCurrentAction = useFindCurrentAction();

  const {
    getStartOfDay,
    getEndOfDay,
    formatTimeStampToString,
  } = useDateModule();

  const [isInfoModalOpen, closeInfoModal, openInfoModal] = useToggle(false);
  const [selectedReport, setSelectedReport] = React.useState<RevenueForecastedReport | null>(null);

  const [revenueForecastedPage, setRevenueForecastedPage] = React.useState(0);
  const [revenueForecastedRowsPerPage, setRevenueForecastedRowsPerPage] = React.useState(10);
  const [revenueForecastedOrderBy, setRevenueForecastedOrderBy] = React.useState<string>();
  const [revenueForecastedOrder, setRevenueForecastedOrder] = React.useState<'ASC' | 'DESC'>();

  const forecastedRevenueDetailsCsvMutation = useForecastedRevenueDetailsReportCsvMutation();

  const onInfoModalOpen = React.useCallback((record: RevenueForecastedReport) => {
    setSelectedReport(record);
    openInfoModal();
  }, [openInfoModal]);

  const exportReport = React.useCallback(async (report: RevenueForecastedReport) => {
    const date = new Date(report.date);

    const start = getStartOfDay(date);
    const end = getEndOfDay(date);

    const reportLocal = await forecastedRevenueDetailsCsvMutation.mutateAsync({
      from: Number(start),
      until: Number(end),
    });

    const timeString = formatTimeStampToString(Number(start), 'MM/dd');

    const fileName = `ForecastedRevenueDetailsReport_${timeString}.csv`;
    const file = new File([reportLocal], fileName, { type: 'text/plain;charset=utf-8' });
    saveAs(file);
  }, [
    forecastedRevenueDetailsCsvMutation,
    formatTimeStampToString,
    getStartOfDay,
    getEndOfDay,
  ]);

  const exportCurrentReport = React.useCallback(() => {
    exportReport(selectedReport!);
  }, [exportReport, selectedReport]);

  const forecastedRevenueRowsMap = React.useMemo(() => {
    const rowsMap: RowsMap<RevenueForecastedReport> = [
      {
        title: 'Date',
        id: 'date',
        data: ({ date }) => formatTimeStampToString(date, 'MM/dd/yyyy'),
      },
      {
        title: 'Transient',
        id: 'transient',
        data: ({ transient }) => formatPrice(transient),
      },
      {
        title: 'Permit (fcst)',
        id: 'permit',
        data: ({ permit }) => formatPrice(permit),
      },
      {
        title: 'Event (fcst)',
        id: 'event',
        data: ({ event }) => formatPrice(event),
      },
      {
        title: 'Validation (fcst)',
        id: 'validation',
        data: ({ validation }) => formatPrice(validation),
      },
      {
        title: 'Disputes & Refunds',
        id: 'dispute',
        data: ({ dispute }) => <Price>{dispute}</Price>,
        disableSorting: true,
      },
      {
        title: 'Total (fcst)',
        id: 'total',
        data: ({ total }) => {
          return <TotalPrice>{formatPrice(total)}</TotalPrice>;
        },
      },
    ];

    if (findCurrentAction('revenue:forecasted:report') !== 'h') {
      rowsMap.push({
        title: 'Details',
        id: 'Details',
        disableSorting: true,
        data: (record) => (
          <InfoButton onClick={() => onInfoModalOpen(record)} />
        ),
      });
    }
    return rowsMap;
  }, [findCurrentAction, formatTimeStampToString, onInfoModalOpen]);

  const {
    data: revenueReportsData,
    isLoading: revenueReportsLoading,
    isFetching: revenueReportsFetching,
  } = useRevenueForecastedQuery({
    orderBy: revenueForecastedOrderBy as 'date' | 'permit' | 'event' | 'transient' | 'validation' | 'total' | undefined,
    direction: revenueForecastedOrder,
    page: revenueForecastedPage + 1,
    take: revenueForecastedRowsPerPage,
  }, {
    keepPreviousData: true,
  });

  return (
    <>
      <TableCard data-test="forecasted-revenue-table">
        <TableHead
          title="Forecasted Revenue"
          extraContent={(
            <StyledButton
              variant="text"
              color="primary"
              disabled={true}
              data-test="forecasted-revenue-table-btn"
            >
            &nbsp;
            </StyledButton>
          )}
        />
        <TableContent
          isLoading={revenueReportsLoading}
          data={revenueReportsData?.reports || []}
        >
          <StyledTable
            rowsMap={forecastedRevenueRowsMap}
            data={revenueReportsData?.reports!}
            minWidth="840px"
            page={revenueForecastedPage}
            onPageChange={(e, page) => setRevenueForecastedPage(page)}
            onRowsPerPageChange={(e) => setRevenueForecastedRowsPerPage(Number(e.target.value))}
            onSortChange={(orderBy, order) => {
              setRevenueForecastedOrderBy(orderBy);
              setRevenueForecastedOrder(order);
            }}
            count={revenueReportsData?.entitiesCount}
            isLoading={revenueReportsFetching}
            data-test="forecasted-revenue-table-content"
          />
        </TableContent>
      </TableCard>
      <ReportModal
        open={isInfoModalOpen}
        onClose={closeInfoModal}
        fullScreenSize="550px"
      >
        <ForecastedReport
          revenueReport={selectedReport!}
          onClose={closeInfoModal}
          onExport={exportCurrentReport}
          isExporting={forecastedRevenueDetailsCsvMutation.isLoading}
        />
      </ReportModal>
    </>
  );
};

const TotalPrice = styled.div`
  font-weight: 700;
`;

const StyledTable = styled<React.FC<TableProps<RevenueForecastedReport>>>(Table)`
  margin-top: 10px;

  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
`;

const ReportModal = styled(Modal)`
  max-width: 1280px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  background-color: transparent;
`;

export default Forecasted;
