import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationTableByTenant } from '@zero5/garage-api';

import { validationHistoryQueryKeys } from './queryKeys';
import { mapValidationsByTenant } from './mappings';

type Response = ReturnType<typeof mapValidationsByTenant>;
type Key = ReturnType<typeof validationHistoryQueryKeys.validationsByTenant>;
type Params = {
  sortBy?: 'timestamp' | 'amount' | 'tenantName' | 'tenantType' | 'validationCount';
  order?: 'ASC' | 'DESC';
  skip?: number;
  take?: number;
};

const useValidationsByTenantQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    validationHistoryQueryKeys.validationsByTenant(params),
    async () => {
      const response = await getValidationTableByTenant(params);

      return mapValidationsByTenant(response);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useValidationsByTenantQuery;
