import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ChartDataset } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';

import {
  BigCategoryCard,
  LineChart,
  Row,
  Select,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';

import { RevenueTrendAggregationPeriod } from '@/api/revenue/models';
import useGetReceiptAggregatedCount from '@/api/permit/useGetReceiptAggregatedCount';

import { selectPermitSettings } from '@/store/selectors/settings';
import { settingsActions } from '@/store/actions/settings';

import RangePicker from '@/components/common/RangePicker';

import useGetChartDateRangeChanger from '@/utils/hooks/useGetChartDateRangeChanger';
import useGetChartAggregationOptions from '@/utils/hooks/useGetChartAggregationOptions';


export type Value = number;

export type DataItem = {
  values: Array<Value>;
  datasetSettings?: Partial<ChartDataset<'line'>>;
};

interface Props {}

const PermitUsageTrendChart: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { usageDateRange } = useSelector(selectPermitSettings);
  const onDateRangeChange = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setPermitSettings({
      usageDateRange: newDateRange,
    }));
  }, [dispatch]);

  const [
    usageTrendChartAggregation,
    setUsageTrendChartAggregation,
  ] = React.useState<RevenueTrendAggregationPeriod>('daily');

  const permitTrendChartAggregatedOptions = useGetChartAggregationOptions(usageDateRange);
  const trendDateRangeChangeHandler = useGetChartDateRangeChanger(onDateRangeChange, setUsageTrendChartAggregation);

  const {
    data: usageTrend,
    isLoading,
    refetch: usageTrendRefetch,
  } = useGetReceiptAggregatedCount({
    from: usageDateRange.start,
    to: usageDateRange.end,
    aggregationPeriod: usageTrendChartAggregation,
  }, {
    enabled: false,
  });
  const usageValues = usageTrend?.values || [];
  const usageDates = usageTrend?.dates || [];

  useEffect(() => {
    if (usageDateRange.end && usageDateRange.start){
      usageTrendRefetch();
    }
  }, [usageDateRange]);

  return (
    <BigCategoryCard
      title="Permit Usage Trend"
      gap="10px"
      headerContent={
        <Row wrap="wrap" gap="10px">
          <RangePicker
            dateRange={usageDateRange}
            onChange={(range) => trendDateRangeChangeHandler(range, usageTrendChartAggregation)}
            hideShortcutsSize="1450px"
          />
          <StyledSelect
            options={permitTrendChartAggregatedOptions}
            value={permitTrendChartAggregatedOptions.find(({ value }) => value === usageTrendChartAggregation)}
            onChange={(option) => {
              trendDateRangeChangeHandler(usageDateRange, option!.value);
              setUsageTrendChartAggregation(option!.value);
            }}
          />
        </Row>
      }
    >
      <LineChart
        data={usageValues}
        labels={usageDates}
        isLoading={isLoading}
      />
    </BigCategoryCard>
  );
};

const StyledSelect = styled(Select)`
  min-width: 115px;
` as typeof Select;

export default PermitUsageTrendChart;