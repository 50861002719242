import React from 'react';

import schemas from '@zero5/ui/lib/utils/validation/schemas';
import { Input, LoadingIndicator } from '@zero5/ui';

import useFindUserByEmailQuery from '@/api/queries/useUserByEmail';

import { useDebouncedInputChangeHandler } from '@/utils/hooks/useDebouncedInputChangeHandler';

import { Contact } from './types';

interface Props {
  contact: Contact;
  onChange: (contact: Contact) => void;
  emailError?: string;
}

const ContactInput: React.FC<Props> = ({
  contact,
  onChange,
  emailError,
}) => {
  const {
    email,
    firstName,
    lastName,
    userRole,
  } = contact;
  // const [contactEmail, setContactEmail] = React.useState(email);

  const changeHandler = React.useCallback((newEmail: string) => {
    onChange({
      id: null,
      email: newEmail,
      firstName: '',
      lastName: '',
      userRole: '',
    });
  }, [onChange]);

  const [debouncedEmailChangeHandler] = useDebouncedInputChangeHandler(changeHandler);

  const findUserByEmailQuery = useFindUserByEmailQuery(email, {
    enabled: schemas.emailSchema.isValidSync(email),
    onSuccess: (data) => {
      if (!data) {
        onChange({
          id: null,
          email,
          firstName: '',
          lastName: '',
          userRole: '',
        });
        return;
      }

      onChange({
        id: data.userId,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        userRole: data.role?.label || '',
      });
    },
  });

  // React.useEffect(() => {
  //   setContactEmail(email);
  // }, [email]);

  return (
    <>
      <Input
        label="Email Address"
        defaultValue={email}
        onChange={debouncedEmailChangeHandler}
        endAdornment={findUserByEmailQuery.isLoading ? (
          <LoadingIndicator size="10px" />
        ) : null}
        error={Boolean(emailError)}
        helperText={emailError}
      />
      <Input
        label="First Name"
        value={firstName}
        disabled
      />
      <Input
        label="Last Name"
        value={lastName}
        disabled
      />
      <Input
        label="User Role"
        value={userRole}
        disabled
      />
    </>
  );
};

export default ContactInput;