import React from 'react';
import styled from 'styled-components';

import { Input } from '@zero5/ui';

import { useDebouncedInputChangeHandler } from '@/utils/hooks/useDebouncedInputChangeHandler';

interface Props {
  onChange: (searchQuery: string) => void;
}

const SearchInput: React.FC<Props> = ({ onChange }) => {
  const [,searchChangeHandler] = useDebouncedInputChangeHandler(onChange, {
    wait: 800,
  });

  return (
    <StyledInput
      onChange={searchChangeHandler}
      placeholder="Search license plate"
      transform="uppercase"
    />
  );
};

const StyledInput = styled(Input)`
  input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
` as typeof Input;

export default SearchInput;