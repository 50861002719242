type GetMinutesOptionsArgs = {
  maxMinutesValue: number;
  minutesStep: number;
};

export type Option = {
  value: number;
  label: string;
};

export function getMinutesOptions({
  maxMinutesValue,
  minutesStep,
}: GetMinutesOptionsArgs): Option[] {
  const arrLength = maxMinutesValue / minutesStep;
  if (!Number.isInteger(arrLength)) {
    throw Error(`maxMinutesValue:${maxMinutesValue} is indivisible by minutesStep:${minutesStep}`);
  }

  const optionList: Option[] = [];
  for (let index = 0; index <= arrLength; index++) {
    const optionValue = index * minutesStep;
    optionList.push({ value: optionValue, label: `${optionValue} min` });
  }

  return optionList;
}
