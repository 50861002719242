import React, { useCallback } from 'react';

import { DateRange } from '@zero5/ui/lib/Chart';

import { RevenueTrendAggregationPeriod } from '@/api/revenue/models';

import useDateModule from '@/utils/date/useDateModule';
import isAggregationAvailable from '@/utils/date/isAggregationAvailable';

const useGetChartDateRangeChanger = (
  onDateRangeChange: (dateRange: DateRange) => void,
  setChartAggregation?: React.Dispatch<React.SetStateAction<RevenueTrendAggregationPeriod>>,
) => {
  const {
    z5DateModule,
  } = useDateModule();

  return useCallback((selectedRange: DateRange, aggregation: RevenueTrendAggregationPeriod) => {
    const endOfToday = new Date(z5DateModule.fromUtc(Date.now()).dayEnd().getTimestamp());

    switch (aggregation) {
      case 'weekly':
        if (isAggregationAvailable('weekly', selectedRange) || !setChartAggregation) {
          const newStart = new Date(z5DateModule.fromUtc(selectedRange.start.getTime()).weekStart().getTimestamp());
          const newEnd = new Date(
            Math.min(
              z5DateModule.fromUtc(selectedRange.end.getTime()).weekEnd().getTimestamp(),
              endOfToday.getTime(),
            ),
          );

          // eslint-disable-next-line no-param-reassign
          selectedRange = {
            start: newStart,
            end: newEnd,
          };
        } else {
          setChartAggregation?.('daily');
        }
        break;
      case 'monthly':
        if (isAggregationAvailable('monthly', selectedRange) || !setChartAggregation) {
          const newStart = new Date(z5DateModule.fromUtc(selectedRange.start.getTime()).monthStart().getTimestamp());
          const newEnd = new Date(
            Math.min(
              z5DateModule.fromUtc(selectedRange.end.getTime()).monthEnd().getTimestamp(),
              endOfToday.getTime(),
            ),
          );

          // eslint-disable-next-line no-param-reassign
          selectedRange = {
            start: newStart,
            end: newEnd,
          };
        } else {
          setChartAggregation?.('daily');
        }
    }

    onDateRangeChange(selectedRange);
  },
  [onDateRangeChange, z5DateModule]);
};

export default useGetChartDateRangeChanger;