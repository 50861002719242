import React from 'react';

import vars from '@zero5/ui/lib/styles/vars';

import { InvoiceStatus as InvoiceStatusType } from '@/api/revenue/models';

import Status from '@/components/common/Status';

interface Props {
  status: InvoiceStatusType;
}

const statusToView = {
  PENDING: {
    color: '#2541B2',
    label: 'Pending',
  },
  SUCCESSFUL: {
    color: vars.palette.primary,
    label: 'Successful',
  },
  FAILED: {
    color: vars.palette.danger,
    label: 'Failed',
  },
};

const InvoiceStatus: React.FC<Props> = ({ status }) => {
  return (
    <Status color={statusToView[status].color}>
      {statusToView[status].label}
    </Status>
  );
};

export default InvoiceStatus;