import React from 'react';
import styled from 'styled-components';

import {
  Button,
  Card,
  CardHead,
  ConfirmationModal,
  DeleteButton,
  InfoButton,
  Modal,
  Row,
  RowsMap,
  Table,
  TableContent,
} from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import useTablePagination from '@zero5/ui/lib/utils/hooks/useTablePagination';

import { WatchlistVehicleTableItem } from '@/api/watchlist/models';
import useWatchlistVehiclesQuery from '@/api/watchlist/useWatchlistVehiclesQuery';
import { TableSortingKeys as WatchlistTableSortingKeys } from '@/api/watchlist/useWatchlistVehiclesQuery/types';
import useDeleteWatchlistVehicleMutation from '@/api/watchlist/useDeleteWatchlistVehicleMutation';
import useSearchWatchlistVehiclesQuery from '@/api/watchlist/useSearchWatchlistVehiclesQuery';

import WatchlistModal from './modals/WatchlistModal';
import SearchInput from './SearchInput';

interface Props {}

const WatchlistContent: React.FC<Props> = ({}) => {
  const [isAddModalOpen, closeAddModal, openAddModal] = useToggle(false);
  const [isInfoModalOpen, closeInfoModal, openInfoModal] = useToggle(false);
  const [isDeleteModalOpen, closeDeleteModal, openDeleteModal] = useToggle(false);

  const [infoModalData, setInfoModalData] = React.useState<number>();
  const [deleteModalData, setDeleteModalData] = React.useState<WatchlistVehicleTableItem>();

  const [
    page,
    rowsPerPage,
    changePageHandler,
    changeRowsPerPageHandler,
    ,
    ,
    order,
    orderBy,
    changeSortingHandler,
  ] = useTablePagination();

  const [searchQuery, setSearchQuery] = React.useState('');

  const deleteWatchlistVehicleMutation = useDeleteWatchlistVehicleMutation({
    onSuccess: () => {
      closeDeleteModal();
    },
  });

  const watchlistQuery = useWatchlistVehiclesQuery({
    take: rowsPerPage,
    skip: rowsPerPage * page,
    order,
    orderBy: orderBy as WatchlistTableSortingKeys,
  });
  const searchWatchlistVehicleQuery = useSearchWatchlistVehiclesQuery({
    lpNum: searchQuery,
  }, {
    enabled: searchQuery.length > 0,
  });

  const deleteWatchlistHandler = React.useCallback(() => {
    deleteWatchlistVehicleMutation.mutateAsync({
      watchlistVehicleId: deleteModalData?.watchlistVehicleId!,
    });
  }, [deleteModalData?.watchlistVehicleId, deleteWatchlistVehicleMutation]);

  const rowsMap = React.useMemo<RowsMap<WatchlistVehicleTableItem>>(() => [
    {
      id: 'lpNum',
      title: 'License Plate',
      data: ({ vehicle: { lpNum } }) => lpNum,
    },
    {
      id: 'make',
      title: 'Vehicle Make',
      data: ({ vehicle: { make } }) => make,
    },
    {
      id: 'model',
      title: 'Vehicle Type',
      data: ({ vehicle: { model } }) => model,
    },
    {
      id: 'color',
      title: 'Vehicle Color',
      data: ({ vehicle: { color } }) => color,
    },
    {
      id: 'reason',
      title: 'Note',
      data: ({ reason }) => reason,
    },
    {
      id: 'info',
      title: 'Info',
      data: (item) => {
        const { watchlistVehicleId } = item;
        return (
          <Row gap="20px">
            <InfoButton onClick={() => {
              setInfoModalData(watchlistVehicleId);
              openInfoModal();
            }} />
            <DeleteButton onClick={() => {
              setDeleteModalData(item);
              openDeleteModal();
            }} />
          </Row>
        );
      },
      disableSorting: true,
      HeadCellProps: {
        align: 'left',
      },
    },
  ], [openDeleteModal, openInfoModal]);

  return (
    <>
      <ButtonRow justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={openAddModal}>Add Vehicle</Button>
      </ButtonRow>
      <Card>
        <CardHead
          title="Watchlist"
          headerContent={(
            <SearchInput onChange={setSearchQuery} />
          )}
        />
        {searchQuery.length > 0 ? (
          <TableContent
            isLoading={searchWatchlistVehicleQuery.isLoading}
            data={searchWatchlistVehicleQuery.data || []}
          >
            <Table
              data={searchWatchlistVehicleQuery.data!}
              rowsMap={rowsMap}
              isLoading={searchWatchlistVehicleQuery.isFetching}
            />
          </TableContent>
        ) : (
          <TableContent
            isLoading={watchlistQuery.isLoading}
            data={watchlistQuery.data?.rows || []}
          >
            <Table
              data={watchlistQuery.data?.rows!}
              rowsMap={rowsMap}
              count={watchlistQuery.data?.rowsCount!}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={changePageHandler}
              onRowsPerPageChange={changeRowsPerPageHandler}
              order={order}
              orderBy={orderBy}
              onSortChange={changeSortingHandler}
              isLoading={watchlistQuery.isFetching}
            />
          </TableContent>
        )}
      </Card>
      <Modal
        open={isAddModalOpen}
        onClose={closeAddModal}
        fullScreenSize="520px"
      >
        <WatchlistModal
          mode="add"
          onCancel={closeAddModal}
        />
      </Modal>
      <Modal
        open={isInfoModalOpen}
        onClose={closeInfoModal}
        fullScreenSize="520px"
      >
        <WatchlistModal
          id={infoModalData!}
          mode="info"
          onCancel={closeInfoModal}
        />
      </Modal>
      <ConfirmationModal
        onAccept={deleteWatchlistHandler}
        onReject={closeDeleteModal}
        open={isDeleteModalOpen}
        text={`Are you sure you want to remove ${deleteModalData?.vehicle.lpNum} from watchlist?`}
        loading={deleteWatchlistVehicleMutation.isLoading}
      />
    </>
  );
};

const ButtonRow = styled(Row)`
  margin-bottom: 30px;
`;

export default WatchlistContent;