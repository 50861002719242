import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationDetails } from '@zero5/garage-api';

import { validationHistoryQueryKeys } from './queryKeys';
import { mapValidationDetails } from './mappings';

type Response = ReturnType<typeof mapValidationDetails>;
type Key = ReturnType<typeof validationHistoryQueryKeys.validationDetails>;
type Params = {
  validationId: string;
};

const useValidationDetailsQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    validationHistoryQueryKeys.validationDetails(params.validationId),
    async () => {
      const response = await getValidationDetails(params.validationId);

      return mapValidationDetails(response);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useValidationDetailsQuery;
