import React, { useState } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

import { Button, Skeleton } from '@zero5/ui';

import useCompanyGarageSignsQuery from '@/api/queries/useCompanyGarageSignsQuery';
import useValidationTypesAvailableNowQuery from '@/api/validation/useValidationTypesAvailableNowQuery';

import ValidationTypeSelect from '@/components/Validation/ValidationTypeSelect';
import ValidationContent, {
  ValidationType,
} from '@/components/Validation/ValidationContent';

import ArrowSvg from '@/assets/icons/arrow_back_black.inline.svg';

const ValidationForCustomers: React.FC = () => {
  const [validationType, setValidationType] = useState<ValidationType>(null);

  const companyGarageSignQuery = useCompanyGarageSignsQuery();
  const validationTypesAvailableNowQuery = useValidationTypesAvailableNowQuery();

  const qrCodeLink = React.useMemo(() => {
    return `${process.env.PAY_PAGE_VALIDATION}/${companyGarageSignQuery.data?.signs.validationReceipt}`;
  }, [companyGarageSignQuery.data?.signs.validationReceipt]);

  const handleGoBackClick = () => setValidationType(null);

  const hasAvailableValidations = Boolean(validationTypesAvailableNowQuery.data?.length);

  if (!validationType) {
    return (
      <>
        <QRCodeWrapper>
          {
            companyGarageSignQuery.isLoading ||
            validationTypesAvailableNowQuery.isLoading
              ? <Skeleton width="150px" height="150px" variant="rect" />
              : hasAvailableValidations && <QRCode size={150} value={qrCodeLink} />
          }
        </QRCodeWrapper>
        <ValidationTypeSelect setValidationType={setValidationType} />
      </>
    );
  }
  return (
    <>
      <GoBackButton onClick={handleGoBackClick}>
        <ArrowSvg />
        <GoBackText>Go Back</GoBackText>
      </GoBackButton>
      <ValidationContent
        validationType={validationType}
        handleGoBackClick={handleGoBackClick}
      />
    </>
  );
};

const GoBackButton = styled(Button)`
  padding: 0;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 77px;
  :hover {
  background-color: transparent;
}
`;

const GoBackText = styled.span`
font-style: normal;
font-weight: normal;
margin-left:10px;
text-transform: none;
font-size: 18px;
line-height: 21px;
`;

const QRCodeWrapper = styled.div`
display: flex;
align-items: center;
min-height: 150px;
justify-content: center;
`;

export default ValidationForCustomers;
