import { useMemo } from 'react';

import { DateRange } from '@zero5/ui/lib/Chart';

import { RevenueTrendAggregationPeriod } from '@/api/revenue/models';

import isAggregationAvailable from '@/utils/date/isAggregationAvailable';

export interface AllowedAggregation {
  daily?: boolean;
  weekly?: boolean;
  monthly?: boolean;
}

export const defaultAllowedAggregation = {
  daily: true,
  weekly: true,
  monthly: true,
};

const useGetChartAggregationOptions = (
  dateRange: DateRange,
  allowedAggregation: AllowedAggregation = defaultAllowedAggregation,
) => {
  return useMemo(() => {
    const options: Array<{
      label: string;
      value: RevenueTrendAggregationPeriod;
    }> = [];

    if (allowedAggregation.daily && isAggregationAvailable('daily', dateRange)) {
      options.push({
        label: 'Daily',
        value: 'daily',
      });
    }

    if (allowedAggregation.weekly && isAggregationAvailable('weekly', dateRange)) {
      options.push({
        label: 'Weekly',
        value: 'weekly',
      });
    }

    if (allowedAggregation.monthly && isAggregationAvailable('monthly', dateRange)) {
      options.push({
        label: 'Monthly',
        value: 'monthly',
      });
    }

    return options;
  }, [dateRange]);
};

export default useGetChartAggregationOptions;