const maxValidationHours = [
  {
    label: '1 hour',
    value: 1,
  },
  {
    label: '2 hours',
    value: 2,
  },
  {
    label: '3 hours',
    value: 3,
  },
  {
    label: '4 hours',
    value: 4,
  },
  {
    label: '5 hours',
    value: 5,
  },
  {
    label: '6 hours',
    value: 6,
  },
  {
    label: '12 hours',
    value: 12,
  },  
];

export default maxValidationHours;
