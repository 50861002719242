import React, { useState } from 'react';
import styled from 'styled-components';
import flow from 'lodash/flow';

import {
  Button,
  ButtonIcon,
  CategoryCard,
  ConfirmationModal,
  formatPhone,
  Row,
  RowsMap,
  Table,
  TableCard,
  TableContent,
} from '@zero5/ui';
import { capitalize } from '@zero5/ui/lib/utils/formatters/capitalize';

import useGarageTenantsQuery from '@/api/tenants/useGarageTenantsQuery';
import useDeleteTenantCompanyMutation from '@/api/tenants/useDeleteTenantCompanyMutation';
import useTenantsCountQuery from '@/api/tenants/useTenantsCountQuery';
import { Tenant } from '@/api/tenants/models';

import Status from '@/components/common/Status';
import AddUpdateTenantModal from '@/components/tenants/modals/AddUpdateTenantModal';
import Page from '@/components/common/page';
import Role, { useFindCurrentAction } from '@/components/common/Role';
import TableHead from '@/components/common/TableHead';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

import InfoIcon from '@/assets/icons/dots.inline.svg';
import DeleteIcon from '@/assets/icons/delete.inline.svg';

interface Props {}

const statusToView: Record<string, {
  label: string;
  color: string;
}> = {
  active: {
    label: 'Active',
    color: '#007AFF',
  },
  invite_sent: {
    label: 'Invite Sent',
    color: '#FF9F1C',
  },
  deactivated: {
    label: 'Deactivated',
    color: '#EF5164',
  },
};

const ManageTenants: React.FC<Props> = () => {
  const [currentTenant, setCurrentTenant] = useState<Tenant | null>(null);
  const [modalType, setModalType] = useState<'add' | 'update' | 'delete' | null>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tenants = useGarageTenantsQuery();
  const tenantsCount = useTenantsCountQuery();
  const deleteTenantCompanyMutation = useDeleteTenantCompanyMutation();
  const findCurrentAction = useFindCurrentAction();

  const closeModal = React.useCallback(() => {
    setModalType(null);
  }, []);

  const cardsData = [
    {
      title: 'Total Tenants',
      data: tenantsCount.data?.tenantCompaniesCount || '-',
    },
    {
      title: 'Registered Tenant Users',
      data: tenantsCount.data?.tenantUsersCount || '-',
    },
  ];

  const tenantsTableRowsMap = React.useMemo(
    () => {
      const tenantsRowsMap: RowsMap<Tenant> = [
        {
          title: 'Tenant Name',
          id: 'companyName',
          data: ({ companyName }) => companyName,
        },
        {
          title: 'Tenant Type',
          id: 'companyType',
          data: ({ companyType }) => (companyType ? capitalize(companyType) : emptyFieldStub),
        },
        {
          title: 'Main Account',
          id: 'mainAccount',
          data: ({ tenantAdmin: { firstName, lastName } }) => `${firstName} ${lastName}`,
          getSortComparator: ({ tenantAdmin: { firstName, lastName } }) => `${firstName} ${lastName}`,
        },
        {
          title: 'Phone Number',
          id: 'phone',
          data: ({ tenantAdmin: { phone } }) => (phone ? formatPhone(phone) : emptyFieldStub),
          getSortComparator: ({ tenantAdmin: { phone } }) => phone,
        },
        {
          title: 'Email',
          id: 'email',
          data: ({ tenantAdmin: { email } }) => email,
          getSortComparator: ({ tenantAdmin: { email } }) => email,
        },
        {
          title: 'Status',
          id: 'status',
          data: ({ tenantAdmin: { status } }) => (
            <Status color={statusToView[status]?.color}>
              {statusToView[status]?.label}
            </Status>
          ),
          getSortComparator: ({ tenantAdmin: { status } }) => status,
        },
      ];
      if (findCurrentAction('tenant:updateTenant') === 'w') {
        tenantsRowsMap.push({
          title: 'Info',
          id: 'info',
          data: (user) => {
            return (
              <StyledButtonIcon
                Icon={InfoIcon}
                onClick={() => {
                  setCurrentTenant(user);
                  setModalType('update');
                }}
                data-test="manage-tenants-info-icon"
              />
            );
          },
          disableSorting: true,
        });
      }
      if (findCurrentAction('tenant:deleteTenant') === 'w') {
        tenantsRowsMap.push({
          title: 'Delete',
          id: 'delete',
          data: (user) => {
            return (
              <StyledButtonIcon
                Icon={DeleteIcon}
                onClick={() => {
                  setCurrentTenant(user);
                  setModalType('delete');
                }}
                data-test="manage-tenants-delete-icon"
              />
            );
          },
          disableSorting: true,
        });
      }
      return tenantsRowsMap;
    },
    [findCurrentAction],
  );

  const deleteTenantCompanyHandler = async () => {
    await deleteTenantCompanyMutation.mutateAsync({ companyId: currentTenant!.companyId });
    if (tenants.data!.length % rowsPerPage === 1) {
      setPage((prev) => Math.max(prev - 1, 0));
    }
    closeModal();
    await tenants.refetch();
    await tenantsCount.refetch();
  };

  return (
    <Page>
      <PageWrapper>
        <Role widgetId="tenant:stats" action="r">
          <CardsWrapper>
            {cardsData.map((props) => (
              <CategoryCard key={props.title} {...props} />
            ))}
          </CardsWrapper>
        </Role>
        <StyledRow justifyContent="flex-end">
          <Role widgetId="tenant:createTenant" action="w">
            <Button
              color="primary"
              onClick={() => setModalType('add')}
              variant="contained"
              data-test="create-tenant-btn"
            >
              Create Tenant Account
            </Button>
          </Role>
        </StyledRow>
        <TableCard>
          <TableHead
            title="Tenant List"
          />
          <TableContent
            isLoading={tenants.isLoading}
            data={tenants.data}
          >
            <StyledTable
              rowsMap={tenantsTableRowsMap}
              data={tenants.data!}
              page={page}
              onPageChange={(_event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
              data-test="tenant-list"
            />
          </TableContent>
        </TableCard>
        <Role widgetId="tenant:deleteTenant" action="w">
          <ConfirmationModal
            open={modalType === 'delete'}
            text="Are you sure you want to delete"
            variableText={`${currentTenant?.companyName}`}
            text2={'\'s account?'}
            onAccept={deleteTenantCompanyHandler}
            onReject={closeModal}
            loading={deleteTenantCompanyMutation.isLoading}
            acceptText={'Delete'}
          />
        </Role>
        <Role widgetId="tenant:tenant" action="w">
          <AddUpdateTenantModal
            open={modalType === 'add'}
            onClose={closeModal}
            modalType="add"
          />
        </Role>
        <Role widgetId="tenant:tenant" action="w">
          <AddUpdateTenantModal
            open={modalType === 'update'}
            onClose={closeModal}
            tenant={currentTenant}
            modalType="update"
          />
        </Role>
      </PageWrapper>
    </Page>

  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTable = styled(Table)`
  margin-top: 10px;
  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
` as typeof Table;

const StyledRow = styled(Row)`
  margin-bottom: 35px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  display: flex;
  margin: 0 auto;
`;

export default flow(
  withGarage,
  withRole('tenant'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(ManageTenants);
