import React from 'react';
import styled from 'styled-components';

import { InfoButton, RowsMap, Table, TableContent } from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';

import useValidationTypeListQuery from '@/api/validationType/useGetValidationTypesQuery';
import { ValidationType } from '@/api/models/ValidationType';

import ValidationPolicyModal from '../ParkingPolicy/ValidationPolicyModal';

interface Props {}

const ValidationTypes: React.FC<Props> = ({}) => {
  const [isInfoModalOpen, closeInfoModal, openInfoModal] = useToggle(false);
  const [infoModalData, setInfoModalData] = React.useState<ValidationType>();

  const openInfoModalHandler = React.useCallback((item: ValidationType) => {
    openInfoModal();
    setInfoModalData(item);
  }, [openInfoModal]);

  const closeInfoModalHandler = React.useCallback(() => {
    closeInfoModal();
  }, [closeInfoModal]);

  const validationTypesRowsMap = React.useMemo<RowsMap<ValidationType>>(() => [
    {
      title: 'Type Name',
      id: 'name',
      data: ({ name }) => name,
    },
    {
      title: 'Permit Price',
      id: 'price',
      data: ({ price }) => formatPrice(price),
    },
    {
      title: 'Info',
      id: 'info',
      disableSorting: true,
      data: (item) => (
        <InfoButton onClick={() => openInfoModalHandler(item)} />
      ),
    },
  ], [openInfoModalHandler]);

  const validationTypeListQuery = useValidationTypeListQuery();

  return (
    <>
      <StyledTableContent
        isLoading={validationTypeListQuery.isLoading}
        data={validationTypeListQuery.data || []}
      >
        <Table
          rowsMap={validationTypesRowsMap}
          data={validationTypeListQuery.data!}
          withoutPagination
        />
      </StyledTableContent>
      <ValidationPolicyModal
        open={isInfoModalOpen}
        title="Validation Type Information"
        data={infoModalData}
        onClose={closeInfoModalHandler}
      /> 
    </>
  );
};

const StyledTableContent = styled(TableContent)`
  min-height: unset;
  max-height: 270px;
  height: 100%;
  overflow-y: auto;
`;

export default ValidationTypes;