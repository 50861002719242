import { useQuery, UseQueryOptions } from 'react-query';

import { getEvents, EventStatus } from '@zero5/garage-api';

import { mapEventsResponse } from '@/api/mappings/event';

import { eventQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapEventsResponse>;

type Params = {
  from: number;
  to: number;
  statuses: Array<EventStatus>;
};

export type QueryKey = ReturnType<typeof eventQueryKeys.eventList>;

const useEventsQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, QueryKey>,
) => {
  return useQuery(
    eventQueryKeys.eventList(params),
    async () => {
      const response = await getEvents({
        statuses: params.statuses,
        fromDate: params.from,
        toDate: params.to,
      });
      return mapEventsResponse(response);
    },
    {
      ...options,
    },
  );
};

export default useEventsQuery;
