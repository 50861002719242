import { useMutation, UseMutationOptions } from 'react-query';

import { resendTemporaryPassword } from '@zero5/auth-api';

type Response = unknown;
type Params = {
  email: string;
};

const useResendTemporaryPasswordMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'resendTemporaryPassword',
    async (params) => {
      const response = await resendTemporaryPassword(params);

      return response;
    },
    options,
  );
};

export default useResendTemporaryPasswordMutation;
