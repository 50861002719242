import React from 'react';
import styled from 'styled-components';
import flow from 'lodash/flow';

import {
  BigCategoryCard,
  CardHead,
  Select, 
  Card,
} from '@zero5/ui';

import Page from '@/components/common/page';
import AllValidations from '@/components/validationHistory/AllValidations';
import ValidationTypes from '@/components/validationHistory/ValidationTypes';
import Cards from '@/components/validationHistory/Cards';
import AccountValidations from '@/components/validationHistory/AccountValidations';
import ValidationTrend from '@/components/validationHistory/ValidationTrend';

import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

type TableType = 'all' | 'byTenant';

const tableTypeOptions: Array<{ value: TableType; label: string; }> = [
  { value: 'all', label: 'All Validations' },
  { value: 'byTenant', label: 'By Tenant' },
];

interface Props {}

const ValidationHistory: React.FC<Props> = () => {
  const [tableType, setTableType] = React.useState<TableType>('all');

  return (
    <Page>
      <Cards />
      <CardsWrapper>
        <ValidationTrend />
        <BigCategoryCard title="Validation Types" headerComponent={ValidationTypesCardHeaderComponent}>
          <ValidationTypes />
        </BigCategoryCard>
      </CardsWrapper>
      <Card>
        <CardHead
          title="Validation History"
          headerContent={(
            <StyledSelect
              options={tableTypeOptions}
              value={tableTypeOptions.find(({ value }) => value === tableType)}
              onChange={(option) => setTableType(option!.value)}
            />
          )}
        />
        {tableType === 'all' && <AllValidations />}
        {tableType === 'byTenant' && <AccountValidations />}
      </Card>
    </Page>
  );
};

const ValidationTypesCardHeaderComponent = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 15px));
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: 100%;
  }
`;

const StyledSelect = styled(Select)`
  width: 160px;
` as typeof Select;

export default flow( 
  withGarage,
  withRole('validationHistory'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(ValidationHistory);
