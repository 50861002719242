import React from 'react';
import saveAs from 'file-saver';

import useExportValidationHistoryMutation from '@/api/validationHistory/useExportValidationHistoryMutation';
import useGetCompanyQuery from '@/api/user/useGetCompanyQuery';

import ExportButton from '@/components/common/ExportButton';

import useDateModule from '@/utils/date/useDateModule';

interface Props {
  companyId: number;
  fromDate: number;
  toDate: number;
}

const ExportValidationHistorySection: React.FC<Props> = ({ companyId, fromDate, toDate }) => {
  const {
    formatMonthYear,
  } = useDateModule();

  const exportValidationHistoryMutation = useExportValidationHistoryMutation();

  const companyQuery = useGetCompanyQuery({
    companyId,
  });

  const exportHandler = React.useCallback(async () => {
    try {
      const response = await exportValidationHistoryMutation.mutateAsync({
        companyId,
        fromDate,
        toDate,
      });

      const fileName = `ValidationHistory_${companyQuery.data!.name}_${formatMonthYear(fromDate)}.csv`;
      const file = new File([response.report], fileName, { type: 'text/plain;charset=utf-8' });
      saveAs(file);
    } catch (error) {
      console.error(error);
    }
  }, [companyId, companyQuery.data, exportValidationHistoryMutation, formatMonthYear, fromDate, toDate]);

  return (
    <ExportButton
      onClick={exportHandler}
      disabled={companyQuery.isLoading}
      isExporting={exportValidationHistoryMutation.isLoading}
      data-test="validation-history-modal-export-btn"
    />
  );
};

export default ExportValidationHistorySection;