import React from 'react';
import styled from 'styled-components';

import vars from '@zero5/ui/lib/styles/vars';
import { ButtonIcon } from '@zero5/ui';

import MinusIcon from '@/assets/icons/minus.inline.svg';
import PlusIcon from '@/assets/icons/plus.inline.svg';

interface Props {
  disabled?: boolean;
  withoutRemove?: boolean;
  onAdd?: () => void;
  onRemove?: () => void;
  className?: string;
}

const RowControlButtons: React.FC<Props> = ({
  disabled,
  withoutRemove,
  onAdd,
  onRemove,
  className,
}) => {
  return (
    <Grid className={className}>
      <ButtonIcon
        iconProps={{
          width: '20px',
          fill: disabled ? vars.palette.secondary : vars.palette.primary,
        }}
        disabled={disabled}
        Icon={PlusIcon}
        onClick={onAdd}
        type="button"
      />
      {!withoutRemove && (
        <ButtonIcon
          iconProps={{
            width: '20px',
            fill: disabled ? vars.palette.secondary : vars.palette.danger,
          }}
          disabled={disabled}
          Icon={MinusIcon}
          onClick={onRemove}
          type="button"
        />
      )}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20px 20px;
  grid-gap: 10px;
`;

export default RowControlButtons;