import {
  GetWatchlistContactsResponseType,
  GetWatchlistSettingsResponseType,
  GetWatchlistVehicleTableResponseType,
  WatchlistGateBehavior,
  WatchlistVehicleData,
  WatchlistVehicleTableRow,
} from '@zero5/garage-api';

import { mapCompanyUser } from '../mappings/user';

import { GateBehavior, WatchlistVehicle, WatchlistVehicleTableItem } from './models';

type WatchlistTableItem = Omit<WatchlistVehicleTableRow, 'timestamp'>;

export const mapWatchlistTableItem = (data: WatchlistTableItem): WatchlistVehicleTableItem => ({
  watchlistVehicleId: data.watchlistVehicleId,
  vehicle: {
    lpNum: data.lpNum,
    make: data.make || '',
    model: data.model || '',
    color: data.color || '',
  },
  reason: data.reason,
});

export const mapWatchlistItem = (data: WatchlistVehicleData): WatchlistVehicle => ({
  watchlistVehicleId: data.watchlistVehicleId,
  watchlistId: data.watchlistId,
  vehicle: {
    lpNum: data.lpNum,
    make: data.make || '',
    model: data.model || '',
    color: data.color || '',
    state: data.lpState || '',
  },
  note: data.note || '',
  reason: data.reason,
});

export const mapWatchlistTableItems = (data: Array<WatchlistTableItem>) =>
  data.map(mapWatchlistTableItem);

export const mapWatchlist = (data: GetWatchlistVehicleTableResponseType) => ({
  rows: mapWatchlistTableItems(data.rows),
  rowsCount: data.rowsCount,
  pagesCount: data.pagesCount,
});

export const mapWatchlistSettings = (data: GetWatchlistSettingsResponseType) => ({
  settings: {
    gateBehavior: mapGateBehavior(data.settings.gateBehavior),
  },
});

export const mapWatchlistContacts = (data: GetWatchlistContactsResponseType) => ({
  users: data.users.map(mapCompanyUser),
});

export const mapGateBehavior = (data: WatchlistGateBehavior): GateBehavior => {
  const map = {
    [WatchlistGateBehavior.DO_NOT_OPEN]: 'DO_NOT_OPEN',
    [WatchlistGateBehavior.OPEN_AND_NOTIFY]: 'OPEN_AND_NOTIFY',
  } as const;

  return map[data];
};

export const unmapGateBehavior = (data: GateBehavior): WatchlistGateBehavior => {
  const map = {
    'DO_NOT_OPEN': WatchlistGateBehavior.DO_NOT_OPEN,
    'OPEN_AND_NOTIFY': WatchlistGateBehavior.OPEN_AND_NOTIFY,
  } as const;

  return map[data];
};
