import {
  GetValidationDetailsResponseType,
  GetValidationHistoryCardDataResponseType,
  ValidationTableAllValidationsResult,
  ValidationData,
  ValidationTableAllValidationsRow,
  ValidationTableByTenantRow,
  ValidationTableByTenantResult,
  ValidationTableByTenantSummaryRow,
  ValidationTableByTenantSummaryResult,
  ValidationTableResult,
  ValidationTableByTenantDetailsRow,
  ValidationTableByTenantDetailsResult,
} from '@zero5/garage-api';

import { mapUser } from '@/api/mappings/user';

import { mapGarage } from '../garage/mappings';
import { mapValidationType } from '../mappings/validationType';
import { mapTenantTypeName, mapCompany } from '../permit/mappings';

import {
  AccountValidationDetailsItem,
  AccountValidationHistory,
  AccountValidationSummaryItem,
  Validation,
  ValidationHistory,
} from './models';

const mapTableResponse = <T, M>(data: ValidationTableResult<T>, mappingFunction: (data: T) => M) => ({
  rows: data.rows.map(mappingFunction),
  rowsCount: data.rowsCount,
  pagesCount: data.pagesCount,
});

const mapValidationHistory = (data: ValidationTableAllValidationsRow): ValidationHistory => ({
  validationId: data.validationId,
  date: data.timestamp,
  type: data.type,
  note: data.note,
  tenantName: data.tenantName,
  userName: data.userName,
  amount: data.amount,
});

export const mapAllValidations = (data: ValidationTableAllValidationsResult) =>
  mapTableResponse(data, mapValidationHistory);

const mapAccountValidationHistory = (data: ValidationTableByTenantRow): AccountValidationHistory => ({
  companyId: data.companyId,
  date: data.timestamp,
  tenantName: data.tenantName,
  tenantType: mapTenantTypeName[data.tenantType],
  validationCount: data.validationCount,
  amount: data.amount,
});

export const mapValidationsByTenant = (data: ValidationTableByTenantResult) =>
  mapTableResponse(data, mapAccountValidationHistory);

const mapValidationData = (data: ValidationData): Validation => ({
  validationId: data.validationId,
  type: mapValidationType(data.type),
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  lpNum: data.lpNum,
  created: data.created,
});

export const mapValidationDetails = (data: GetValidationDetailsResponseType) => ({
  validationData: mapValidationData(data.validation),
  garage: mapGarage(data.garage),
  price: data.costCents,
  company: mapCompany(data.company),
  user: data.user ? mapUser(data.user) : null,
});

export const mapValidationHistoryCards = (data: GetValidationHistoryCardDataResponseType) => ({
  validationBalance: data.validationBalance,
  validationCount: data.validationCount,
});

const mapAccountValidationSummaryItem = (data: ValidationTableByTenantSummaryRow): AccountValidationSummaryItem => ({
  validationTypeName: data.validationTypeName,
  validationCount: data.validationCount,
  unitPrice: data.unitPrice,
  totalAmount: data.totalAmount,
});

export const mapAccountValidationSummary = (data: ValidationTableByTenantSummaryResult) =>
  mapTableResponse(data, mapAccountValidationSummaryItem);

const mapAccountValidationDetailsItem = (data: ValidationTableByTenantDetailsRow): AccountValidationDetailsItem => ({
  validationId: data.validationId,
  date: data.timestamp,
  type: data.type,
  note: data.note,
  amount: data.amount,
  totalAmount: data.totalAmount,
});
  
export const mapAccountValidationDetails = (data: ValidationTableByTenantDetailsResult) =>
  mapTableResponse(data, mapAccountValidationDetailsItem);
    