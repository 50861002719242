const eligibleTenantTypes = [
  'Management',
  'Corporate',
  'Entertainment',
  'Retail',
  'Hotel',
  'Public',
  'Restaurant',
  'Security',
];

export default eligibleTenantTypes;
