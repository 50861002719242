import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BigCategoryCard,
  LineChart,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';

import useValidationTrendQuery from '@/api/validationHistory/useValidationTrendQuery';

import { selectValidationHistorySettings } from '@/store/selectors/settings';
import { settingsActions } from '@/store/actions/settings';

import AggregationRangePicker, { AggregationPeriod } from '@/components/common/AggregatedRangePicker';

import useDateModule from '@/utils/date/useDateModule';

interface Props {}

const mapAggregationPeriod: Record<AggregationPeriod, 'day' | 'week' | 'month'>  = {
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
};

const ValidationTrend: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const {
    formatFullMonthAndDate,
  } = useDateModule();

  const [aggregation, setAggregation] = React.useState<AggregationPeriod>('daily');

  const { trendDateRange } = useSelector(selectValidationHistorySettings);

  const validationTrendQuery = useValidationTrendQuery({
    fromDate: trendDateRange.start.getTime(),
    toDate: trendDateRange.end.getTime(),
    aggregationPeriod: mapAggregationPeriod[aggregation],
  });

  const trendDateRangeChangeHandler = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setValidationHistorySettings({
      trendDateRange: newDateRange,
    }));
  }, [dispatch]);

  return (
    <BigCategoryCard
      title="Validation Trend"
      gap="10px"
      headerContent={
        <AggregationRangePicker
          dateRange={trendDateRange}
          onDateRangeChange={trendDateRangeChangeHandler}
          aggregation={aggregation}
          onAggregationPeriodChange={setAggregation}
        />
      }
    >
      <LineChart
        data={validationTrendQuery.data?.map((item) => item.value)!}
        labels={validationTrendQuery.data?.map((item) => formatFullMonthAndDate(item.date))!}
        isLoading={validationTrendQuery.isLoading}
      />
    </BigCategoryCard>
  );
};

export default ValidationTrend;