export const validationHistoryQueryKeys = {
  base: ['validationHistory'] as const,
  allValidations: (params: {
    sortBy?: 'timestamp' | 'note' | 'type' | 'tenantName' | 'userName' | 'amount';
    order?: 'ASC' | 'DESC';
    skip?: number;
    take?: number;
  }) => [...validationHistoryQueryKeys.base, 'allValidations', params] as const,
  validationsByTenant: (params: {
    sortBy?: 'timestamp' | 'amount' | 'tenantName' | 'tenantType' | 'validationCount';
    order?: 'ASC' | 'DESC';
    skip?: number;
    take?: number;
  }) => [...validationHistoryQueryKeys.base, 'validationsByTenant', params] as const,
  validationDetails: (validationId: string) => [...validationHistoryQueryKeys.base, 'validationDetails', validationId],
  cards: (params: {
    fromDate: number;
    toDate: number;
  }) => [...validationHistoryQueryKeys.base, 'cards', params],
  trend: (params: {
    fromDate: number;
    toDate: number;
    aggregationPeriod: 'day' | 'week' | 'month';
  }) => [...validationHistoryQueryKeys.base, 'trend', params],
  accountValidationSummary: (params: {
    companyId: number;
    fromDate: number;
    toDate: number;
  }) => [...validationHistoryQueryKeys.base, 'accountValidationSummary', params],
  accountValidationDetails: (params: {
    skip: number;
    take: number;
    fromDate: number;
    toDate: number;
    companyId: number;
  }) => [...validationHistoryQueryKeys.base, 'accountValidationDetails', params],
};
