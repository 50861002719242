import React, { ChangeEvent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import schemas from '@zero5/ui/lib/utils/validation/schemas';
import {
  Input, Modal, ModalProps, Select, Button, Row, InputPhone, Checkbox, Skeleton, ButtonIcon,
} from '@zero5/ui';
import { capitalize } from '@zero5/ui/lib/utils/formatters/capitalize';
import { arrayUtils } from '@zero5/ui/lib/utils/array';
import vars from '@zero5/ui/lib/styles/vars';

import useCreateTenantMutation from '@/api/tenants/useCreateTenantMutation';
import useTenantTypeListQuery from '@/api/tenants/useTenantTypeListQuery';
import { Tenant } from '@/api/tenants/models';
import useUpdateTenantCompanyMutation from '@/api/tenants/useUpdateTenantCompanyMutation';
import useUpdateTenantUserMutation from '@/api/tenants/useUpdateTenantUserMutation';
import useCompanyFeaturesQuery from '@/api/companyFeatures/useCompanyFeaturesQuery';
import useUpdateCompanyFeaturesMutation from '@/api/companyFeatures/useUpdateCompanyFeature';
import useTenantCompanyEmailsQuery from '@/api/tenants/useTenantCompanyEmailsQuery';
import { tenantQueryKeys } from '@/api/tenants/queryKeys';
import usePermitTypesForTenantTypeQuery from '@/api/permit/usePermitTypesForTenantTypeQuery';
import useSetPermitTypesCompanyLimitsMutation from '@/api/permit/useSetPermitTypesCompanyLimitsMutation';
import usePermitTypesCompanyLimitsQuery from '@/api/permit/usePermitTypesCompanyLimitsQuery';
import useValidationTypesForTenantTypeQuery from '@/api/validationType/usetGetValidationTypesForTenantTypeQuery';
import useGetValidationTypesCompanyLimitsQuery from '@/api/validation/useGetValidationTypesCompanyLimitsQuery';
import useSetValidationTypeCompanyLimitsMutation from '@/api/validation/useSetValidationTypeCompanyLimitsMutation';
import useCountValidationsThisMonthQuery from '@/api/validation/useCountValidationsThisMonthQuery';

import DatePicker from '@/components/common/DatePicker';

import { handleError } from '@/utils/handleError';
import useDateModule from '@/utils/date/useDateModule';

import MinusIcon from '@/assets/icons/minus.inline.svg';
import PlusIcon from '@/assets/icons/plus.inline.svg';

import { PermitPolicy, ValidationPolicy } from '../types';

interface Props extends Omit<ModalProps, 'children'> {
  tenant?: Tenant | null;
  modalType?: string;
}

const AddUpdateUserModal: React.FC<Props> = ({
  tenant, onClose, modalType, ...props
}) => {
  return (
    <StyledModal
      title={modalType === 'update' ? 'Edit Tenant' : 'New Tenant'}
      fullScreenSize="580px"
      onClose={onClose}
      {...props}
    >
      <Content
        onFinished={onClose}
        tenant={tenant}
      />
    </StyledModal>
  );
};

type ContentProps = Pick<Props, 'tenant'> & {
  onFinished: () => void;
};

const emptyPermitPolicy: Partial<PermitPolicy> = {
  permitTypeId: undefined,
  maxPermits: undefined,
};

const emptyValidationPolicy: Partial<ValidationPolicy> = {
  validationTypeGroupId: undefined,
  monthlyLimit: undefined,
};

const Content: React.FC<ContentProps> = ({
  onFinished,
  tenant,
}) => {
  const { getTimestampOfTodayStart } = useDateModule();
  const queryClient = useQueryClient();

  const createTenantMutation = useCreateTenantMutation({
    onSuccess: () => {
      onFinished();
    },
    onError: (error) => handleError(error, 'Error while creating tenant!'),
  }); 

  const updateTenantCompanyMutation = useUpdateTenantCompanyMutation({
    onError: (error) => handleError(error, 'Error while updating tenant company!'),
  });
  
  const updateTenantUserMutation = useUpdateTenantUserMutation({
    onError: (error) => handleError(error, 'Error while updating tenant user!'),
  });
  
  const updateCompanyFeaturesMutation = useUpdateCompanyFeaturesMutation({
    onError: (error) => handleError(error, 'Error while updating tenant user!'),
  });

  const { 
    data: companyFeature,
    isLoading: isGetCompanyFeatureLoading, 
  } = useCompanyFeaturesQuery(tenant?.companyId!, {
    enabled: Boolean(tenant),
  });

  const types = useTenantTypeListQuery();
  
  const emails = useTenantCompanyEmailsQuery(tenant?.companyId!, {
    enabled: Boolean(tenant),
  });

  const permitTypesCompanyLimitsQuery = usePermitTypesCompanyLimitsQuery(tenant?.companyId!, {
    enabled: Boolean(tenant),
  });

  const setPermitTypesCompanyLimitMutation = useSetPermitTypesCompanyLimitsMutation();

  const getValidationTypeCompanyLimitsQuery = useGetValidationTypesCompanyLimitsQuery(tenant?.companyId!, {
    enabled: Boolean(tenant),
  });

  const setValidationTypeCompanyLimitsMutation = useSetValidationTypeCompanyLimitsMutation();

  const countValidationsThisMonthQuery = useCountValidationsThisMonthQuery(tenant?.companyId!, {
    enabled: Boolean(tenant),
  });

  const todayStartInGarageTimezone = React.useMemo(() => {
    return new Date(getTimestampOfTodayStart());
  }, [getTimestampOfTodayStart]);

  const formik = useFormik<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    tenantName: string;
    startDate: Date;
    type: string;
    permitCreation: boolean;
    permitPolicies: Array<PermitPolicy | Partial<PermitPolicy>>;
    validationCreation: boolean;
    validationPolicies: Array<ValidationPolicy | Partial<ValidationPolicy>>;
    event: boolean;
    useEmployeeValidation: boolean;
  }>({
    enableReinitialize: true,
    initialValues: {
      firstName: tenant?.tenantAdmin.firstName || '',
      lastName: tenant?.tenantAdmin.lastName || '',
      email: tenant?.tenantAdmin.email || '',
      phone: tenant?.tenantAdmin.phone || '',
      tenantName: tenant?.companyName || '',
      startDate: tenant ? new Date(tenant.leaseStartDate) : todayStartInGarageTimezone,
      type: tenant?.companyType || '',
      permitCreation: companyFeature ? companyFeature.canCreatePermits : false,
      permitPolicies: permitTypesCompanyLimitsQuery.data?.length
        ? permitTypesCompanyLimitsQuery.data.map(({ permitTypeId, maxPermits }) => ({ permitTypeId, maxPermits }))
        : [emptyPermitPolicy],
      validationCreation: companyFeature ? companyFeature.canValidateVisitors : false,
      validationPolicies: getValidationTypeCompanyLimitsQuery.data?.length
        ? getValidationTypeCompanyLimitsQuery.data.map((item) => ({ ...item }))
        : [emptyValidationPolicy],
      event: companyFeature ? companyFeature.canRequestEvents : false,
      useEmployeeValidation: companyFeature ? companyFeature.useEmployeeValidation : false,
    },
    onSubmit: async ({
      firstName,
      lastName,
      email,
      phone,
      type,
      tenantName,
      startDate,
      permitPolicies,
      permitCreation,
      validationCreation,
      validationPolicies,
      event,
      useEmployeeValidation,
    }) => {
      if (tenant) {
        try {
          const requests: Array<Promise<unknown>> = [];

          const compare = <T extends object>(field: keyof T, obj: T, obj2: T) => {
            return obj[field] === obj2[field];
          };
          const compareDeep = <T extends object>(field: keyof T, obj: T, obj2: T) => {
            return isEqual(obj[field], obj2[field]);
          };
          const isDirty = (field: keyof typeof formik.initialValues, deep?: boolean) => {
            if (deep) {
              return !compareDeep(field, formik.initialValues, formik.values);
            }

            return !compare(field, formik.initialValues, formik.values);
          };

          if (
            isDirty('firstName')
            || isDirty('lastName')
            || isDirty('phone')
          ) {
            const updateTenantUserRequest = updateTenantUserMutation.mutateAsync({
              userId: tenant.tenantAdmin.userId,
              firstName,
              lastName,
              phone,
            });
            requests.push(updateTenantUserRequest);
          }
  
          if (
            isDirty('tenantName')
            || isDirty('type')
            || isDirty('email')
          ) {
            const updateTenantCompanyRequest = updateTenantCompanyMutation.mutateAsync({
              companyId: tenant.companyId,
              name: tenantName,
              typeName: type,
              email,
            });
            requests.push(updateTenantCompanyRequest);
          }

          if (
            isDirty('permitCreation')
            || isDirty('event')
            || isDirty('validationCreation')
            || isDirty('useEmployeeValidation')
          ) {
            const updateCompanyFeaturesRequest = updateCompanyFeaturesMutation.mutateAsync({
              companyId: tenant.companyId,
              args:{
                canCreatePermits: permitCreation,
                canRequestEvents: event,
                canValidateVisitors: validationCreation,
                useEmployeeValidation,
              },
            });
            requests.push(updateCompanyFeaturesRequest);
          }

          if (isDirty('permitPolicies', true) || isDirty('permitCreation')) {
            const request = setPermitTypesCompanyLimitMutation.mutateAsync({
              companyId: tenant.companyId,
              limits: permitCreation ? permitPolicies as Array<PermitPolicy> : [],
            });
            requests.push(request);
          }

          if (isDirty('validationPolicies', true) || isDirty('validationCreation')) {
            const request = setValidationTypeCompanyLimitsMutation.mutateAsync({
              companyId: tenant.companyId,
              limits: validationCreation ? validationPolicies as Array<ValidationPolicy> : [],
            });
            requests.push(request);
          }

          await Promise.all(requests);
  
          queryClient.invalidateQueries(tenantQueryKeys.garageTenants());
  
          onFinished();
        } catch (error) {
          console.error(error);
        }
      } else {
        const createdTenant = await createTenantMutation.mutateAsync({
          firstName,
          lastName,
          email,
          phone,
          tenantName,
          typeName: type,
          leaseStartDate: startDate.getTime(),
          companyFeatures:{
            canCreatePermits: permitCreation,
            canValidateVisitors: validationCreation,
            canRequestEvents: event,
            useEmployeeValidation,
          },
        });

        if (permitCreation) {
          await setPermitTypesCompanyLimitMutation.mutateAsync({
            companyId: createdTenant.companyId,
            limits: permitPolicies as Array<PermitPolicy>,
          });
        }

        if (validationCreation) {
          await setValidationTypeCompanyLimitsMutation.mutateAsync({
            companyId: createdTenant.companyId,
            limits: validationPolicies as Array<ValidationPolicy>,
          });
        }
      }
    },
    validationSchema: yup.object().shape({
      tenantName: schemas.nameSchema.label('Tenant Name'),
      firstName: schemas.nameSchema.label('First Name'),
      lastName: schemas.nameSchema.label('Last Name'),
      email: schemas.emailSchema,
      phone: schemas.phoneSchema,
      type: yup.string()
        .label('Type')
        .required(),
      startDate: schemas.dateSchema.label('Start Date'),
      permitCreation: yup.boolean(),
      permitPolicies: yup
        .array()
        .when('permitCreation', {
          is: true,
          then: (schema) => schema.required()
            .of(
              yup.object().shape({
                permitTypeId: yup.string().required().label('Permit Name'),
                maxPermits: yup
                  .number()
                  .required()
                  .moreThan(0)
                  .test(
                    'permit-limit-max',
                    'Cannot exceed allowed permits',
                    (value, context) => {
                      const permitTypeId = context.parent.permitTypeId;

                      const currentLimit = permitTypesLimit.find((limit) => limit.permitTypeId == permitTypeId);

                      if (!currentLimit) return false;

                      return (value || 0) <= currentLimit.limitMax;
                    },
                  )
                  .test(
                    'permit-limit-min',
                    'Cannot be less than active permits count',
                    (value, context) => {
                      const permitTypeId = context.parent.permitTypeId;

                      const currentLimit = permitTypesLimit.find((limit) => limit.permitTypeId == permitTypeId);

                      if (!currentLimit) return false;

                      return (value || 0) >= currentLimit.limitMin;
                    },
                  )
                  .label('Allowed Permits'),
              }),
            ),
          otherwise: (schema) => schema.optional(),
        }),
      validationCreation: yup.boolean(),
      validationPolicies: yup
        .array()
        .when('validationCreation', {
          is: true,
          then: (schema) => schema.required()
            .of(
              yup.object().shape({
                validationTypeGroupId: yup.string().required().label('Validation Name'),
                monthlyLimit: yup
                  .number()
                  .required()
                  .moreThan(0)
                  .test(
                    'validation-limit-min',
                    'Cannot be less than the number of issued validations',
                    (value, context) => {
                      if (!tenant) return true;

                      const validationTypeGroupId = context.parent.validationTypeGroupId;

                      const currentLimit = validationTypesLimits.find(
                        (limit) => limit.validationTypeGroupId == validationTypeGroupId,
                      );
                      return (value ?? 0) >= (currentLimit?.minValue ?? 0);
                    },
                  )
                  .label('Allowed Validations'),
              }),
            ),
          otherwise: (schema) => schema.optional(),
        }),
      useEmployeeValidation: yup.boolean(),
    }),
  });

  useEffect(() => {
    if (permitTypesCompanyLimitsQuery.data && permitTypesCompanyLimitsQuery.data?.length > 0) {
      const value = permitTypesCompanyLimitsQuery.data
        .map(({ permitTypeId, maxPermits }) => ({ permitTypeId, maxPermits }));
      formik.setFieldValue('permitPolicies', value);
    }
  }, [permitTypesCompanyLimitsQuery.data]);

  useEffect(() => {
    if (getValidationTypeCompanyLimitsQuery.data && getValidationTypeCompanyLimitsQuery.data?.length > 0) {
      const value = getValidationTypeCompanyLimitsQuery.data
        .map(({ validationTypeGroupId, monthlyLimit }) => ({ validationTypeGroupId, monthlyLimit }));
      formik.setFieldValue('validationPolicies', value);
    }
  }, [getValidationTypeCompanyLimitsQuery.data]);

  useEffect(()=>{
    if (companyFeature) {
      formik.setFieldValue('validationCreation', companyFeature.canValidateVisitors);
      formik.setFieldValue('event', companyFeature.canRequestEvents);
      formik.setFieldValue('permitCreation', companyFeature.canCreatePermits);
      formik.setFieldValue('useEmployeeValidation', companyFeature.useEmployeeValidation);
    }
  }, [companyFeature]);

  const validationTypes = useValidationTypesForTenantTypeQuery(formik.values.type, {
    enabled: Boolean(formik.values.type),
  });

  useEffect(() => {
    if (!validationTypes.isFetching && formik.values.type) {
      validationTypes.refetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type]);

  const permitTypesQuery = usePermitTypesForTenantTypeQuery(formik.values.type, {
    enabled: Boolean(formik.values.type),
  });

  const permitTypesLimit = React.useMemo(() => {
    if (!permitTypesQuery.data) return [];

    return permitTypesQuery.data.map((item) => {
      const relatedCompanyLimit = permitTypesCompanyLimitsQuery.data?.find(
        (companyLimit) => item.permitType.id === companyLimit.permitTypeId,
      );

      const companyActivePermits = relatedCompanyLimit?.activePermits ?? 0;
      const companyMaxPermits = relatedCompanyLimit?.maxPermits ?? 0;

      return {
        permitTypeId: item.permitType.id,
        limitMax: Math.max(
          Math.min(companyMaxPermits + item.permitTypeFreeLimitSlots, item.permitType.maxPermits),
          companyActivePermits,
        ),
        limitMin: companyActivePermits,
      };
    });
  }, [permitTypesCompanyLimitsQuery.data, permitTypesQuery.data]);

  const validationTypeOptions = React.useMemo(() => {
    if (!validationTypes.data) return [];

    return validationTypes.data
      .map((item) => ({
        validationTypeGroupId: item.validationTypeGroupId,
        label: item.name,
      }));
  }, [validationTypes.data]);

  const permitTypeOptions = React.useMemo(() => {
    if (!permitTypesQuery.data) return [];

    return permitTypesQuery.data
      .map((item) => ({
        permitTypeId: item.permitType.id,
        label: item.permitType.name,
        isDisabled: formik.values.permitPolicies.some((pp) => pp.permitTypeId === item.permitType.id),
      }));
  }, [formik.values.permitPolicies, permitTypesQuery.data]);

  const validationTypesLimits = React.useMemo(() => {
    if (!countValidationsThisMonthQuery.data) return [];

    return countValidationsThisMonthQuery.data.map((item) => ({
      validationTypeGroupId: item.validationTypeGroupId,
      minValue: item.validationsCount,
    }));
  }, [countValidationsThisMonthQuery.data]);

  // active permits / all permits | per tenant company / at all ???

  const transformedInputChangeHandler = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      const fieldName = e.target.name;
      await formik.setFieldValue(fieldName, value);
      formik.setFieldTouched(fieldName, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.setFieldValue, formik.setFieldTouched],
  );

  const inputChangeHandler = React.useCallback(
    async (name: string, value: unknown) => {
      await formik.setFieldValue(name, value);
      formik.setFieldTouched(name, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.setFieldValue, formik.setFieldTouched],
  );

  const permitPoliciesDisabled = React.useMemo(() => {
    return !formik.values.permitCreation || isGetCompanyFeatureLoading;
  }, [formik.values.permitCreation, isGetCompanyFeatureLoading]);

  const validationPoliciesDisabled = React.useMemo(() => {
    return !formik.values.validationCreation || isGetCompanyFeatureLoading;
  }, [formik.values.validationCreation, isGetCompanyFeatureLoading]);

  return (
    <Wrapper data-test="new-tenant-modal">
      <Form onSubmit={formik.handleSubmit}>
        <SectionTitle>
          Tenant Information
        </SectionTitle>

        <Grid>
          <StyledInput
            id="tenantName"
            name="tenantName"
            label="Tenant Name"
            onChange={formik.handleChange}
            value={formik.values.tenantName}
            error={Boolean(formik.touched.tenantName && formik.errors.tenantName)}
            helperText={formik.touched.tenantName ? formik.errors.tenantName : undefined}
            columns={6}
          />
          <StyledSelect
            label="Tenant Type"
            columns={6}
            value={{
              value: capitalize(formik.values.type),
              label: capitalize(formik.values.type),
            }}
            // eslint-disable-next-line max-len
            options={types.data?.map(({ name }) => ({ value: capitalize(name), label: capitalize(name) })).filter(({ value }) => value !== 'Management')}
            onChange={(value) => {
              inputChangeHandler('type', value?.value);
            }}
            isLoading={types.isLoading}
            error={Boolean(formik.touched.type && formik.errors.type)}
            helperText={formik.errors.type}
            id="businessType"
          />
          <StyledDatePicker
            label="Start Date"
            pickerProps={{
              disabled: Boolean(tenant),
              selected: formik.values.startDate,
              minDate: todayStartInGarageTimezone,
              placeholderText: 'mm/dd/yyyy',
              onChange: async (selectedDate: Date) => {
                inputChangeHandler('startDate', selectedDate);
              },
            }}
            columns={6}
          />
        </Grid>

        <SectionTitle>
          Admin Information
        </SectionTitle>

        <Grid>
          {tenant ? (
            <StyledSelect
              options={emails.data?.map((email) => ({ value: email, label: email }))}
              onChange={async (option) => {
                inputChangeHandler('email', option?.value);
              }}
              value={{
                value: formik.values.email,
                label: formik.values.email,
              }}
              isLoading={emails.isLoading}
              label="Email"
              columns={6}
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.errors.email}
            />
          ) : (
            <StyledInput
              id="email"
              name="email"
              label="Email"
              onChange={transformedInputChangeHandler}
              value={formik.values.email}
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.errors.email}
              columns={6}
              transform="lowercase"
            />
          )}
          <StyledInputPhone
            id="phone"
            name="phone"
            label="Phone Number"
            onChangeValue={(newPhone) => {
              inputChangeHandler('phone', newPhone);
            }}
            value={formik.values.phone}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.errors.phone}
            columns={6}
          />
          <StyledInput
            id="firstName"
            name="firstName"
            label="First Name"
            onChange={transformedInputChangeHandler}
            value={formik.values.firstName}
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            helperText={formik.errors.firstName}
            columns={6}
            transform="capitalize"
          />
          <StyledInput
            id="lastName"
            name="lastName"
            label="Last Name"
            onChange={transformedInputChangeHandler}
            value={formik.values.lastName}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            helperText={formik.errors.lastName}
            columns={6}
            transform="capitalize"
          />
        </Grid>

        <SectionTitle>
          Parking Settings
        </SectionTitle>

        <div>
          <Fieldset>
            <Legend>Permit Creation</Legend>
            <PoliciesWrapper>
              <PermitCreationRow alignItems="center" justifyContent="flex-start" wrap="wrap" gap="10px">
                <CheckboxDescription>
                  Allow the tenant to create permits for their users
                </CheckboxDescription>
                {isGetCompanyFeatureLoading ? (
                  <Skeleton variant="rect" width={106} height={16} />
                ) : (
                  <CheckboxLabel>
                    <StyledCheckbox
                      name={'permitCreation'}
                      checked={formik.values.permitCreation}
                      disabled={isGetCompanyFeatureLoading}
                      onChange={(value, checked) => {
                        inputChangeHandler('permitCreation', checked);
                      }}
                    />
                    <CheckboxTitle>
                    {formik.values.permitCreation ? 'Allowed' : 'Not allowed'}
                    </CheckboxTitle>
                  </CheckboxLabel>
                )}
              </PermitCreationRow>
              <PolicyList>
                {formik.values.permitPolicies.map(({ permitTypeId, maxPermits }, idx) => {
                  const countInputError = Boolean(
                    get(formik.touched, `permitPolicies[${idx}].maxPermits`)
                    && get(formik.errors, `permitPolicies[${idx}].maxPermits`),
                  );

                  const nameField = `permitPolicies[${idx}].permitTypeId`;
                  const countField = `permitPolicies[${idx}].maxPermits`;

                  const currentLimit = permitTypesLimit.find((limit) => limit.permitTypeId === permitTypeId);

                  return (
                    <PolicyItem>
                      <PermitTypeSelect
                        id='permitTypeSelect'
                        options={permitTypeOptions}
                        value={permitTypeOptions.find((option) => option.permitTypeId === permitTypeId)}
                        getOptionValue={(option) => option.permitTypeId.toString()}
                        onChange={(option) => {
                          inputChangeHandler(nameField, option?.permitTypeId);
                        }}
                        onMenuClose={() => {
                          formik.setFieldTouched(nameField, true);
                        }}
                        isClearable
                        isDisabled={permitPoliciesDisabled}
                        error={
                          Boolean(
                            !permitPoliciesDisabled
                            && get(formik.touched, nameField)
                            && get(formik.errors, nameField),
                          )
                        }
                        helperText={get(formik.errors, nameField)}
                      />
                      <InputWrapper>
                        <Input
                          id='permitNumber'
                          value={permitPoliciesDisabled ? '' : maxPermits ?? ''}
                          onChange={async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            const valueNumber = Math.abs(parseInt(event.target.value, 10));
                            inputChangeHandler(countField, isNaN(valueNumber) ? undefined : valueNumber);
                          }}
                          disabled={permitPoliciesDisabled}
                          error={!permitPoliciesDisabled && countInputError}
                          helperText={get(formik.errors, countField)}
                        />
                        {!countInputError && currentLimit && !permitPoliciesDisabled && (
                          <StyledLabel>
                            Remaining issuable permits: {Math.max(currentLimit.limitMax - (maxPermits || 0), 0)}
                          </StyledLabel>
                        )}
                      </InputWrapper>
                      <ButtonsWrapper>
                        <ButtonIcon
                          iconProps={{
                            width: '20px',
                            fill: permitPoliciesDisabled ? vars.palette.secondary : vars.palette.primary,
                          }}
                          disabled={permitPoliciesDisabled}
                          Icon={PlusIcon}
                          onClick={() => {
                            inputChangeHandler(
                              'permitPolicies',
                              arrayUtils.push(formik.values.permitPolicies, emptyPermitPolicy),
                            );
                          }}
                          type="button"
                        />
                        {formik.values.permitPolicies.length > 1 && (
                          <ButtonIcon
                            iconProps={{
                              width: '20px',
                              fill: permitPoliciesDisabled ? vars.palette.secondary : vars.palette.danger,
                            }}
                            disabled={permitPoliciesDisabled}
                            Icon={MinusIcon}
                            onClick={() => {
                              inputChangeHandler(
                                'permitPolicies',
                                arrayUtils.removeAt(formik.values.permitPolicies, idx),
                              );
                            }}
                            type="button"
                          />
                        )}
                      </ButtonsWrapper>
                    </PolicyItem>
                  );
                })}
              </PolicyList>
            </PoliciesWrapper>
          </Fieldset>
          <Fieldset>
            <Legend>Validation</Legend>
            <ValidationWrapper>
              <ValidationCreationRow alignItems="center" justifyContent="flex-start" wrap="wrap" gap="10px">
                <CheckboxDescription>
                  Allow the tenant to validate their visitors
                </CheckboxDescription>
                {isGetCompanyFeatureLoading ? (
                  <Skeleton variant="rect" width={106} height={16} />
                ) : (<CheckboxLabel>
                  <StyledCheckbox
                    name="validationCreation"
                    checked={formik.values.validationCreation}
                    disabled={isGetCompanyFeatureLoading}
                    onChange={(value, checked) => {
                      if (!checked){
                        inputChangeHandler('useEmployeeValidation', false);
                      }
                      inputChangeHandler('validationCreation', checked);
                    }}
                  />
                  <CheckboxTitle>
                    {formik.values.validationCreation ? 'Allowed' : 'Not allowed'}
                  </CheckboxTitle>
                </CheckboxLabel>)}
              </ValidationCreationRow>
              <PolicyList>
                {formik.values.validationPolicies.map(({ validationTypeGroupId, monthlyLimit }, idx) => {
                  const countInputError = Boolean(
                    get(formik.touched, `validationPolicies[${idx}].monthlyLimit`)
                    && get(formik.errors, `validationPolicies[${idx}].monthlyLimit`),
                  );

                  const nameField = `validationPolicies[${idx}].validationTypeGroupId`;
                  const countField = `validationPolicies[${idx}].monthlyLimit`;

                  const currentLimit = validationTypesLimits.find(
                    (limit) => limit.validationTypeGroupId === validationTypeGroupId,
                  ) ?? {
                    validationTypeGroupId,
                    minValue: 0,
                  };

                  return (
                    <PolicyItem>
                      <ValidationTypeSelect
                        id="validationTypeSelect"
                        options={validationTypeOptions}
                        value={validationTypeOptions.find(
                          (option) => option.validationTypeGroupId === validationTypeGroupId,
                        )}
                        getOptionValue={(option) => option.validationTypeGroupId.toString()}
                        onChange={(option) => {
                          inputChangeHandler(nameField, option?.validationTypeGroupId);
                        }}
                        onMenuClose={() => {
                          formik.setFieldTouched(nameField, true);
                        }}
                        isClearable
                        isDisabled={validationPoliciesDisabled}
                        error={
                          Boolean(
                            !validationPoliciesDisabled
                            && get(formik.touched, nameField)
                            && get(formik.errors, nameField),
                          )
                        }
                        helperText={get(formik.errors, nameField)}
                      />
                      <InputWrapper>
                        <Input
                          id="validationNumber"
                          value={validationPoliciesDisabled ? '' : monthlyLimit ?? ''}
                          onChange={async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            const valueNumber = Math.abs(parseInt(event.target.value, 10));
                            inputChangeHandler(countField, isNaN(valueNumber) ? undefined : valueNumber);
                          }}
                          disabled={validationPoliciesDisabled}
                          error={!validationPoliciesDisabled && countInputError}
                          helperText={get(formik.errors, countField)}
                        />
                        {!countInputError && currentLimit && !validationPoliciesDisabled && (
                          <StyledLabel>
                            Validations issued this month: {Math.max(currentLimit.minValue, 0)}
                          </StyledLabel>
                        )}
                      </InputWrapper>
                    </PolicyItem>
                  );
                })}
              </PolicyList>
              <ValidationCreationRow alignItems="center" justifyContent="flex-start" wrap="wrap" gap="10px">
              <CheckboxDescription>
                Use employee codes for validation
              </CheckboxDescription>
              {isGetCompanyFeatureLoading ? (
                <Skeleton variant="rect" width={106} height={16} />
              ) : (<CheckboxLabel>
                <StyledCheckbox
                  name="useEmployeeValidation"
                  checked={formik.values.useEmployeeValidation && formik.values.validationCreation}
                  disabled={isGetCompanyFeatureLoading || !formik.values.validationCreation}
                  onChange={(value, checked) => {
                    inputChangeHandler('useEmployeeValidation', checked);
                  }}
                />
              </CheckboxLabel>)}
            </ValidationCreationRow>
               </ValidationWrapper>
          </Fieldset>
          <Fieldset>
            <Legend>Event</Legend>
            <Row alignItems="center" justifyContent="flex-start"   wrap="wrap" gap="10px">
              <CheckboxDescription>
                Allow the tenant to request to host events
              </CheckboxDescription>
              {isGetCompanyFeatureLoading ? (
                <Skeleton variant="rect" width={106} height={16} />
              ) : (<CheckboxLabel>
                <StyledCheckbox
                  name="event"
                  disabled={isGetCompanyFeatureLoading}
                  checked={formik.values.event}
                  onChange={(value, checked) => {
                    inputChangeHandler('event', checked);
                  }}
                />
                <CheckboxTitle>
                  {formik.values.event ? 'Allowed' : 'Not allowed'}
                </CheckboxTitle>
              </CheckboxLabel>)}
            </Row>
          </Fieldset>
        </div>

        <StyledRow justifyContent="flex-end">
          <StyledButton
            id="create-tenant-button"
            color="primary"
            variant="contained"
            type="submit"
            loading={formik.isSubmitting}
            disabled={!formik.dirty}
          >
            {tenant ? 'Update' : 'Create'}
          </StyledButton>
          <Button
            onClick={onFinished}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
        </StyledRow>
      </Form>
    </Wrapper>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  align-items: end;
  margin-bottom: 20px;
`;

const columnStyles = css<{ columns: number; }>`
  grid-column: span ${({ columns }) => columns};
  grid-row: span 1;

  @media (max-width: 520px) {
    grid-column: span 12;
  }
`;

const StyledInput = styled(Input)<{ columns: number; }>`
  ${columnStyles}
`;

const StyledInputPhone = styled(InputPhone)<{ columns: number; }>`
  ${columnStyles}
`;

const StyledSelect = styled(Select)`
  ${columnStyles}
` as typeof Select;

const StyledDatePicker = styled(DatePicker)`
  ${columnStyles}
`;

const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  width: 80px;
  margin-right: 10px;
`;

const StyledModal = styled(Modal)`
  /* @media (max-width: 1220px) {
    margin: auto 30px;
  } */

  @media (max-width: 800px) {
    width: 90%;
    margin: auto 30px;
  }

  @media (max-width: 580px) {
    width: auto;
    margin: auto;
  }
`;

const Wrapper = styled.div``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled(Row)`
  margin-top: auto;
`;

const Fieldset = styled.fieldset`
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  border: none;
`;

const Legend = styled.legend`
  padding: 0;
  margin-bottom: 15px;
  font-weight: normal;
`;

const PermitCreationRow = styled(Row)`
  padding: 10px 0;
`;

const ValidationCreationRow = styled(Row)`
  padding: 10px 0;
`;

const CheckboxDescription = styled.div`
  min-width: 310px;

  @media (max-width: 420px) {
    min-width: unset;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

const CheckboxTitle = styled.span`
  width: 80px;
`;

const PoliciesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: start;

  @media (max-width: 980px) {
    grid-template-columns: 1fr;
  }
`;

const ValidationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: start;

  @media (max-width: 980px) {
    grid-template-columns: 1fr;
  }
`;

const PolicyList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 30px;

  list-style: none;
  margin: 0;
  padding: 0;
`;

const PolicyItem = styled.li`
  display: flex;
  gap: 10px;
`;

const PermitTypeSelect = styled(Select)`
  flex-shrink: 0;
  min-width: 175px;
` as typeof Select;

const ValidationTypeSelect = styled(Select)`
  flex-shrink: 0;
  min-width: 175px;
` as typeof Select;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledLabel = styled.div`
  position: absolute;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  color: #C4C4C4;
  white-space: nowrap;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 56px;
  flex-shrink: 0;
`;

export default AddUpdateUserModal;
