import React, { useState } from 'react';
import styled from 'styled-components';
import assertNever from 'assert-never';
import flow from 'lodash/flow';

import {
  CategoryCard,
  Row,
  Table,
  RowsMap,
  ButtonIcon,
  Button,
  TableCard,
  TableContent,
  Modal,
} from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import vars from '@zero5/ui/lib/styles/vars';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useGetGarageQuery from '@/api/garage/useGetGarageQuery';
import { PermitGarage, PermitStatus } from '@/api/permit/models';
import useGetPermitsQuery from '@/api/permit/useGetPermitsQuery';
import useGetPermitsInUseCountQuery from '@/api/permit/useGetPermitsInUseCountQuery';
import useGetPermitsRevenueQuery from '@/api/permit/useGetPermitsRevenueQuery';

import Role, { useFindCurrentAction } from '@/components/common/Role';
import PermitAddModal from '@/components/modals/PermitAddModal';
import Page from '@/components/common/page';
import TableHead from '@/components/common/TableHead';
import PermitInformation from '@/components/modals/PermitInformation';
import Status from '@/components/common/Status';
import PermitTrendChart from '@/components/PermitTrendChart';
import PermitUsageTrendChart from '@/components/PermitUsageTrendChart';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import useDateModule from '@/utils/date/useDateModule';
import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

import InfoIcon from '@/assets/icons/dots.inline.svg';

interface Props {}

export const permitStatusToView = (status: PermitStatus) => {
  switch (status) {
    case 'ACTIVE':
      return {
        color: vars.palette.primary,
        text: 'Active',
      };

    case 'PAYMENT_PROCESSING':
      return {
        color: vars.palette.secondary,
        text: 'Payment processing',
      };

    case 'PAYMENT_FAILED':
      return {
        color: vars.palette.danger,
        text: 'Payment failed',
      };

    case 'EXPIRED':
      return {
        color: vars.palette.danger,
        text: 'Expired',
      };

    case 'DEACTIVATED':
      return {
        color: '#EF5164',
        text: 'Inactive',
      };

    default:
      assertNever(status);
  }
};

enum PermitColumnId {
  LICENSE_PLATE = 'licensePlate',
  NAME = 'name',
  VALID_DATE = 'permit.startTime',
  STATUS = 'status',
  TYPE = 'permit.type',
  AUTO_RENEW = 'permit.autoRenew',
  PRICE = 'permit.price',
  CONTACT = 'contact',
  INFO = 'info',
}

const Permit: React.FC<Props> = () => {

  const { formatTimeStampToString, getMonthAndYear } = useDateModule();

  const [isAddModalOpen, closeAddModal, openAddModal] = useToggle(false);
  const [isInfoModalOpen, closeInfoModal, openInfoModal] = useToggle(false);
  const findCurrentAction = useFindCurrentAction();

  const [permitsPage, setPermitsPage] = React.useState(0);
  const [permitsPerPage, setPermitsPerPage] = React.useState(10);

  const [selectedPermit, setSelectedPermit] = useState<PermitGarage>();

  const permitRowsMap = React.useMemo(() => {
    const permits: RowsMap<PermitGarage> = [
      {
        title: 'Name',
        id: PermitColumnId.NAME,
        data: ({ driver }) => `${driver?.firstName} ${driver?.lastName}`,
        getSortComparator: ({ driver }) => `${driver?.firstName} ${driver?.lastName}`,
      },
      {
        title: 'License Plate',
        id: PermitColumnId.LICENSE_PLATE,
        data: ({ vehicle }) => vehicle.lpNum,
        getSortComparator:  ({ vehicle }) => vehicle.lpNum,
      },
      {
        title: 'Valid Date',
        id: PermitColumnId.VALID_DATE,
        data: ({ permit: { startTime, endTime } }) => (
          `${formatTimeStampToString(startTime, 'MM/dd/yyyy')} - ${formatTimeStampToString(endTime, 'MM/dd/yyyy')}`
        ),
        getSortComparator: ({ permit: { startTime, endTime } }) => `${startTime} ${endTime}`,
      },
      {
        title: 'Permit Status',
        id: PermitColumnId.STATUS,
        data: ({ permit: { status } }) => {
          const view = permitStatusToView(status);
          return (
            <Status color={view.color}>
              {view.text}
            </Status>
          );
        },
        getSortComparator: ({ permit: { status } }) => status,
      },
      {
        title: 'Permit Type',
        id: PermitColumnId.TYPE,
        data: ({ permit: { type } }) => type.name,
        getSortComparator: ({ permit: { type } }) => type.name,
      },
    ];
    if (findCurrentAction('permit:updatePermit') === 'r' || findCurrentAction('permit:updatePermit') === 'w') {
      permits.push({
        title: 'Info',
        id: PermitColumnId.INFO,
        disableSorting: true,
        data: (permit) => (
          <StyledButtonIcon
            data-test="info-btn"
            Icon={InfoIcon}
            onClick={() => {
              setSelectedPermit(permit);
              openInfoModal();
            }}
          />
        ),
      });
    }
    return permits;
  }, [findCurrentAction, formatTimeStampToString, openInfoModal]);

  const {
    data: permitsData,
    isLoading: permitsLoading,
    isRefetching: permitsRefetching,
  } = useGetPermitsQuery({
    take: permitsPerPage,
    skip: permitsPerPage * permitsPage,
  });

  const { data: permitsInUseCountData } = useGetPermitsInUseCountQuery();
  const { data: permitsRevenue } = useGetPermitsRevenueQuery();

  const { data: garageData } = useGetGarageQuery({
    enabled: findCurrentAction('permit:stats') !== 'h' || findCurrentAction('permit:trends') !== 'h',
  });

  const cardsData = [
    {
      title: 'Total Permit Revenue',
      // changes: 0.0,
      data: formatPrice(permitsRevenue || 0),
      subtext: getMonthAndYear(new Date()),
      widgetId: 'permit:stats:totalPermitRevenue',
    },
    {
      title: 'Permits in Use',
      data: permitsInUseCountData || emptyFieldStub,
      subtext: `/${garageData?.permitLimit || emptyFieldStub}`,
      widgetId: 'permit:stats:permitsInUse',
    },
  ];

  return (
    <Page>
      <Role widgetId="permit:stats" action="r">
        <CardsWrapper>
          {cardsData.map(({ widgetId, ...props }) => (
              <Role widgetId={widgetId} action="r">
                <CategoryCard key={props.title} {...props} />
              </Role>
          ))}
        </CardsWrapper>
      </Role>
      <Role widgetId="permit:trends" action="r">
        <ChartsWrapper>
          <PermitTrendChart />
          <PermitUsageTrendChart />
        </ChartsWrapper>
      </Role>
      <Role widgetId="permit" action="w">
        <ButtonRow justifyContent="flex-end">
          <Button
              variant="contained"
              color="primary"
              onClick={openAddModal}
              data-test="create-permit-modal-btn"
          >
            Create Permit
          </Button>
        </ButtonRow>
      </Role>
      <Role widgetId="permit" action="r">
        <TableCard>
          <TableHead
            title="Permit List"
            // setDateRange={dateRangeChangeHandler}
            // dateRange={dateRange}
          />
          <TableContent
            isLoading={permitsLoading}
            data={permitsData?.permits}
          >
            <StyledTable
              rowsMap={permitRowsMap}
              page={permitsPage}
              count={permitsData?.totalCount}
              onPageChange={(permit, page) => {
                setPermitsPage(page);
              }}
              onRowsPerPageChange={(e) => {
                setPermitsPerPage(Number(e.target.value));
                setPermitsPage(0);
              }}
              data={permitsData?.permits!}
              isLoading={permitsRefetching}
              data-test="permit-list"
              // minWidth="800px"
            />
          </TableContent>
        </TableCard>
      </Role>
      <Role widgetId="permit" action="w">
        <PermitAddModal open={isAddModalOpen} onClose={closeAddModal} />
      </Role>
      <Role widgetId="permit" action="r">
        <StyledModal title="Permit Information" open={isInfoModalOpen} onClose={closeInfoModal} fullScreenSize="550px">
          <PermitInformation
            permit={selectedPermit!}
            garage={permitsData?.garage!}
            onClose={closeInfoModal}
          />
        </StyledModal>
      </Role>
    </Page>
  );
};

const StyledModal = styled(Modal)`
  margin: 30px auto;

  @media (max-width: 550px) {
    margin: auto;
  }
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 30px / 2));
  grid-gap: 30px;
  margin-bottom: 35px;
  min-height: 400px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }

  @media (max-width: 520px) {
    min-height: 300px;
  }  
`;

const StyledTable = styled(Table)`
  margin-top: 10px;
  & .MuiTableCell-root {
    padding-bottom: 12px;
  }
  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

` as typeof Table;

const StyledButtonIcon = styled(ButtonIcon)`
  margin: 0 auto;
  height: auto;
  line-height: 22px;
`;

const ButtonRow = styled(Row)`
  margin-bottom: 35px;
`;

export default flow(
  withGarage,
  withRole('permit'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(Permit);
