import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import flow from 'lodash/flow';
import styled from 'styled-components';

import Page from '@/components/common/page';
import ValidationForEmployees from '@/components/Validation/ValidationForEmployees';
import ValidationForCustomers from '@/components/Validation/ValidationForCustomers';

import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

enum ValidationTabs {
  EMPLOYEES,
  CUSTOMERS,
}

interface Props {}

const Validation: React.FC<Props> = ({}) => {
  const [tab, setTab] = React.useState<ValidationTabs>(ValidationTabs.EMPLOYEES);

  const changeTabHandler = React.useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  }, []);

  return (
    <Page>
      <StyledTabs onChange={changeTabHandler} value={tab} indicatorColor="primary" textColor="primary">
        <Tab label="for Employees" />
        {/* <Tab label="for Customers" /> */}
      </StyledTabs>
      {tab === ValidationTabs.EMPLOYEES && <ValidationForEmployees />}
      {tab === ValidationTabs.CUSTOMERS && <ValidationForCustomers />}
    </Page>
  );
};

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;
`;

export default flow( 
  withGarage,
  withRole('validation'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(Validation);