import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { createTenantCompany, CreateTenantCompanyRequest } from '@zero5/user-api';

import { queryGroupName as permitQueryGroupName } from '../permit/queryGroupName';
import { queryKey as remainingPermitsQueryKey } from '../permit/useGetRemainingPermitsQuery';

import { mapTenant } from './mappings';
import { Tenant } from './models';
import { tenantQueryKeys } from './queryKeys';

type Response = Tenant;


const useCreateTenantMutation = (
  options?: UseMutationOptions<Response, Error, CreateTenantCompanyRequest, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'createTenant',
    async (params) => {
      const response = await createTenantCompany(params);

      return mapTenant(response);
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(tenantQueryKeys.garageTenants());
        queryClient.refetchQueries([permitQueryGroupName, remainingPermitsQueryKey]);

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useCreateTenantMutation;
