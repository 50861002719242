import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { setWatchlistSettings } from '@zero5/garage-api';

import { handleError } from '@/utils/handleError';

import { watchlistQueryKeys } from './queryKeys';
import { GateBehavior } from './models';
import { unmapGateBehavior } from './mappings';

type Response = unknown;
type Params = {
  gateBehavior: GateBehavior;
};

const useSetWatchlistSettingsMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params) => {
      const response = await setWatchlistSettings({
        gateBehavior: unmapGateBehavior(params.gateBehavior),
      });

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(watchlistQueryKeys.watchlistSettings());
        options?.onSuccess?.(...params);
      },
      onError: (...params) => {
        const [error] = params;
        handleError(error, 'Error while updating watchlist vehicle.');
        options?.onError?.(...params);
      },
    },
  );
};

export default useSetWatchlistSettingsMutation;
