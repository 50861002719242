import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Input, LoadingIndicator } from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';
import { ValidationTypePricingType } from '@zero5/garage-api';

import createValidationReceiptMutation from '@/api/validation/createValidationReceiptMutation';
import useUserCompanyQuery from '@/api/queries/useUserCompanyQuery';

import { regexEmail } from '@/utils/regex';
import useDateModule from '@/utils/date/useDateModule';

import HourSelector from './HoursSelector';
import { VehicleInfo } from './ValidationContent';
import ValidationSuccess from './ValidationSuccess';

interface Props{
  searchInput: string;
  vehicle: VehicleInfo;
  handleSearchAgainClick: () => void;
  setHoursHandler: (hours: number) => void;
  hours: null | number;
  handleGoBackClick: () => void;
}

const ValidationInputResultContent: React.FC<Props> = ({
  searchInput, vehicle, hours, setHoursHandler, handleSearchAgainClick,
  handleGoBackClick,
}) => {
  const { formatTimeStampToString, hoursToMilliseconds } = useDateModule();
  const isNotEmail = !searchInput.includes('@');
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState<boolean>(false);

  const { mutateAsync: createValidationReceipt, isLoading, isSuccess } = createValidationReceiptMutation();
  const { data: company, isFetching: isCompanyLoading } = useUserCompanyQuery();

  const handleValidateClick = () => {
    createValidationReceipt({
      email: email || searchInput,
      workTime: hoursToMilliseconds(hours || 0),
      licensePlate: searchInput.includes('@') ? undefined : searchInput,
    });
  };

  const isHourFee = useMemo(() => {
    return company?.pricingType === ValidationTypePricingType.HOURLY_RATE;
  }, [company]);

  useEffect(() => {    
    if (company && !isHourFee && !isNotEmail){      
      handleValidateClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHourFee, isNotEmail]);

  if (isLoading || isCompanyLoading) {
    return (<LoadingIndicator />);
  }

  if (isSuccess) {
    return (<ValidationSuccess searchInput={searchInput} handleGoBackClick={handleGoBackClick} />);
  }

  const handleSetEmailHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isEmailValidHandler(event.target.value)) {
      setEmail(event.target.value);
    }
  };

  const isEmailValidHandler = (testEmail: string) => {
    const regexp = new RegExp(regexEmail);
    const isValid = regexp.test(testEmail);
    setShowEmailError(!isValid);
    return isValid;
  };


  return (
    <>
      {isNotEmail ? (
        <>
          <Text size="25px">
            Search Result for
            {' '}
            <span style={{ fontWeight: 600 }}>{searchInput}</span>
          </Text>
          <VehicleInformation>
            <VehicleImage src={vehicle!.photo === '-' ? vehicle?.image : vehicle!.photo} />
            <Grid>
              <div style={{ fontWeight: 600, color: 'black' }}>License Plate</div>
              <div>{vehicle!.lp}</div>
              <div style={{ fontWeight: 600, color: 'black' }}>Entry Time</div>
              <div>{formatTimeStampToString(vehicle!.entryTime, 'MM/dd/yyyy HH:mm:ss')}</div>
            </Grid>
          </VehicleInformation>
          <Text style={{ fontWeight: 400, margin: '45px auto 25px' }}>
            If the information above is correct, please choose options below and click validate.
          </Text>
          <ValidationInput
            errorBorderColor={showEmailError}
            error={showEmailError}
            onChange={handleSetEmailHandler}
            helperText="Email must be valid"
            placeholder="Email"
          />
          <ValidationSelectRow>
            {isHourFee && <HourSelector setHours={setHoursHandler} />}
            <ValidateButton
              variant="contained"
              color="primary"
              onClick={handleValidateClick}
              disabled={isHourFee ? (!hours || email === '' || showEmailError) : email === '' || showEmailError}
            >
              Validate
            </ValidateButton>
          </ValidationSelectRow>
        </>
      ) : (
        <>
          <Text style={{ fontWeight: 400, margin: '45px auto 25px' }}>
            How many hours would you like to validate?
          </Text>
          <ValidationSelectRow>
            <HourSelector setHours={setHoursHandler} />
            <ValidateButton
              variant="contained"
              color="primary"
              onClick={handleValidateClick}
              disabled={!hours}
            >
              Validate
            </ValidateButton>
          </ValidationSelectRow>
        </>
      )}
      {isNotEmail && (
      <StyledLink onClick={handleSearchAgainClick}>
        This is not the car
      </StyledLink>
      )}
    </>
  );
};

const ValidationInput = styled(Input)<{ errorBorderColor: boolean; }>`
input {
  ${({ errorBorderColor }) => !errorBorderColor && 'border:none;'};;
  background: #F8F9FA;
  width: 335px;
  height:50px;
  text-align: center;
}
 ::placeholder {
    color: #ACB3BF;
  }
  margin-bottom: 35px;
`;

const Text = styled.div<{ size?: string; weight?: string; }>`
  font-weight: ${({ weight }) => weight || 300};
  font-size: ${({ size }) => size || '16px'};
  line-height: 1.375;
  color: #181818;
  margin: 33px auto 30px;
`;

const VehicleInformation = styled.div`
  width: 100%;
  max-width: 870px;
  display: flex;
  padding: 33px 25px 31px;
  align-items: center;
  justify-content: center;
  gap: 55px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  @media (max-width: 800px) {
    flex-direction: column;
  };
`;

const VehicleImage = styled.img`
  width: 400px;
  height: 220px;
  @media (max-width: 500px){
    width: 300px;
  height: 150px;
  }
  @media (max-width: 350px){
    width: 200px;
  height: 120px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  gap: 10px 10px;
`;

const ValidationSelectRow = styled.div`
  display: flex;
  gap: 25px;
  @media (max-width:350px){
    flex-direction: column;
  }
`;

const StyledLink = styled.a`
  margin-top: 38px;
  color: ${vars.palette.primary};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ValidateButton = styled(Button)`
  min-width:150px;
  height:50px;
`;

export default ValidationInputResultContent;
