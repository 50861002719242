import React from 'react';
import styled from 'styled-components';
import saveAs from 'file-saver';

import {
  RowsMap,
  TableCard,
  TableContent,
  Button,
  TableHead,
  InfoButton,
  Modal,
} from '@zero5/ui';
import Table, { Order } from '@zero5/ui/lib/Table';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import { RevenueReport as RevenueReportType, TransactionsAggregationPeriod } from '@/api/revenue/models';
import useRevenueReportsQuery from '@/api/revenue/useRevenueReportsQuery';
import useAggregatedTransactionsDetailsReportCsvMutation
  from '@/api/revenue/useAggregatedTransactionsDetailsReportCsvMutation';
import useTransactionsDetailsReportCsvMutation from '@/api/revenue/useTransactionsDetailsReportCsvMutation';

import useDateModule from '@/utils/date/useDateModule';
import { emptyFieldStub } from '@/utils/emptyFieldStub';

import { useFindCurrentAction } from '../common/Role';
import Price from '../common/Price';

import RevenueReport from './modals/RevenueReport';
import { PendingAmount } from './components/PendingAmount';

interface Props {}

const Reports: React.FC<Props> = ({}) => {
  const findCurrentAction = useFindCurrentAction();

  const {
    getStartOfMonth,
    getEndOfMonth,
    formatTimeStampToString,
    getMonthAndYear,
  } = useDateModule();

  const [isInfoModalOpen, closeInfoModal, openInfoModal] = useToggle(false);
  const [openedReport, setOpenedReport] = React.useState<RevenueReportType | null>(null);

  const [selectedReport, setSelectedReport] = React.useState<RevenueReportType>();

  const [revenueReportsPage, setRevenueReportsPage] = React.useState(0);
  const [revenueReportsRowsPerPage, setRevenueReportsRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>();
  const [orderBy, setOrderBy] = React.useState<string>();

  const aggregatedTransactionsDetailsCsvMutation = useAggregatedTransactionsDetailsReportCsvMutation();
  const transactionsDetailsCsvMutation = useTransactionsDetailsReportCsvMutation();

  const onInfoModalOpen = React.useCallback((record: RevenueReportType) => {
    setOpenedReport(record);
    openInfoModal();
  }, [openInfoModal]);

  const exportReport = React.useCallback(async (report: RevenueReportType, period: TransactionsAggregationPeriod) => {
    const date = new Date(report.date);

    const start = getStartOfMonth(date);
    const end = getEndOfMonth(date);

    let reportLocal: string;

    if (period === 'all') {
      reportLocal = await transactionsDetailsCsvMutation.mutateAsync({
        from: Number(start),
        until: Number(end),
      });
    } else {
      reportLocal = await aggregatedTransactionsDetailsCsvMutation.mutateAsync({
        from: Number(start),
        until: Number(end),
        aggregationPeriod: period,
      });
    }

    const dateRangeString = `${
      formatTimeStampToString(start, 'MM/dd/yyyy')
    }-${
      formatTimeStampToString(end, 'MM/dd/yyyy')
    }`;

    const fileName = `TransactionsDetailsReport_${dateRangeString}_${period}.csv`;
    const file = new File([reportLocal], fileName, { type: 'text/plain;charset=utf-8' });
    saveAs(file);
  }, [
    aggregatedTransactionsDetailsCsvMutation,
    getEndOfMonth,
    getStartOfMonth,
    transactionsDetailsCsvMutation,
    formatTimeStampToString,
  ]);

  const exportCurrentReport = React.useCallback((period: TransactionsAggregationPeriod) => {
    exportReport(openedReport!, period);
  }, [exportReport, openedReport]);

  const exportSelectedReports = React.useCallback(async () => {
    exportReport(selectedReport!, 'all');
  }, [exportReport, selectedReport]);

  const revenueReportsRowsMap = React.useMemo(() => {
    const rowsMap: RowsMap<RevenueReportType> = [
      {
        title: 'Date',
        id: 'date',
        data: ({ date }) => getMonthAndYear(date),
      },
      {
        title: 'Transient',
        id: 'transient',
        data: ({ transient }) => formatPrice(transient),
      },
      {
        title: 'Permit',
        id: 'permit',
        data: ({ permit }) => formatPrice(permit),
      },
      {
        title: 'Validation',
        id: 'validation',
        data: ({ validation }) => formatPrice(validation),
      },
      {
        title: 'Event',
        id: 'event',
        data: ({ event }) => formatPrice(event),
      },
      {
        title: 'Disputes & Refunds',
        id: 'dispute',
        data: ({ dispute }) => <Price>{dispute}</Price>,
        disableSorting: true,
      },
      {
        title: 'Pending',
        id: 'pending',
        data: ({ pending }) => pending ? <PendingAmount>{formatPrice(pending)}</PendingAmount> : emptyFieldStub,
        disableSorting: true,
      },
      {
        title: 'Total Amount',
        id: 'total',
        data: ({ total }) => {
          return <TotalPrice>{formatPrice(total)}</TotalPrice>;
        },
      },
    ];

    if (findCurrentAction('revenue:reports:report') !== 'h') {
      rowsMap.push({
        title: 'Details',
        id: 'Details',
        disableSorting: true,
        data: (record) => (
          <InfoButton onClick={() => onInfoModalOpen(record)} />
        ),
      });
    }
    return rowsMap;
  }, [findCurrentAction, getMonthAndYear, onInfoModalOpen]);

  const {
    data: revenueReportsData,
    isLoading: revenueReportsLoading,
    isFetching: revenueReportsFetching,
  } = useRevenueReportsQuery({
    page: revenueReportsPage + 1,
    take: revenueReportsRowsPerPage,
    orderBy: orderBy as 'event' | 'date' | 'permit' | 'transient' | 'validation' | 'total' | undefined,
    direction: order,
  });

  return (
    <>
      <TableCard data-test="revenue-reports-table">
        <TableHead
          title="Revenue Reports"
          extraContent={(
            <Button
              variant="contained"
              color="primary"
              onClick={exportSelectedReports}
              disabled={!selectedReport}
              loading={
                aggregatedTransactionsDetailsCsvMutation.isLoading
                || transactionsDetailsCsvMutation.isLoading
              }
              data-test="revenue-reports-table-export-btn"
            >
              export
            </Button>
          )}
        />
        <TableContent
          isLoading={revenueReportsLoading}
          data={revenueReportsData?.reports || []}
        >
          <StyledTable
            rowsMap={revenueReportsRowsMap}
            data={revenueReportsData?.reports!}
            controls="radio"
            onItemSelect={(selected) => setSelectedReport(selected)}
            selected={selectedReport}
            radioClearSelected
            getSelectComparator={(item) => item?.date}
            page={revenueReportsPage}
            onPageChange={(e, page) => setRevenueReportsPage(page)}
            onRowsPerPageChange={(e) => setRevenueReportsRowsPerPage(Number(e.target.value))}
            count={revenueReportsData?.pagesCount}
            onSortChange={(orderByLocal, orderLocal) => {
              setOrder(orderLocal);
              setOrderBy(orderByLocal);
            }}
            isLoading={revenueReportsFetching}
            data-test="revenue-reports-table-content"
          />
        </TableContent>
      </TableCard>
      <ReportModal
        open={isInfoModalOpen}
        onClose={closeInfoModal}
        fullScreenSize="550px"
      >
        <RevenueReport
          revenueReport={openedReport!}
          onClose={closeInfoModal}
          onExport={exportCurrentReport}
          isExporting={
            aggregatedTransactionsDetailsCsvMutation.isLoading
            || transactionsDetailsCsvMutation.isLoading
          }
        />
      </ReportModal>
    </>
  );
};

const TotalPrice = styled.div`
  font-weight: 700;
`;

const StyledTable = styled(Table)`
  margin-top: 10px;
  white-space: nowrap;

  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
` as typeof Table;

const ReportModal = styled(Modal)`
  max-width: 1280px;
  width: 100%;
`;

export default Reports;
