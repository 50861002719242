import React from 'react';
import styled from 'styled-components';

import { LoadingIndicator } from '@zero5/ui';

export const withLoading = (
  fallback: React.ReactNode = <StyledLoadingIndicator />,
) => <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (props: P) => {
    return (
      <React.Suspense fallback={fallback}>
        <Component {...props} />
      </React.Suspense>
    );
  };

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
`;
