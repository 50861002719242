import React from 'react';
import flow from 'lodash/flow';
import { Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';

import Page from '@/components/common/page';
import WatchlistContent from '@/components/watchlist/WatchlistContent';
import Role from '@/components/common/Role';
import SettingsContent from '@/components/watchlist/SettingsContent';

import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

interface Props {}

enum WatchlistTabs {
  WATCHLIST,
  SETTINGS,
}

const Watchlist: React.FC<Props> = () => {
  const [tab, setTab] = React.useState<WatchlistTabs>(0);

  const changeTabHandler = React.useCallback((event: unknown, newValue: number) => {
    setTab(newValue);
  }, []);

  return (
    <Page>
      <StyledTabs
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={changeTabHandler}
      >
        <Role widgetId="watchlist:watchlist" action="r">
          <Tab label="Watchlist" />
        </Role>
        <Role widgetId="watchlist:settings" action="r">
          <Tab label="Settings" />
        </Role>
      </StyledTabs>
      {tab === WatchlistTabs.WATCHLIST && (
        <WatchlistContent />
      )}
      {tab === WatchlistTabs.SETTINGS && (
        <SettingsContent />
      )}
    </Page>
  );
};

const StyledTabs = styled(Tabs)`
  margin-bottom: 30px;
`;

export default flow(
  withGarage,
  withRole('watchlist'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(Watchlist);
