import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
  Button, Buttons, DataCard,
  Image, Label,
  Modal, ModalProps, Row,
  SimpleRowsMap, SimpleTable,
} from '@zero5/ui';
import { getGarageName, getZone } from '@zero5/ui/lib/utils/formatters/formatGate';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import { formatDurationShort } from '@zero5/ui/lib/utils/formatters/formatDuration';
import { ActivityType } from '@zero5/activity-api';

import { Activity } from '@/api/models/activities';
import useGetFullActivityInfo from '@/api/queries/useGetFullActivityInfo';
import useGetEntryActivityInfo from '@/api/queries/useGetEntryActivityInfo';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import useDateModule from '@/utils/date/useDateModule';
interface Props extends Omit<ModalProps, 'children'> {
  activity: Activity;
}

const ActivityInformationModal: React.FC<Props> = ({ onClose, activity, ...props }) => {
  return (
    <Modal title="Activity Information" fullScreenSize="550px" onClose={onClose} {...props}>
      <Content onClose={onClose} activity={activity} />
    </Modal>
  );
};

type ContentProps = Pick<Props, 'activity' | 'onClose'>;

const Content: React.FC<ContentProps> = ({
  activity,
  onClose,
}) => {
  const [imageType, setImageType] = React.useState(activity.frontImg ? 'front' : 'rear');

  const { formatTimeStampToString } = useDateModule();

  const { data: fullActivity, isLoading } = useGetFullActivityInfo(
    activity.activityId, 
    {
      enabled: activity.type !== ActivityType.IN,
    });

  const { 
    data: entryActivityData,
    isLoading: isEntryActivityLoading } = useGetEntryActivityInfo(
    activity.activityId,
    {
      enabled: activity.type === ActivityType.IN,
    },
  );

  const entryActivity = fullActivity 
    ? fullActivity.entryActivity 
    : entryActivityData?.entryActivity || activity;
  const exitActivity = fullActivity?.exitActivity;
  const paymentInfo = fullActivity?.paymentInfo;

  const vehicleInfoRowsMap = useMemo<SimpleRowsMap<Activity>>(() => [
    {
      title: 'License Plate',
      data: ({ mainLp }) => mainLp,
    },
    // {
    //   title: 'Vehicle Make',
    //   data: ({ vehicle: { make } }) => make,
    // },
    // {
    //   title: 'Vehicle Type',
    //   data: ({ vehicle: { type } }) => type,
    // },
    // {
    //   title: 'Vehicle Color',
    //   data: ({ vehicle: { color } }) => color,
    // },
  ], []);

  const parkingInfoRowsMap = useMemo<SimpleRowsMap<Activity>>(() => [
    {
      title: 'Entry Time',
      data: () => entryActivity
        ? formatTimeStampToString(entryActivity.eventTime, 'MM/dd/yyyy HH:mm:ss')
        : emptyFieldStub,
    },
    {
      title: 'Entry Location',
      data: () => entryActivity
        ? `Layout ${getZone(entryActivity.deviceId)}`
        : emptyFieldStub,
    },
    {
      title: 'Entry Gate',
      data: () => entryActivity
        ? `${getGarageName(entryActivity.deviceId).replace(/IN_|OUT_/, '')}`
        : emptyFieldStub,
    },  
    {
      title: 'Exit Time',
      data: () => exitActivity
        ? formatTimeStampToString(exitActivity.eventTime, 'MM/dd/yyyy HH:mm:ss')
        : emptyFieldStub,
    },
    {
      title: 'Exit Location',
      data: () => exitActivity
        ? `Layout ${getZone(exitActivity.deviceId)}`
        : emptyFieldStub,
    },
    {
      title: 'Exit Gate',
      data: () => exitActivity
        ? `${getGarageName(exitActivity.deviceId).replace(/IN_|OUT_/, '')}`
        : emptyFieldStub,
    },

  ], [entryActivity, exitActivity, formatTimeStampToString]);

  const paymentInfoRowsMap = React.useMemo<SimpleRowsMap<Activity>>(() => [
    {
      title: 'Payment Method',
      data: () => paymentInfo?.paymentMethod || emptyFieldStub,
    },
    {
      title: 'Duration',
      data: () => paymentInfo?.duration
        ? formatDurationShort(paymentInfo.duration)
        : emptyFieldStub,
    },
    {
      title: 'Fee',
      data: () => paymentInfo?.fee
        ? formatPrice(paymentInfo?.fee)
        : emptyFieldStub,
    },
    {
      title: 'Note',
      data: () => paymentInfo?.note || emptyFieldStub,
    },
  ], [paymentInfo]);

  const isActivityLoading = useMemo(() => isLoading || isEntryActivityLoading, 
    [isLoading, isEntryActivityLoading]);

  return (
    <>
      <Wrapper data-test="activity-info-modal">
        <ImageWrapper>
          <Buttons
            onBack={() => setImageType('front')}
            onForward={() => setImageType('rear')}
            disableBack={imageType === 'front' || !activity.frontImg}
            disableForward={imageType === 'rear' || !activity.rearImg}
          >
            <Label text={imageType === 'front' ? 'Front' : 'Rear'}>
              <StyledImage 
                data-test="car-image"
                width="400px" 
                height="220px" 
                src={imageType === 'front' 
                  ? activity.frontImg || ''
                  : activity.rearImg || ''
                } 
              />
            </Label>
          </Buttons>
        </ImageWrapper>
        <VehicleInformationWrapper data-test="vehicle-info">
          <StyledDataCard label="Vehicle Info">
            <StyledSimpleTable
              rowsMap={vehicleInfoRowsMap}
              item={activity}
              align="left"
              isLoading={isActivityLoading}
              variant="text"
              width={120}
              height={18}
            />
          </StyledDataCard>
          <StyledDataCard label="Parking Info" data-test="parking-info">
            <StyledSimpleTable
              rowsMap={parkingInfoRowsMap}
              item={activity}
              align="left"
              isLoading={isActivityLoading}
              variant="text"
              width={120}
              height={18}
            />
          </StyledDataCard>
          <StyledDataCard label="Payment Info" data-test="payment-info">
            <StyledSimpleTable
              rowsMap={paymentInfoRowsMap}
              item={activity}
              align="left"
              isLoading={isActivityLoading}
              variant="text"
              width={120}
              height={18}
            />
          </StyledDataCard>
        </VehicleInformationWrapper>
        {/* )} */}
      </Wrapper>
      <ButtonRow justifyContent="flex-end">
        <Button data-test="close-btn" variant="contained" color="primary" onClick={onClose}>Close</Button>
      </ButtonRow>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 50px;
  align-items: center;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: min-content;
  margin: 0 auto;

  @media (max-width: 460px) {
    width: 100%;
  }
`;

const StyledImage = styled(Image)`
  @media (max-width: 460px) {
    width: 100%;
  }
`;

const StyledSimpleTable = styled(SimpleTable)`
  & tr > th {
    width: 120px;
  }
  margin-bottom:10px;
` as typeof SimpleTable;

const VehicleInformationWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 15px;
`;

const StyledDataCard = styled(DataCard)`
  th {
    width: 100px;
  }

  @media (max-width: 460px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonRow = styled(Row)`
  margin-top: 30px;
`;

export default ActivityInformationModal;
