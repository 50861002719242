import React from 'react';
import styled from 'styled-components';

import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import { formatNumber } from '@zero5/ui/lib/utils/formatters/formatNumber';

import { AccountValidationSummaryItem } from '@/api/validationHistory/models';

type Props = {
  data: Array<AccountValidationSummaryItem>;
};

const SummaryTable: React.FC<Props> = ({ data }) => {
  return (
    <StyledTable data-test="modal-revenue-table">
      <thead>
        <th aria-label="Table head" />
        <th>No. Of Validation</th>
        <th>Unit Price</th>
        <th>Total Amount</th>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr>
            <td>{item.validationTypeName}</td>
            <td>{formatNumber(item.validationCount)}</td>
            <td>{formatPrice(item.unitPrice)}</td>
            <td>{formatPrice(item.totalAmount)}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;
  min-width: 660px;
  white-space: nowrap;

  & > thead {
    position: relative;
    & > th {
      padding: 0 10px 23px 10px;
      text-align: right;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background: black;
      bottom: 0;
      left: 0;
    }
  }
  & td:first-child {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
  }
  & td {
    padding: 5px 10px;
    text-align: right;
  }
  & tr:not(:last-child) > td {
    padding-bottom: 14px;
  }
`;

export default SummaryTable;