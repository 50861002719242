import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deleteWatchlistVehicle } from '@zero5/garage-api';

import { handleError } from '@/utils/handleError';

import { watchlistQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  watchlistVehicleId: number;
};

const useDeleteWatchlistVehicleMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params) => {
      const response = await deleteWatchlistVehicle(params.watchlistVehicleId);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(watchlistQueryKeys.watchlistVehicleLists());
        queryClient.invalidateQueries(watchlistQueryKeys.watchlistVehicles());
        options?.onSuccess?.(...params);
      },
      onError: (...params) => {
        const [error] = params;
        handleError(error, 'Error while deleting watchlist vehicle.');
        options?.onError?.(...params);
      },
    },
  );
};

export default useDeleteWatchlistVehicleMutation;
