import React, { Suspense } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Input,
  LoadingIndicator,
  Row,
  Select,
} from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';
import states from '@zero5/ui/lib/utils/states';

import useWatchlistVehicleQuery from '@/api/watchlist/useWatchlistVehicleQuery';
import useCreateWatchlistVehicleMutation from '@/api/watchlist/useCreateWatchlistVehicleMutation';
import useUpdateWatchlistVehicleMutation from '@/api/watchlist/useUpdateWatchlistVehicleMutation';

import ModalTitle from '@/components/common/ModalTitle';
import ModalTitleButton from '@/components/common/ModalTitleButton';

import SideImage from '@/assets/images/roadworks.png';

type DataProps = {
  mode: 'add';
  id?: never;
} | {
  mode: 'info';
  id: number;
} | {
  mode: 'edit';
  id: number;
};

type CommonProps = {
  onCancel: () => void;
};

type Props = CommonProps & DataProps;

const WatchlistLoader: React.FC<Props> = ({ ...props }) => {
  return (
    <Suspense
      fallback={(
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
    >
      <WatchlistModal {...props} />
    </Suspense>
  );
};

const LoadingWrapper = styled.div`
  width: min(1140px, 100vw);
  max-width: 100%;
  min-height: 510px;
`;

const validationSchema = Yup.object().shape({
  lpNum: Yup.string().required().label('License Plate'),
  state: Yup.string(),
  vehicleMake: Yup.string(),
  vehicleModel: Yup.string(),
  vehicleColor: Yup.string(),
  reason: Yup.string().required().label('Reason'),
  note: Yup.string(),
});

const reasons = [
  {
    value: 'Constant tailgating',
    label: 'Constant tailgating',
  },
  {
    value: 'Outstanding balance',
    label: 'Outstanding balance',
  },
  {
    value: 'Public disturbance',
    label: 'Public disturbance',
  },
  {
    value: 'Others',
    label: 'Others',
  },
];

const WatchlistModal: React.FC<Props> = (props) => {
  const {
    id,
    onCancel,
  } = props;

  const [mode, setMode] = React.useState(props.mode);

  const watchlistItemQuery = useWatchlistVehicleQuery({
    watchlistVehicleId: id!,
  }, {
    enabled: mode !== 'add',
    suspense: true,
  });

  const createWatchlistVehicleMutation = useCreateWatchlistVehicleMutation({
    onSuccess: () => {
      onCancel();
    },
  });
  const updateWatchlistVehicleMutation = useUpdateWatchlistVehicleMutation({
    onSuccess: () => {
      onCancel();
    },
  });

  const formik = useFormik<{
    lpNum: string;
    state: string;
    vehicleMake: string;
    vehicleModel: string;
    vehicleColor: string;
    reason: string;
    note: string;
  }>({
    initialValues: mode === 'add' ? {
      lpNum: '',
      state: '',
      vehicleMake: '',
      vehicleModel: '',
      vehicleColor: '',
      reason: '',
      note: '',
    } : {
      lpNum: watchlistItemQuery.data!.vehicle.lpNum,
      state: watchlistItemQuery.data!.vehicle.state,
      vehicleMake: watchlistItemQuery.data!.vehicle.make,
      vehicleModel: watchlistItemQuery.data!.vehicle.model,
      vehicleColor: watchlistItemQuery.data!.vehicle.color,
      reason: watchlistItemQuery.data!.reason,
      note: watchlistItemQuery.data!.note,
    },
    onSubmit: async (values) => {
      if (mode === 'add') {
        await createWatchlistVehicleMutation.mutateAsync({
          lpNum: values.lpNum,
          lpState: values.state,
          make: values.vehicleMake,
          model: values.vehicleModel,
          color: values.vehicleColor,
          reason: values.reason,
          note: values.note,
        });
      }
      if (mode === 'edit') {
        await updateWatchlistVehicleMutation.mutateAsync({
          watchlistVehicleId: id!,
          lpNum: values.lpNum,
          lpState: values.state,
          make: values.vehicleMake,
          model: values.vehicleModel,
          color: values.vehicleColor,
          reason: values.reason,
          note: values.note,
        });
      }
    },
    validationSchema,
  });

  const transformedInputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formik.setFieldValue(e.target.name, value);
    }, [formik],
  );

  return (
    <>
      {mode === 'add' && <ModalTitle>Add Vehicle</ModalTitle>}
      {mode === 'info' && (
        <ModalTitleButton
          onClick={() => setMode('edit')}
        >
          Vehicle Information
        </ModalTitleButton>
      )}
      {mode === 'edit' && <ModalTitle>Edit Vehicle Information</ModalTitle>}
      <Wrapper>
        <ImageWrapper>
          <StyledImage src={SideImage} />
        </ImageWrapper>
        <Form onSubmit={formik.handleSubmit}>
          <SectionTitle>Event Information</SectionTitle>
          <Grid>
            <GridItem columns={6}>
              <Input
                label="License Plate"
                name="lpNum"
                value={formik.values.lpNum}
                onChange={transformedInputChangeHandler}
                error={Boolean(formik.touched.lpNum && formik.errors.lpNum)}
                helperText={formik.errors.lpNum}
                transform="uppercase"
                required
                disabled={mode === 'info'}
              />
            </GridItem>
            <GridItem columns={6}>
              <Select
                label="Registered State"
                options={states}
                getOptionLabel={(options) => options.abbreviation}
                getOptionValue={(options) => options.abbreviation}
                value={states.find((state) => state.abbreviation === formik.values.state)}
                onChange={(option) => formik.setFieldValue('state', option?.abbreviation)}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.errors.state}
                isDisabled={mode === 'info'}
              />
            </GridItem>
            <GridItem columns={4}>
              <Input
                label="Vehicle Make"
                name="vehicleMake"
                value={formik.values.vehicleMake}
                onChange={transformedInputChangeHandler}
                error={Boolean(formik.touched.vehicleMake && formik.errors.vehicleMake)}
                helperText={formik.errors.vehicleMake}
                transform="uppercase"
                disabled={mode === 'info'}
              />
            </GridItem>
            <GridItem columns={4}>
              <Input
                label="Vehicle Model"
                name="vehicleModel"
                value={formik.values.vehicleModel}
                onChange={transformedInputChangeHandler}
                error={Boolean(formik.touched.vehicleModel && formik.errors.vehicleModel)}
                helperText={formik.errors.vehicleModel}
                transform="uppercase"
                disabled={mode === 'info'}
              />
            </GridItem>
            <GridItem columns={4}>
              <Input
                label="Vehicle Color"
                name="vehicleColor"
                value={formik.values.vehicleColor}
                onChange={transformedInputChangeHandler}
                error={Boolean(formik.touched.vehicleColor && formik.errors.vehicleColor)}
                helperText={formik.errors.vehicleColor}
                transform="capitalize"
                disabled={mode === 'info'}
              />
            </GridItem>
            <GridItem columns={12}>
              <Select
                label="Reason"
                options={reasons}
                value={reasons.find((state) => state.value === formik.values.reason) || {
                  value: formik.values.reason,
                  label: formik.values.reason,
                }}
                onChange={(option) => formik.setFieldValue('reason', option?.value)}
                error={formik.touched.reason && Boolean(formik.errors.reason)}
                helperText={formik.errors.reason}
                required
                isDisabled={mode === 'info'}
              />
            </GridItem>
            <GridItem columns={12}>
              <Input
                multiline
                rows={3}
                maxRows={5}
                label="Note"
                name="note"
                placeholder="Add more detailed information here"
                value={formik.values.note}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.note && formik.errors.note)}
                helperText={formik.errors.note}
                disabled={mode === 'info'}
              />
            </GridItem>
          </Grid>
          <ButtonsRow justifyContent="flex-end" gap="20px">
            <Button variant={mode === 'info' ? 'contained' : 'text'} color="primary" onClick={onCancel}>
              {mode === 'info' ? 'Close' : 'Cancel'}
            </Button>
            {mode !== 'info' && (
              <StyledButton
                loading={formik.isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting || !formik.dirty}
              >
                {mode === 'add' ? 'Add' : 'Update'}
              </StyledButton>
            )}
          </ButtonsRow>
        </Form>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 380px) minmax(420px, 700px);

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 250px 1fr;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 1px solid ${vars.palette.border};

  @media (max-width: 800px) {
    padding-left: 0;
    padding-top: 20px;
    border-left: none;
    border-top: 1px solid ${vars.palette.border};
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
`;

const GridItem = styled.div<{ columns: number; }>`
  grid-column: span ${({ columns }) => columns};
  grid-row: span 1;

  @media (max-width: 520px) {
    grid-column: span 12;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
  min-width:80px;
`;

const ButtonsRow = styled(Row)`
  margin-top: auto;
`;

export default WatchlistLoader;
