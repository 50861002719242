import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { TableCellProps } from '@material-ui/core';

import {
  Button,
  Row,
  RowsMap,
  Skeleton,
  Table,
  TableContent,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
// import vars from '@zero5/ui/lib/styles/vars';

import { ForecastedRevenueDetail, RevenueForecastedReport } from '@/api/revenue/models';
import useForecastedRevenueSummaryQuery from '@/api/revenue/useForecastedRevenueSummaryQuery';
import useForecastedRevenueDetailsQuery from '@/api/revenue/useForecastedRevenueDetailsQuery';

import { selectCurrentGarage } from '@/store/selectors/workspace';


import Price from '@/components/common/Price';

import useDateModule from '@/utils/date/useDateModule';
import { emptyFieldStub } from '@/utils/emptyFieldStub';

import ExportButton from '../../common/ExportButton';
import {
  BigText,
  GarageNameRow,
  MediumLevelTitle,
  RowWithOffset,
  SummarySection,
  TableHiddenScroll,
  TopLevelTitle,
  TransactionsTable,
} from '../components/styled';
import SummaryTable from '../components/SummaryTable';
import { mapTransactionTypeToTitle } from '../utils';
import Service from '../components/Service';

interface Props {
  revenueReport: RevenueForecastedReport;
  onClose: () => void;
  onExport: () => void;
  isExporting?: boolean;
}

const commonCellProps: TableCellProps = {
  align: 'left',
  style: {
    padding: '11.5px 30px 12.5px',
  },
};
const ForecastedReport: React.FC<Props> = ({
  revenueReport,
  onClose,
  onExport,
  isExporting,
}) => {
  const {
    formatTimeStampToString,
    getStartOfDay,
    getCurrentTimeOrEndOfDay,
    formatFullDate,
  } = useDateModule();

  const garage = useSelector(selectCurrentGarage);

  const [forecastedRevenueDetailsPage, setForecastedRevenueDetailsPage] = React.useState(0);
  const [forecastedRevenueDetailsRowsPerPage, setForecastedRevenueDetailsRowsPerPage] = React.useState(10);

  const dateRange: DateRange = useMemo(() => {
    const date = new Date(revenueReport.date);

    const start = getStartOfDay(date);
    const end = getCurrentTimeOrEndOfDay(date);

    return {
      start,
      end,
    };
  }, [getStartOfDay, getCurrentTimeOrEndOfDay]);

  const {
    data: forecastedRevenueSummaryData,
    isLoading: forecastedRevenueSummaryLoading,
  } = useForecastedRevenueSummaryQuery({
    from: Number(dateRange.start),
    until: Number(dateRange.end),
  });
  const {
    data: forecastedRevenueDetailsData,
    isLoading: forecastedRevenueDetailsLoading,
    isFetching: forecastedRevenueDetailsFetching,
  } = useForecastedRevenueDetailsQuery({
    from: Number(dateRange.start),
    until: Number(dateRange.end),
    page: forecastedRevenueDetailsPage + 1,
    take: forecastedRevenueDetailsRowsPerPage,
  });

  const revenueDetailsRowsMap = React.useMemo(() => {
    const details: RowsMap<ForecastedRevenueDetail> = [
      {
        title: 'Date',
        id: 'date',
        data: ({ date }) => formatFullDate(date),
        disableSorting: true,
        HeadCellProps: {
          width: 194,
          ...commonCellProps,
        },
        CellProps: {
          ...commonCellProps,
        },
      },
      {
        title: 'Type',
        id: 'service',
        data: ({ service }) => <Service service={service} />,
        disableSorting: true,
        HeadCellProps: {
          width: 100,
          ...commonCellProps,
        },
        CellProps: {
          ...commonCellProps,
        },
      },
      {
        title: 'Payment Method',
        id: 'paymentMethod',
        data: ({ paymentMethod }) => mapTransactionTypeToTitle[paymentMethod],
        disableSorting: true,
        HeadCellProps: {
          width: 169,
          ...commonCellProps,
        },
        CellProps: {
          ...commonCellProps,
        },
      },
      {
        title: 'Note',
        id: 'note',
        data: ({ note }) => note || emptyFieldStub,
        disableSorting: true,
        HeadCellProps: {
          width: 260,
          ...commonCellProps,
        },
        CellProps: {
          ...commonCellProps,
        },
      },
      {
        title: 'Amount',
        id: 'amount',
        data: ({ amount }) => <Price>{amount}</Price>,
        disableSorting: true,
        HeadCellProps: {
          width: 116,
          align: 'right',
          style: {
            padding: '11.5px 33px 12.5px 30px',
          },
        },
        CellProps: {
          align: 'right',
          style: {
            padding: '11.5px 33px 12.5px 30px',
          },
        },
      },
      {
        title: 'Total Amount',
        id: 'total',
        data: ({ total }) => formatPrice(total || 0).replace('$', '$ '),
        disableSorting: true,
        HeadCellProps: {
          width: 165,
          align: 'right',
          style: {
            padding: '11.5px 47px 12.5px 30px',
          },
        },
        CellProps: {
          align: 'right',
          style: {
            padding: '11.5px 47px 12.5px 30px',
          },
        },
      },
    ];

    return details;
  }, [formatFullDate]);

  return (
    <>
      <RowWithOffset>
        <TopLevelTitle>Forecasted Revenue</TopLevelTitle>
        <ExportButton onClick={onExport} isExporting={isExporting} data-test="forecasted-report-modal-export-btn"/>
      </RowWithOffset>

      <GarageNameRow>
        <BigText>{garage?.label}</BigText>
        <TopLevelTitle>
          {formatTimeStampToString(+dateRange.start, 'MM/dd/yyyy')}
        </TopLevelTitle>
      </GarageNameRow>

      {forecastedRevenueSummaryLoading ? (
        <TransactionsSkeleton width="100%" height="310px" variant="rect" />
      ) : (
        <StyledSummarySection data-test="forecasted-report-modal-summary">
          <TitleTextRow>
            <MediumLevelTitle>Revenue Summary</MediumLevelTitle>
            <ItalicMediumLevelTitle>
              * Tenant permits and event parking fees will be charged on the last day of the month
            </ItalicMediumLevelTitle>
          </TitleTextRow>
          <RowWithOffset>
            <BigText>
              {forecastedRevenueSummaryLoading
                ? <Skeleton width="150px" />
                : formatPrice(forecastedRevenueSummaryData?.total || 0)
              }
            </BigText>
          </RowWithOffset>
          <TableHiddenScroll>
            <SummaryTable
              report={forecastedRevenueSummaryData}
              forecasted
            />
          </TableHiddenScroll>
        </StyledSummarySection>
      )}

      <Row>
        <TopLevelTitle>Details</TopLevelTitle>
      </Row>

      <StyledTableContent
        isLoading={forecastedRevenueDetailsLoading}
        data={forecastedRevenueDetailsData?.reports}
      >
        <StyledTransactionsTable
          rowsMap={revenueDetailsRowsMap}
          data={forecastedRevenueDetailsData?.reports!}
          page={forecastedRevenueDetailsPage}
          onPageChange={(e, page) => setForecastedRevenueDetailsPage(page)}
          onRowsPerPageChange={(e) => setForecastedRevenueDetailsRowsPerPage(Number(e.target.value))}
          count={forecastedRevenueDetailsData?.entriesCount!}
          isLoading={forecastedRevenueDetailsFetching}
          minWidth="1008px"
          data-test="forecasted-report-modal-details"
        />
      </StyledTableContent>

      <ButtonRow justifyContent="flex-end" >
        <Button data-test="modal-close-btn" variant="contained" color="primary" onClick={onClose} >Close</Button>
      </ButtonRow>
    </>
  );
};

const TitleTextRow = styled(RowWithOffset)`
  gap: 20px;
  flex-wrap: wrap;
`;

const ButtonRow = styled(Row)`
  margin-top: 30px;
`;

const ItalicMediumLevelTitle = styled(MediumLevelTitle)`
`;

const StyledSummarySection = styled(SummarySection)`
  margin-bottom: 30px;
`;

const StyledTableContent = styled(TableContent)`
  min-height: 200px;
`;

const StyledTransactionsTable = styled(TransactionsTable)`
  margin-top: 10px;
` as typeof Table;

const TransactionsSkeleton = styled(Skeleton)`
  margin-bottom: 30px;
`;

export default ForecastedReport;
