import React from 'react';

import vars from '@zero5/ui/lib/styles/vars';

import Status from '@/components/common/Status';

interface Props {
  status: string;
}

const eventStatusToView: Record<string, {
  color: string;
  text: string;
}> = {
  CONFIRMED: {
    color: '#30cd9a',
    text: 'Approved',
  },  
  APPROVED_ADMIN: {
    text: 'Updated',
    color: '#FF9F1C',
  },
  REQUESTED: {
    color: vars.palette.danger,
    text: 'Pending',
  },
  REJECTED_ADMIN: {
    color: vars.palette.danger,
    text: 'Rejected Admin',
  },
  REJECTED_TENANT: {
    color: vars.palette.danger,
    text: 'Rejected Tenant',
  },
};

const EventStatus: React.FC<Props> = ({ status }) => {
  return (
    <Status color={eventStatusToView[status].color}>
      {eventStatusToView[status].text}
    </Status>
  );
};

export default EventStatus;