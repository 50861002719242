import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { createWatchlistVehicle } from '@zero5/garage-api';

import { handleError } from '@/utils/handleError';

import { watchlistQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  lpNum: string;
  lpState?: string;
  make?: string;
  model?: string;
  color?: string;
  reason: string;
  note?: string;
};

const useCreateWatchlistVehicleMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params) => {
      const response = await createWatchlistVehicle(params);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(watchlistQueryKeys.watchlistVehicleLists());
        queryClient.invalidateQueries(watchlistQueryKeys.watchlistVehicles());
        options?.onSuccess?.(...params);
      },
      onError: (...params) => {
        const [error] = params;
        handleError(error, 'Error while creating watchlist vehicle.');
        options?.onError?.(...params);
      },
    },
  );
};

export default useCreateWatchlistVehicleMutation;
