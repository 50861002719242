import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationTable } from '@zero5/garage-api';

import { validationHistoryQueryKeys } from './queryKeys';
import { mapAllValidations } from './mappings';

type Response = ReturnType<typeof mapAllValidations>;
type Key = ReturnType<typeof validationHistoryQueryKeys.allValidations>;
type Params = {
  sortBy?: 'timestamp' | 'note' | 'type' | 'tenantName' | 'userName' | 'amount';
  order?: 'ASC' | 'DESC';
  skip?: number;
  take?: number;
};

const useAllValidationsQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    validationHistoryQueryKeys.allValidations(params),
    async () => {
      const response = await getValidationTable(params);

      return mapAllValidations(response);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useAllValidationsQuery;
