import { useQuery, UseQueryOptions } from 'react-query';

import { getCompany } from '@zero5/user-api';

import { mapCompany } from '../permit/mappings';

import { userQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapCompany>;
type Key = ReturnType<typeof userQueryKeys.company>;
type Params = {
  companyId: number;
};

const useGetCompanyQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    userQueryKeys.company(params.companyId),
    async () => {
      const response = await getCompany(params.companyId);

      return mapCompany(response.company);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useGetCompanyQuery;
