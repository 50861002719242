import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/order
import { TableCellProps } from '@material-ui/core';
import {
  Button, ButtonIcon, ConfirmationModal, Row, RowsMap, Table, TableContent,
} from '@zero5/ui';
// import Role, { useFindCurrentAction } from '@/components/common/Role';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useValidationTypeListQuery from '@/api/validationType/useGetValidationTypesQuery';
import { ValidationType } from '@/api/models/ValidationType';
import useDeleteValidationTypeMutation from '@/api/validationType/deleteValidationTypeMutation';

import Role from '@/components/common/Role';

import DeleteIcon from '@/assets/icons/delete.inline.svg';
import InfoIcon from '@/assets/icons/dots.inline.svg';

import Title from './common/Title';
import ValidationPolicyModal from './ValidationPolicyModal';

interface Props {}


const Validation: React.FC<Props> = () => {
  const [modalType, setModalType] = React.useState<'info' | 'delete' | 'create'>();
  const [modalData, setModalData] = React.useState<ValidationType>();
  const { data:validationTypeList, isFetching: isValidationTypeListLoading } = useValidationTypeListQuery();
  const { mutateAsync:deleteValidationType, isLoading: isDeleteLoading  } = useDeleteValidationTypeMutation();
  // const findCurrentAction = useFindCurrentAction();

  const closeModalHandler = React.useCallback(() => {
    setModalType(undefined);
  }, []);

  const deleteValidationTypeHandler = (id?: number)=> async ()=>{
    if (id){
      setModalType(undefined);
      await deleteValidationType(id);
    }
  };

  const commonCellProps: TableCellProps = {
    align: 'left',
  };

  const rowsMap: RowsMap<ValidationType> = React.useMemo(() => [
    {
      title: 'Name',
      id: 'name',
      HeadCellProps: { width: 200, ...commonCellProps },
      CellProps: { width: 200, ...commonCellProps },
      data: ({ name }) => name,
    },
    {
      title: 'Pricing Type',
      id: 'pricingType',
      HeadCellProps: { width: 150, ...commonCellProps },
      CellProps: { width: 150, ...commonCellProps },
      data: ({ pricingType }) => pricingType,
    },
    {
      title: 'Price',
      id: 'price',
      HeadCellProps: { width: 200, ...commonCellProps },
      CellProps: { width: 200, ...commonCellProps },
      data: ({ price }) => formatPrice(price),
    },
    {
      title: 'Info',
      id: 'info',
      data: (item) => (
        <StyledButtonIcon
          Icon={InfoIcon}
          onClick={() => {
            setModalData(item);
            setModalType('info');
          }}
        />
      ),
      disableSorting: true,
      HeadCellProps: {
        width: '25px',
      },
    },
    {
      title: 'Delete',
      id: 'delete',
      data: (item) => (
        <StyledButtonIcon
          Icon={DeleteIcon}
          onClick={() => {
            setModalData(item);
            setModalType('delete');
          }}
        />
      ),
      disableSorting: true,
      HeadCellProps: {
        width: '41px',
      },
    },
  ], []);

  return (
    <>
      <MainTitleRow>
        <MainTitle>Validation List</MainTitle>
        <Role widgetId="parkingPolicy:createValidation" action="w">
          <Button
            color="primary"
            variant="contained"
            onClick={() => setModalType('create')}
          >
            Create Validation
          </Button>
        </Role>

      </MainTitleRow>
      <TableContent data={validationTypeList || []} isLoading={isValidationTypeListLoading || isDeleteLoading}>
        <Table
          rowsMap={rowsMap}
          data={validationTypeList || []}
        />
      </TableContent>
      <Role widgetId="parkingPolicy:validationPolicy" action="r">
         <ValidationPolicyModal
          open={modalType === 'info' || modalType === 'create'}
          title={modalType === 'info' ? 'Validation Type Information' : 'Create New Validation Type'}
          data={modalType === 'info' ? modalData : undefined}
          onClose={closeModalHandler}
        /> 
      </Role>
      <ConfirmationModal
        open={modalType === 'delete'}
        onAccept={deleteValidationTypeHandler(modalData?.validationTypeId)}
        onReject={closeModalHandler}
        text="Are you sure you want to delete"
        acceptText="Delete"
        variableText={modalData?.name}
        text2=" validation?"
      />
    </>
  );
};

const MainTitleRow = styled(Row)`
  margin-bottom: 20px;
`;

const MainTitle = styled(Title)`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  margin: auto;
`;

export default Validation;
