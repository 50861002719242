import React from 'react';
import styled from 'styled-components';

import {
  Row,
  Skeleton,
} from '@zero5/ui';

import useGetCompanyQuery from '@/api/user/useGetCompanyQuery';
import { TenantTypeName } from '@/api/permit/models';

import useDateModule from '@/utils/date/useDateModule';

import AccountType from '../../components/AccountType';

interface Props {
  companyId: number;
  fromDate: number;
}

const AccountNameSection: React.FC<Props> = ({ companyId, fromDate }) => {
  const {
    formatMonthYear,
  } = useDateModule();

  const companyQuery = useGetCompanyQuery({ companyId });

  return (
    <AccountNameRow>
      <Row gap="10px">
        <BigText>
          {companyQuery.isLoading ? (
            <Skeleton width="100px" variant="text" />
          ) : (
            companyQuery.data?.name
          )}
        </BigText>
        {companyQuery.isLoading ? (
          <Skeleton width="100px" height="20px" variant="rect" />
        ) : (
          <AccountType type={companyQuery.data?.typeName! as TenantTypeName} />
        )}
      </Row>
      <DateLabel>
        {formatMonthYear(fromDate)}
      </DateLabel>
    </AccountNameRow>
  );
};

const AccountNameRow = styled(Row)`
  margin-bottom: 20px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const BigText = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
`;

const DateLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

export default AccountNameSection;