import { createActionCreators } from 'immer-reducer';

import { ActivitiesReducer } from '@/store/reducers/activities';

export const activitiesActions = createActionCreators(ActivitiesReducer);

export type ActivitiesActions =
  | ReturnType<typeof activitiesActions.addNewActivitiesToQueue>
  | ReturnType<typeof activitiesActions.clearNewActivitiesQueue>
  | ReturnType<typeof activitiesActions.addIssueActivitiesToQueue>
  | ReturnType<typeof activitiesActions.clearIssueActivitiesQueue>;
