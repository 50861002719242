import {
  User as UserResponse,
  CompanyUser as CompanyUserResponse,
  ListCompanyUsersResponse,
  Role as RoleResponse,
  GetUserGarageRoleResponse,
  GetUserCompanyResponse,
} from '@zero5/user-api';
import { Response as SelfUserResponse } from '@zero5/auth-api/lib/src/functions/getSelfSystemUser/types';

import { emptyFieldStub } from '@/utils/emptyFieldStub';

import {
  CompanyUser, Role, Type, User, UserCompany,
} from '../models/users';

export interface TypeResponse{
  name: string;
}

export const mapUser = (userResponse: UserResponse): User => ({
  id: userResponse.userId,
  firstName: userResponse.firstName || emptyFieldStub,
  lastName: userResponse.lastName || emptyFieldStub,
  phone: userResponse.phone || '',
  email: userResponse.email || emptyFieldStub,
  role: emptyFieldStub,
  status: userResponse.isActive ? 'active' : 'deactivated',
});

export const mapRole = (roleResponse: RoleResponse): Role => ({
  id: roleResponse.roleId,
  label: roleResponse.roleLabel || null,
});

export const mapRoles = (roles: Array<RoleResponse>) => roles.map(mapRole);

export const mapType = (typeResponse: TypeResponse): Type => ({
  name: typeResponse.name,
});

export const mapTypes = (types: Array<TypeResponse>) => types.map(mapType);

export const mapCompanyUser = (userResponse: CompanyUserResponse): CompanyUser => ({
  userId: userResponse.userId,
  firstName: userResponse.firstName || emptyFieldStub,
  lastName: userResponse.lastName || emptyFieldStub,
  status: userResponse.isActive ? 'active' : 'deactivated',
  phone: userResponse.phone || '',
  email: userResponse.email || emptyFieldStub,
  role: userResponse.role,
});

export const mapCompanyUsers = (
  usersResponse: ListCompanyUsersResponse,
) => usersResponse.users.map(mapCompanyUser);

export const mapSelfUser = (response: SelfUserResponse) => ({
  user: response.user,
  clientUserGarages: response.clientUserGarages,
  tenantUserGarages: response.tenantUserGarages,
});

export const mapUserGarageRole = (response: GetUserGarageRoleResponse) => ({
  isTenantUser: response.isTenantUser,
  isOwner: response.isOwner,
  role: response.role ? {
    label: response.role.label,
    description: response.role.description,
    uiPermissions: response.role.uiPermissions,
  } : null,
});

export const mapUserCompany = (response: NonNullable<GetUserCompanyResponse['company']>): UserCompany => ({
  companyId: response.companyId,
  name: response.name,
  address: response.address,
  phone: response.phone,
  email: response.email,
  pricingType: response.pricingType,
});
