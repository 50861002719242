import { 
  ActivityData,
  ActivityDataWitType,
  ActivityStatistic,
  ActivityType,
  GetEntryActivityByIdResponseType,
  PaymentInfo,
} from '@zero5/activity-api';
import {
  GetLatestActivitiesResponseType,
  GetActivityByIdResponseType,
  LPRStatus as LPRStatusResponse,
  ActivityStatus as ActivityStatusResponse,
} from '@zero5/activity-api';
import { capitalizeFirsLetterEveryWord } from '@zero5/ui/lib/utils/formatters/capitalize';

import { Activity, LPRStatus, ActivityStatus } from '@/api/models/activities';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import { formatTimeStampToString } from '@/utils/date/helpers';

import { EntryActivity } from '@/controlCenter/api/call/models';

import { Option } from '../models/activities';
import { Trend } from '../models/Trend';

export const mapLPRStatus = {
  [LPRStatusResponse.BLOCKED]: LPRStatus.BLOCKED,
  [LPRStatusResponse.INVALID]: LPRStatus.INVALID,
  [LPRStatusResponse.LOW_CONF_LEVEL]: LPRStatus.LOW_CONF_LEVEL,
  [LPRStatusResponse.MISSING]: LPRStatus.MISSING,
  [LPRStatusResponse.OK]: LPRStatus.OK,
};

export const mapActivityStatus = {
  [ActivityStatusResponse.ERROR]: ActivityStatus.ERROR,
  [ActivityStatusResponse.OK]: ActivityStatus.OK,
};

export const mapActivity = (activity: ActivityData, type?: ActivityType): Activity => ({
  activityId: activity.activityId,
  garageId: activity.garageId,
  deviceId: activity.deviceId,
  frontConfLevel: activity.frontConfLevel,
  frontImg: activity.frontImg,
  frontLpNum: activity.frontLpNum,
  frontLpState: activity.frontLpNum,
  frontStatus: activity.frontStatus && mapLPRStatus[activity.frontStatus],
  rearConfLevel: activity.rearConfLevel,
  rearImg: activity.rearImg,
  rearLpNum: activity.rearLpNum,
  rearLpState: activity.rearLpState,
  rearStatus: activity.rearStatus && mapLPRStatus[activity.rearStatus],
  mainLp: activity.mainLp,
  mainState: activity.mainState,
  eventTime: activity.eventTime,
  status: mapActivityStatus[activity.status],
  vehicleType: activity.vehicleType,
  vehicleMake: activity.vehicleMake,
  vehicleModel: activity.vehicleModel,
  vehicleColor: activity.vehicleColor,
  type,
});

export const mapPaymentInfo = (paymentInfo: PaymentInfo) => ({
  duration: paymentInfo.duration,
  fee: paymentInfo.fee,
  note: paymentInfo.note,
  paymentMethod: paymentInfo.paymentMethod,
});

export const mapActivities = (activityDataList: ActivityData[])=> activityDataList.map(item=> mapActivity(item));

export const mapActivitiesWithType = (activityDataList: ActivityDataWitType[])=> activityDataList.map(item=>
  mapActivity(item.data, item.type));

export const sortActivities = (activityDataList: Activity[])=> activityDataList
  .sort((itemOne, itemTwo)=> itemTwo.eventTime - itemOne.eventTime);

export const mapActivityWithType = (activity: ActivityDataWitType): Activity =>
  mapActivity(activity.data, activity.type);

export function isFieldEmpty(field: string): boolean {
  return field === emptyFieldStub;
}

export const mapActivitiesResponse = (
  activitiesResponse: GetLatestActivitiesResponseType,
) => activitiesResponse.activities.map(mapActivityWithType);

export const mapFullActivityResponse = (
  activityResponse: GetActivityByIdResponseType,
) => {
  const entryActivity = activityResponse.activity?.entryActivity
    && mapActivity(activityResponse.activity.entryActivity);
  const exitActivity = activityResponse.activity?.exitActivity
    && mapActivity(activityResponse.activity.exitActivity);
  const paymentInfo = activityResponse.paymentInfo
    && mapPaymentInfo(activityResponse.paymentInfo);
  return {
    entryActivity,
    exitActivity,
    paymentInfo,
  };
};

export const mapEntryActivityResponse = (
  activityResponse: GetEntryActivityByIdResponseType,
) => {
  const entryActivity = activityResponse.activity && mapActivity(activityResponse.activity);
  return {
    entryActivity,
    exitActivity: null,
    paymentInfo: activityResponse.paymentInfo,
  };
};

export function activitiesAggregatedCountFromBm(response: ActivityStatistic): Trend {
  const values = response.statistiscs.map((item) => item.value);
  const dates = response.statistiscs.map((item) =>  formatTimeStampToString(item.date, 'MM/dd'));
  return { values, dates };
}


function addAllLayoutsOption(options: Option[]): Option[]{
  return [{ label:'All Layouts', value: '' }, ...options];
}

export function mapLayoutsToOptions(layouts: string[]): Option[]{
  const options = layouts.map(layout=> {
    const option: Option = { label: capitalizeFirsLetterEveryWord(layout), value: layout };
    return option;
  });
  return addAllLayoutsOption(options);
}

export const mapEntryActivityToActivityData = (entryActivity: EntryActivity) =>{
  const data = entryActivity.data;
  data.mainLp = entryActivity.updatedMainLp;
  return data;
};