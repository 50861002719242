import { useQuery, UseQueryOptions } from 'react-query';

import { getWatchlistSettings } from '@zero5/garage-api';

import { watchlistQueryKeys } from './queryKeys';
import { mapWatchlistSettings } from './mappings';

type Response = ReturnType<typeof mapWatchlistSettings>;
type Key = ReturnType<typeof watchlistQueryKeys.watchlistSettings>;

const useWatchlistSettingsQuery = (
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    watchlistQueryKeys.watchlistSettings(),
    async () => {
      const response = await getWatchlistSettings();

      return mapWatchlistSettings(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useWatchlistSettingsQuery;
