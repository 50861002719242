import { createSelector, Selector } from 'reselect';

import { State } from '@/store';
import { ActivitiesState } from '@/store/reducers/activities';

const selectActivities = (state: State) => state.activitiesReducer;

// eslint-disable-next-line import/prefer-default-export
export const selectNewActivitiesQueue: Selector<State, ActivitiesState['newActivitiesQueue']> = createSelector(
  selectActivities,
  (state) => state.newActivitiesQueue,
);

export const selectIssueActivitiesQueue: Selector<State, ActivitiesState['newActivitiesQueue']> = createSelector(
  selectActivities,
  (state) => state.issueActivitiesQueue,
);
