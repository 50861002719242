import { EventStatus } from '@zero5/garage-api';

export const eventQueryKeys = {
  base: ['event'] as const,
  eventLists: () => [...eventQueryKeys.base, 'list'] as const,
  eventList: (params: {
    from: number;
    to: number;
    statuses: Array<EventStatus>;
  }) => [...eventQueryKeys.eventLists(), params] as const,
  eventCounts: () => [...eventQueryKeys.base, 'counts'] as const,
  nearestEvent: () => [...eventQueryKeys.base, 'nearestEvent'] as const,
};
