import React, { useEffect } from 'react';
import { ChartDataset } from 'chart.js';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  BigCategoryCard,
  LineChart,
  Row,
  Select,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';
import vars from '@zero5/ui/lib/styles/vars';

import { RevenueTrendAggregationPeriod } from '@/api/revenue/models';
import useGetAggregatedCount from '@/api/permit/useGetAggregatedCount';
import useGetGarageQuery from '@/api/garage/useGetGarageQuery';

import { selectPermitSettings } from '@/store/selectors/settings';
import { settingsActions } from '@/store/actions/settings';

import RangePicker from '@/components/common/RangePicker';
import { useFindCurrentAction } from '@/components/common/Role';

import useGetChartDateRangeChanger from '@/utils/hooks/useGetChartDateRangeChanger';
import useGetChartAggregationOptions from '@/utils/hooks/useGetChartAggregationOptions';

export type Value = number;

export type DataItem = {
  values: Array<Value>;
  datasetSettings?: Partial<ChartDataset<'line'>>;
};

interface Props {}

const allowedAggregation = {
  monthly: true,
};

const PermitTrendChart: React.FC<Props> = ({

}) => {
  const dispatch = useDispatch();
  const { trendDateRange } = useSelector(selectPermitSettings);
  const onDateRangeChange = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setPermitSettings({
      trendDateRange: newDateRange,
    }));
  }, [dispatch]);
  const findCurrentAction = useFindCurrentAction();
  const { data: garageData, isLoading: isGarageLoading } = useGetGarageQuery({
    enabled: findCurrentAction('permit:stats') !== 'h' || findCurrentAction('permit:trends') !== 'h',
  });
  const {
    data: permitTrend,
    isLoading,
    refetch: permitTrendRefetch,
  } = useGetAggregatedCount({
    from: trendDateRange.start,
    to: trendDateRange.end,
    aggregationPeriod: 'monthly',
  }, {
    enabled: false,
  });
  const permitTrendValues = React.useMemo(() => permitTrend?.values || [], [permitTrend?.values]);
  const permitTrendDates = React.useMemo(() => permitTrend?.dates || [], [permitTrend?.dates]);
  const permitTrendData = React.useMemo<Array<DataItem>>(() => [
    {
      values: permitTrendValues,
    },
    {
      values: permitTrendValues.map(() => garageData?.permitLimit || 200),
      datasetSettings: {
        label: 'Permit limit',
        borderColor: vars.palette.danger,
        backgroundColor: 'transparent',
        pointBackgroundColor: vars.palette.danger,
      },
    },
  ], [garageData?.permitLimit, permitTrendValues]);
  const [
    permitTrendChartAggregation,
    setPermitTrendChartAggregation,
  ] = React.useState<RevenueTrendAggregationPeriod>('monthly');

  const permitTrendChartAggregatedOptions = useGetChartAggregationOptions(trendDateRange, allowedAggregation);
  const trendDateRangeChangeHandler = useGetChartDateRangeChanger(onDateRangeChange);
  useEffect(() => {
    if (trendDateRange.end && trendDateRange.start){
      permitTrendRefetch();
    }
  }, [trendDateRange]);

  return (
    <BigCategoryCard
      title="Permit Trend"
      gap="10px"
      data-test="permit-trend"
      headerContent={
        <Row wrap="wrap" gap="10px">
          <RangePicker
            dateRange={trendDateRange}
            onChange={onDateRangeChange}
            hideShortcutsSize="1450px"
          />
          <StyledSelect
            options={permitTrendChartAggregatedOptions}
            value={permitTrendChartAggregatedOptions.find(({ value }) => value === permitTrendChartAggregation)}
            onChange={(option) => {
              trendDateRangeChangeHandler(trendDateRange, permitTrendChartAggregation);
              setPermitTrendChartAggregation(option!.value);
            }}
          />
        </Row>
      }
    >
      <LineChart
        data={permitTrendData}
        labels={permitTrendDates}
        isLoading={isLoading || isGarageLoading}
      />
    </BigCategoryCard>
  );
};

const StyledSelect = styled(Select)`
  min-width: 115px;
` as typeof Select;

export default PermitTrendChart;