import React from 'react';
import styled from 'styled-components';

import { InfoButton, Modal, RowsMap, Table, TableContent } from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import useTablePagination from '@zero5/ui/lib/utils/hooks/useTablePagination';

import useAllValidationsQuery from '@/api/validationHistory/useAllValidationsQuery';
import { ValidationHistory } from '@/api/validationHistory/models';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import useDateModule from '@/utils/date/useDateModule';

import ValidationHistoryModal from './modals/ValidationHistoryModal';

interface Props {}

const AllValidations: React.FC<Props> = ({}) => {
  const {
    formatFullDate,
  } = useDateModule();

  const [
    page,
    rowsPerPage,
    changePageHandler,
    changeRowsPerPageHandler,
    ,
    ,
    order,
    orderBy,
    changeSortingHandler,
  ] = useTablePagination();

  const [validationHistoryModalOpen, closeValidationHistoryModal, openValidationHistoryModal] = useToggle(false);
  const [validationHistoryModalData, setValidationHistoryModalData] = React.useState<string>();

  const allValidationsQuery = useAllValidationsQuery({
    order,
    sortBy: orderBy as 'timestamp' | 'note' | 'type' | 'tenantName' | 'amount',
    skip: page * rowsPerPage,
    take: rowsPerPage,
  });

  const validationHistoryRowsMap = React.useMemo<RowsMap<ValidationHistory>>(() => [
    {
      title: 'Date',
      id: 'timestamp',
      data: ({ date }) => formatFullDate(date),
      CellProps: {
        align: 'left',
      },
      HeadCellProps: {
        align: 'left',
      },
    },
    {
      title: 'Type',
      id: 'type',
      data: ({ type }) => type,
      CellProps: {
        align: 'left',
      },
      HeadCellProps: {
        align: 'left',
      },
    },
    {
      title: 'Note',
      id: 'note',
      data: ({ note }) => note,
      CellProps: {
        align: 'left',
      },
      HeadCellProps: {
        align: 'left',
      },
    },
    {
      title: 'Created By',
      id: 'tenantName',
      data: ({ tenantName }) => tenantName || emptyFieldStub,
      CellProps: {
        align: 'left',
      },
      HeadCellProps: {
        align: 'left',
      },
    },
    {
      title: 'Charge Amount',
      id: 'amount',
      data: ({ amount }) => formatPrice(amount),
      CellProps: {
        align: 'right',
      },
      HeadCellProps: {
        align: 'right',
      },
    },
    {
      title: 'Info',
      id: 'info',
      disableSorting: true,
      data: ({ validationId }) => (
        <InfoButton onClick={() => {
          setValidationHistoryModalData(validationId);
          openValidationHistoryModal();
        }} />
      ),
    },
  ], [formatFullDate, openValidationHistoryModal]);

  return (
    <>
      <TableContent
        isLoading={allValidationsQuery.isLoading}
        data={allValidationsQuery.data?.rows || []}
      >
        <Table
          rowsMap={validationHistoryRowsMap}
          data={allValidationsQuery.data?.rows!}
          count={allValidationsQuery.data?.rowsCount!}
          onPageChange={changePageHandler}
          onRowsPerPageChange={changeRowsPerPageHandler}
          onSortChange={changeSortingHandler}
          isLoading={allValidationsQuery.isFetching}
        />
      </TableContent>
      <StyledModal
        open={validationHistoryModalOpen}
        onClose={closeValidationHistoryModal}
        fullScreenSize="620px"
      >
        <ValidationHistoryModal
          id={validationHistoryModalData!}
          onClose={closeValidationHistoryModal}
        />
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  max-width: 540px;
`;

export default AllValidations;