import React, { Suspense } from 'react';
import styled, { css } from 'styled-components';

import { Button, Row, SimpleRowsMap, SimpleTable, Skeleton } from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import { capitalize } from '@zero5/ui/lib/utils/formatters/capitalize';

import { Company } from '@/api/permit/models';
import { Validation } from '@/api/validationHistory/models';
import useValidationDetailsQuery from '@/api/validationHistory/useValidationDetailsQuery';

import useDateModule from '@/utils/date/useDateModule';
import { handleError } from '@/utils/handleError';
import { emptyFieldStub } from '@/utils/emptyFieldStub';

interface Props {
  id: string;
  onClose: () => void;
}

const ValidationHistoryModal: React.FC<Props> = ({ ...props }) => {
  return (
    <Suspense fallback={<StyledSkeleton variant="rect" />}>
      <ValidationHistoryModalContent {...props} />
    </Suspense>
  );
};

const StyledSkeleton = styled(Skeleton)`
  width: 100vw;
  max-width: 100%;
  min-height: 435px;
  height: 100%;
`;

const ValidationHistoryModalContent: React.FC<Props> = ({
  onClose,
  id,
}) => {
  const {
    formatFullMonthDateYear,
  } = useDateModule();

  const validationDetailsQuery = useValidationDetailsQuery({
    validationId: id,
  }, {
    suspense: true,
    onError: (error) => {
      onClose();
      handleError(error);
    },
  });

  const validationInfoRowsMap: SimpleRowsMap<{
    validationData: Validation;
    company: Company;
  }> = [
    {
      title: 'Date',
      data: ({ validationData }) => formatFullMonthDateYear(validationData.created),
    },
    {
      title: 'Created By',
      data: ({ company }) => capitalize(company.name),
    },
    {
      title: 'Type',
      data: ({ validationData }) => validationData.type.name,
    },
    {
      title: 'Created For',
      data: ({ validationData }) => validationData.email,
    },
    {
      title: 'License Plate',
      data: ({ validationData }) => validationData.lpNum ?? emptyFieldStub,
    },
  ];

  return (
    <Wrapper>
      <Title>Validation Detail</Title>
      <ValidationInfoSimpleTable
        rowsMap={validationInfoRowsMap}
        item={validationDetailsQuery.data!}
      />
      <Title as="h3">Summary</Title>
      <TableContainer>
        <SummaryTable>
          <thead>
            <tr>
              <SummaryTH>Item</SummaryTH>
              <SummaryTH>For</SummaryTH>
              <SummaryTH>Price</SummaryTH>
            </tr>
          </thead>
          <tbody>
            <SummaryTableContentTR>
              <SummaryTD>{validationDetailsQuery.data!.validationData.type.name}</SummaryTD>
              <SummaryTD>{validationDetailsQuery.data!.garage.label}</SummaryTD>
              <SummaryTD>{formatPrice(validationDetailsQuery.data!.price)}</SummaryTD>
            </SummaryTableContentTR>
          </tbody>
        </SummaryTable>
      </TableContainer>
      <ModalText>
        There will be no refunds once the validations are made. For any inquiries,
        please contact <TextLink href="mailto:support@zero5.co">support@zero5.co</TextLink>.
      </ModalText>
      <ButtonRow justifyContent="flex-end">
        <Button onClick={onClose} variant="contained" color="primary">Close</Button>
      </ButtonRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 20px;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const TableContainer = styled.div`
  padding: 10px;
  margin-bottom: 30px;
  background-color: #F0F0F0;
`;

const summaryTableCellProps = css`
  width: 33%;
  padding: 10px;

  text-align: left;

  &:last-child {
    text-align: right;
  }
`;

const SummaryTH = styled.th`
  ${summaryTableCellProps}

  color: #A2A2A2;
  font-weight: 400;
`;

const SummaryTD = styled.td`
  ${summaryTableCellProps}
`;

const SummaryTableContentTR = styled.tr`
  &:last-child {
    & ${SummaryTD} {
      padding-bottom: 20px;
    }
  }
`;

const SummaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

const ValidationInfoSimpleTable = styled(SimpleTable)`
  margin-bottom: 30px;

  font-size: 14px;
  line-height: 16px;

  th, td {
    padding: 5px 0;
  }

  th {
    color: black;
    text-align: left;
    vertical-align: top;
    font-weight: 400;
  }

  td {
    text-align: right;
    color: rgb(162,162,162);
    font-weight: 400;
  }
` as typeof SimpleTable;

const ModalText = styled.p`
  margin: 0;
  margin-bottom: 20px;

  font-size: 12px;
  font-weight: 300;
  color: #BCBEC0;
`;

const TextLink = styled.a`
  text-decoration: none;
  color: #2541B2;
`;

const ButtonRow = styled(Row)`
  margin-top: auto;
`;

export default ValidationHistoryModal;