import React from 'react';
import styled from 'styled-components';

import {
  Row,
  Select,
} from '@zero5/ui';

import RangePicker from '@/components/common/RangePicker';

import useDateModule from '@/utils/date/useDateModule';

export type AggregationPeriod = 'daily' | 'weekly' | 'monthly';
type DateRange = {
  start: Date;
  end: Date;
};

const isAggregationAvailable = (aggregation: AggregationPeriod, range: DateRange) => {
  const monthlyInMilliseconds = 91 * 24 * 60 * 60 * 1000;
  const weeklyInMilliseconds = 14 * 24 * 60 * 60 * 1000;

  const rangeInMilliseconds = range.end.getTime() - range.start.getTime();

  switch (aggregation) {
    case 'daily': 
      return true;
    case 'weekly':
      return rangeInMilliseconds >= weeklyInMilliseconds;
    case 'monthly':
      return rangeInMilliseconds >= monthlyInMilliseconds;
  }
};

interface Props {
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
  aggregation: AggregationPeriod;
  onAggregationPeriodChange: (aggregation: AggregationPeriod) => void;
}

const AggregationRangePicker: React.FC<Props> = ({
  dateRange,
  onDateRangeChange,
  aggregation,
  onAggregationPeriodChange,
}) => {
  const {
    z5DateModule,
  } = useDateModule();

  const aggregatedOptions = React.useMemo(() => {
    const options: Array<{
      label: string;
      value: AggregationPeriod;
    }> = [];
    
    if (isAggregationAvailable('daily', dateRange)) {
      options.push({
        label: 'Daily',
        value: 'daily',
      });
    }

    if (isAggregationAvailable('weekly', dateRange)) {
      options.push({
        label: 'Weekly',
        value: 'weekly',
      });
    }

    if (isAggregationAvailable('monthly', dateRange)) {
      options.push({
        label: 'Monthly',
        value: 'monthly',
      });
    }

    return options;
  }, [dateRange]);

  const trendDateRangeChangeHandler = React.useCallback(
    (selectedRange: DateRange, newAggregation: AggregationPeriod) => {
      const endOfToday = new Date(z5DateModule.fromUtc(Date.now()).dayEnd().getTimestamp());

      switch (newAggregation) {
        case 'weekly':
          if (isAggregationAvailable('weekly', selectedRange)) {
            const newStart = new Date(z5DateModule.fromUtc(selectedRange.start.getTime()).weekStart().getTimestamp());
            const newEnd = new Date(
              Math.min(
                z5DateModule.fromUtc(selectedRange.end.getTime()).weekEnd().getTimestamp(),
                endOfToday.getTime(),
              ),
            );
    
            const newRange = {
              start: newStart,
              end: newEnd,
            };
    
            // eslint-disable-next-line no-param-reassign
            selectedRange = newRange;
          } else {
            onAggregationPeriodChange('daily');
          }
          break;
        case 'monthly':
          if (isAggregationAvailable('monthly', selectedRange)) {
            const newStart = new Date(z5DateModule.fromUtc(selectedRange.start.getTime()).monthStart().getTimestamp());
            const newEnd = new Date(
              Math.min(
                z5DateModule.fromUtc(selectedRange.end.getTime()).monthEnd().getTimestamp(),
                endOfToday.getTime(),
              ),
            );
    
            const newRange = {
              start: newStart,
              end: newEnd,
            };

            // eslint-disable-next-line no-param-reassign
            selectedRange = newRange;
          } else {
            onAggregationPeriodChange('daily');
          }
      }

      onDateRangeChange(selectedRange);
    },
    [onAggregationPeriodChange, onDateRangeChange, z5DateModule],
  );

  return (
    <Row wrap="wrap" gap="10px">
      <RangePicker
        dateRange={dateRange}
        onChange={(range) => trendDateRangeChangeHandler(range, aggregation)}
      />
      <StyledSelect
        options={aggregatedOptions}
        value={aggregatedOptions.find(({ value }) => value === aggregation)}
        onChange={(option) => {
          trendDateRangeChangeHandler(dateRange, option!.value);
          onAggregationPeriodChange(option!.value);
        }}
      />
    </Row>
  );
};

const StyledSelect = styled(Select)`
  min-width: 115px;
` as typeof Select;

export default AggregationRangePicker;