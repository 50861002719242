import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import NotFoundImage from '@/assets/images/not_found.svg';

const PageNotFound: React.FC = () => {
  return (
    <Wrapper>
      <StyledImage src={NotFoundImage} alt="Page not found" />
      <StyledTypography>Sorry, page not found!</StyledTypography>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const StyledImage = styled.img`
  display: block;
  width: 600px;
`;

const StyledTypography = styled(Typography).attrs({
  variant: 'h1',
})`
  margin-top: 20px;
  font-weight: 700;
`;

export default PageNotFound;
