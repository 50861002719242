import { createSelector } from 'reselect';

import { State } from '@/store';

const selectAuth = (state: State) => state.authReducer;

export const selectSignInState = createSelector(
  selectAuth,
  (authState) => authState.signInStatus,
);
