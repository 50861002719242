import { useQuery, UseQueryOptions } from 'react-query';

import { getWatchlistVehicle } from '@zero5/garage-api';

import { watchlistQueryKeys } from './queryKeys';
import { mapWatchlistItem } from './mappings';

type Response = ReturnType<typeof mapWatchlistItem>;
type Key = ReturnType<typeof watchlistQueryKeys.watchlistVehicle>;
type Params = {
  watchlistVehicleId: number;
};

const useWatchlistVehicleQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    watchlistQueryKeys.watchlistVehicle(params.watchlistVehicleId),
    async () => {
      const response = await getWatchlistVehicle(params.watchlistVehicleId);

      return mapWatchlistItem(response.watchlistVehicle);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useWatchlistVehicleQuery;
