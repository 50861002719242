import React from 'react';
import styled from 'styled-components';

interface Props {
  color: string;
  label: string;
  className?: string;
}

const StatusRectangle: React.FC<Props> = ({ color, label, className }) => {
  return (
    <Wrapper $color={color} className={className}>
      {label}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $color: string; }>`
  display: inline-block;
  padding: 4px 7px;
  background-color: ${({ $color }) => $color};
  white-space: nowrap;
`;

export default StatusRectangle;