import assertNever from 'assert-never';

import {
  Activity,
  DeviceMessageType,
  PaymentCalculationResultWithActivity,
  PaymentFeatures as PaymentFeaturesResponse,
  PaymentFeatureType,
  EntryActivity,
  EntryActivityEngineStatus,
  DeviceActivityMessage, SessionPayload,
} from '@zero5/client-admin-api';

import { NonNullableObject } from '@/utils/types';

import { mapActivities, mapActivity } from '../activity/mappings';

import { ActivityEngineStatus, ActivityPair, CallData, IncomingCall, PaymentFeatures } from './models';

export const mapActivityPair = (response: Activity): ActivityPair => ({
  entryActivity: response.entryActivity && mapActivity(response.entryActivity.data),
  exitActivity: mapActivity(response.exitActivity),
});

const mapPaymentFeatures = (features: PaymentFeaturesResponse): PaymentFeatures => {
  switch (features.type) {
    case PaymentFeatureType.Discount:
      return {
        type: PaymentFeatureType.Discount,
        discount: features.discount,
      };
    case PaymentFeatureType.DiscountMinutes:
      return {
        type: PaymentFeatureType.DiscountMinutes,
        minutes: features.minutes,
      };
    case PaymentFeatureType.SpecialPrice:
      return {
        type: PaymentFeatureType.SpecialPrice,
        priceCents: features.priceCents,
      };

    default:
      assertNever(features);
  }
};

export const mapPaymentCalculationResult = (response: PaymentCalculationResultWithActivity) => ({
  ...response,
  activity: mapActivityPair(response.activity),
  paymentFeatures: response.paymentFeatures && mapPaymentFeatures(response.paymentFeatures),
});

const mapActivityEngineStatus = (status: EntryActivityEngineStatus): ActivityEngineStatus => {
  const statusToString: Record<EntryActivityEngineStatus, ActivityEngineStatus> = {
    [EntryActivityEngineStatus.DUPLICATE]: 'DUPLICATE',
    [EntryActivityEngineStatus.VALID]: 'VALID',
  };

  return statusToString[status];
};

export const mapEntryActivity = (response: EntryActivity) => ({
  data: mapActivity(response.data),
  activityEngineStatus: mapActivityEngineStatus(response.activityEngineStatus),
  isUsed: response.isUsed,
});

export const mapEntryActivities = (response: Array<EntryActivity>) => response.map(mapEntryActivity);

export const mapCallData = (callData: NonNullableObject<DeviceActivityMessage>) => {
  let mappedCallData: CallData | null = null;

  switch (callData.type) {
    case DeviceMessageType.EntryFound:
      mappedCallData = {
        type: 'ENTRY_FOUND',
        payload: {
          activity: mapActivityPair(callData.payload.activity),
          paymentCalculationResult: mapPaymentCalculationResult(
            callData.payload.paymentCalculationResult,
          ),
        },
      };
      break;
    case DeviceMessageType.ActivityResolved:
      mappedCallData = {
        type: 'ACTIVITY_RESOLVED',
        payload: {
          activity: mapActivityPair(callData.payload.activity),
          paymentCalculationResult: mapPaymentCalculationResult(
            callData.payload.paymentCalculationResult,
          ),
        },
      };
      break;
    case DeviceMessageType.EntryNotFound:
      mappedCallData = {
        type: 'ENTRY_NOT_FOUND',
        payload: {
          activity: mapActivityPair(callData.payload.activity),
          suggestedEntryActivities: mapActivities(callData.payload.suggestedEntryActivities),
          defaultFee: callData.payload.defaultFee,
        },
      };
      break;
    case DeviceMessageType.AmbiguousEntryActivities:
      mappedCallData = {
        type: 'AMBIGUOUS_ENTRY_ACTIVITIES',
        payload: {
          activity: mapActivityPair(callData.payload.activity),
          ambiguousActivities: callData.payload.ambiguousActivities,
          defaultFee: callData.payload.defaultFee,
        },
      };
      break;
    case DeviceMessageType.ErrorExitActivity:
      mappedCallData = {
        type: 'ERROR_EXIT_ACTIVITY',
        payload: {
          activity: mapActivityPair(callData.payload.activity),
          defaultFee: callData.payload.defaultFee,
        },
      };
      break;
    case DeviceMessageType.PaymentRecalculatedWithTickets:
      mappedCallData = {
        type: 'PAYMENT_RECALCULATED_WITH_TICKETS',
        payload: {
          activity: mapActivityPair(callData.payload.activity),
          paymentCalculationResult: mapPaymentCalculationResult(
            callData.payload.paymentCalculationResult,
          ),
          tickets: callData.payload.tickets,
        },
      };
      break;
  }

  return mappedCallData;
};

export const mapCall = (sessionId: string, call: SessionPayload): IncomingCall => ({
  requestId: sessionId,
  deviceId: call.deviceId,
  callData: call.payload && mapCallData(call.payload),
  note: null,
});
