import { useQuery, UseQueryOptions } from 'react-query';

import { getValidationHistoryCardData } from '@zero5/garage-api';

import { validationHistoryQueryKeys } from './queryKeys';
import { mapValidationHistoryCards } from './mappings';

type Response = ReturnType<typeof mapValidationHistoryCards>;
type Key = ReturnType<typeof validationHistoryQueryKeys.cards>;
type Params = {
  fromDate: number;
  toDate: number;
};

const useValidationHistoryCardsQuery = (
  params: Params,
  options?: UseQueryOptions <Response, unknown, Response, Key>,
) => {
  return useQuery(
    validationHistoryQueryKeys.cards(params),
    async () => {
      const response = await getValidationHistoryCardData(params);

      return mapValidationHistoryCards(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useValidationHistoryCardsQuery;
