import React from 'react';

import DatePickerLib, { Props } from '@zero5/ui/lib/DatePicker';

import useDateModule from '@/utils/date/useDateModule';

const DatePicker: React.FC<Props> = (props) => {
  const { z5DateModule } = useDateModule();

  return (
    <DatePickerLib
      timezone={z5DateModule.getTimezone()}
      {...props}
    />
  );
};

export default DatePicker;
