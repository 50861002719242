import { useMutation, UseMutationOptions } from 'react-query';

import {
  createValidationType,
  CreateValidationTypeArgs,
  ValidationTypeData as ValidationType,
} from '@zero5/garage-api';

import { handleError } from '@/utils/handleError';
import queryClient from '@/utils/queryClient';

import { queryKey as validationTypeQuery } from './useGetValidationTypesQuery';

type Response = ValidationType;

type Args = CreateValidationTypeArgs;

const queryKey = 'createValidationType';

const useCreateValidationTypeMutation = (
  options?: UseMutationOptions<Response, Error, Args, string>,
) => {

  return useMutation(
    queryKey,
    async (params) => {
      const response = await createValidationType(params);

      return response.validationType;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(validationTypeQuery);

        options?.onSuccess?.(...params);
      },
      onError: (error) => handleError(error),
    },
  );
};

export default useCreateValidationTypeMutation;
