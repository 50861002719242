import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { setWatchlistContacts } from '@zero5/garage-api';

import { handleError } from '@/utils/handleError';

import { watchlistQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  userIds: number[];
};

const useSetWatchlistContactsMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params) => {
      const response = await setWatchlistContacts(params.userIds);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(watchlistQueryKeys.watchlistContacts());
        options?.onSuccess?.(...params);
      },
      onError: (...params) => {
        const [error] = params;
        handleError(error, 'Error while updating watchlist vehicle.');
        options?.onError?.(...params);
      },
    },
  );
};

export default useSetWatchlistContactsMutation;
