import { useQuery, UseQueryOptions } from 'react-query';

import { countValidationsThisMonth } from '@zero5/garage-api';

import { mapCountValidationsThisMonth } from './mappings';
import { validationQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapCountValidationsThisMonth>;
type Key = ReturnType<typeof validationQueryKeys.countValidationsThisMonth>;

const useCountValidationsThisMonthQuery = (
  companyId: number,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    validationQueryKeys.countValidationsThisMonth(companyId),
    async () => {
      const response = await countValidationsThisMonth(companyId);

      return mapCountValidationsThisMonth(response.validations);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useCountValidationsThisMonthQuery;
