import React, {  } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  Table,
  RowsMap,
  ButtonIcon,
  TableContent,
  Card,
  CardHead,
  RangePicker,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';
import { EventStatus as EventStatusType } from '@zero5/garage-api';

import { Event as EventType } from '@/api/models/events';
import useEventsQuery from '@/api/event/useEventsQuery';

import { selectEventsSettings } from '@/store/selectors/settings';
import { settingsActions } from '@/store/actions/settings';

import { useFindCurrentAction } from '@/components/common/Role';

import InfoIcon from '@/assets/icons/dots.inline.svg';

import EventStatus from './EventStatus';

interface Props {
  statuses: Array<EventStatusType>;
  onEventSelect: (event: EventType) => void;
}

const EventsTable: React.FC<Props> = ({ statuses, onEventSelect }) => {
  const dispatch = useDispatch();
  const findCurrentAction = useFindCurrentAction();

  const { dateRange } = useSelector(selectEventsSettings);  

  const dateRangeChangeHandler = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setEventsSettings({
      dateRange: newDateRange,
    }));
  }, [dispatch]);

  const { data: events, isLoading } = useEventsQuery({
    statuses,
    from: dateRange.start.getTime(),
    to: dateRange.end.getTime(),
  });

  const eventsRowsMap = React.useMemo(() => {
    const eventsRows: RowsMap<EventType> = [
      {
        title: 'Name',
        id: 'name',
        data: ({ name }) => name,
      },
      {
        title: 'Host',
        id: 'host',
        data: ({ host }) => host,
      },
      {
        title: 'Date',
        id: 'date',
        data: ({ date }) => date,
      },
      {
        title: 'Time',
        id: 'time',
        data: ({ time }) => time,
      },
      {
        title: 'Event Status',
        id: 'status',
        data: ({ status }) => <EventStatus status={status} />,
      },
      {
        title: 'Reserved Spots',
        id: 'reservedSpots',
        data: ({ reservedSpots }) => reservedSpots,
      },

    ];
    if (findCurrentAction('event:updateEvent') === 'r' || findCurrentAction('event:updateEvent') === 'w') {
      eventsRows.push({
        title: 'Info',
        id: 'info',
        disableSorting: true,
        data: (event) => (
          <StyledButtonIcon
            Icon={InfoIcon}
            onClick={() => onEventSelect(event)}
            data-test="event-info-icon"
          />
        ),
      });
    }
    return eventsRows;
  }, [findCurrentAction, onEventSelect]);

  return (
    <Card>
      <CardHead
        title="Event List"
        headerContent={
          <RangePicker
            data-test="periodInput"
            dateRange={dateRange}
            onChange={dateRangeChangeHandler}
            reverse
            allowFullRangeOfDates
            moveEndDateToEndOfDay
          />
        }
      />
      <TableContent
          isLoading={isLoading}
          data={events || []}
      >
        <StyledTable
            rowsMap={eventsRowsMap}
            data={events || []}
            data-test="events-list"
        />
      </TableContent>
    </Card>
  );
};

const StyledTable = styled(Table)`
  margin-top: 10px;
  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

` as typeof Table;

const StyledButtonIcon = styled(ButtonIcon)`
  margin: 0 auto;
`;

export default EventsTable;