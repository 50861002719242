import React, {  } from 'react';
import styled from 'styled-components';

import {
  CategoryCard,
} from '@zero5/ui';

import useEventCountsQuery from '@/api/event/useEventCountsQuery';
import useGetNearestEventQuery from '@/api/event/useGetNearestEvent';

import Role from '@/components/common/Role';

import useDateModule from '@/utils/date/useDateModule';

interface Props {}

const EventsDataCards: React.FC<Props> = ({}) => {
  const { getMonthAndYear } = useDateModule();

  const { data: dataCounts } = useEventCountsQuery();
  const { data: nearestEvent } = useGetNearestEventQuery();

  const cardsData = [
    {
      title: 'Next Event',
      data: nearestEvent,
      widgetId: 'event:stats:nextEvent',
    },
    {
      title: 'Scheduled Events',
      data: dataCounts !== undefined ? dataCounts.scheduledCount : '-',
      subtext: getMonthAndYear(new Date()),
      widgetId: 'event:stats:scheduledEvent',
    },
    {
      title: 'Upcoming Events',
      data: dataCounts !== undefined ? dataCounts.upcomingCount : '-',
      subtext: getMonthAndYear( new Date()),
      widgetId: 'event:stats:upcomingEvent',
    },
    {
      title: 'Pending Requests',
      data: dataCounts !== undefined ? dataCounts.pendingCount : '-',
      subtext: getMonthAndYear(new Date()),
      widgetId: 'event:stats:pendingRequests',
    },
  ];

  return (
    <CardsWrapper>
      {cardsData.map(({ widgetId, ...props }) => (
          <Role widgetId={widgetId} action="r">
            <CategoryCard key={props.title} {...props} />
          </Role>
      ))}
    </CardsWrapper>
  );
};

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

export default EventsDataCards;