/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import {
  Buttons, Label, Image, Button, DataCard, SimpleTable, Checkbox, SimpleTableProps,
} from '@zero5/ui';
import { ActivityStatus, EntryStatus, VehicleStatus } from '@zero5/client-admin-api';
import formatLicensePlate from '@zero5/ui/lib/utils/formatters/formatLicensePlate';
import { getZone } from '@zero5/ui/lib/utils/formatters/formatGate';

import { handleError } from '@/utils/handleError';
import { getEnumLabelValuePairs } from '@/utils/getEnumLabelValuePairs';
import useDateModule from '@/utils/date/useDateModule';

import { IssueActivity as ActivityType } from '@controlCenter/api/activity/models';
import useUpdateEntryActivityMutation from '@controlCenter/api/activity/useUpdateEntryActivityMutation';

import GarageTitle from '../common/GarageTitle';
import Select from '../common/Select';
import EditableText from '../common/EditableText';
import { Params } from '../../api/activity/useGetIssueActivitiesQuery';

interface Props {
  onClose: () => void;
  activity: ActivityType;
  keyParams: Params;
}

interface FormikState {
  confirmedLP: string;
  confirmedLPStatus: EntryStatus | null;
  frontLPStatus: VehicleStatus |  null;
  rearLPStatus: VehicleStatus | null;
  good: boolean;
}

const entryLPoptions = getEnumLabelValuePairs(EntryStatus);

const Activity: React.FC<Props> = ({ onClose, activity, keyParams }) => {
  const {
    formatTimeStampToString,
  } = useDateModule();

  const [imageType, setImageType] = React.useState<'front' | 'rear'>('front');

  const updateEntryActivitiesMutation = useUpdateEntryActivityMutation();

  const formik = useFormik<FormikState>({
    initialValues: {
      confirmedLP: activity.mainLp || '',
      confirmedLPStatus: activity.mainLpResolutionStatus,
      frontLPStatus: activity.frontLpResolutionStatus,
      rearLPStatus: activity.rearLpResolutionStatus,
      good: activity.status === 'OK',
    },
    onSubmit: async ({ confirmedLP, confirmedLPStatus, rearLPStatus, frontLPStatus, good }) => {
      try {
        await updateEntryActivitiesMutation.mutateAsync({
          activityId: activity.activityId,
          data: {
            rearLpResolutionStatus: rearLPStatus || undefined,
            mainLpResolutionStatus: confirmedLPStatus || undefined,
            frontLpResolutionStatus: frontLPStatus || undefined,
            status: good ? ActivityStatus.OK : ActivityStatus.ERROR,
          },
          licensePlate: confirmedLP,
          keyParams,
        });

        onClose();
      } catch (error) {
        handleError(error);
      }
    },
  });


  const entryInfoRowsMap: Array<{
    title: string;
    data: (item: ActivityType) => React.ReactNode;
  }> = [
    {
      title: 'Vehicle Status',
      data: ({ isPermit }) => isPermit ? 'Permit' : 'Visitor',
    },
    {
      title: 'Updated',
      data: ({ issueStatus }) => issueStatus === 'GOOD' || issueStatus === 'RESOLVED' ? 'Yes' : 'No',
    },
    {
      title: 'Entry Location',
      data: ({ deviceId }) => `Layout ${getZone(deviceId)}`,
    },
    {
      title: 'Entry Time',
      data: ({ eventTime }) => formatTimeStampToString(eventTime, 'MM/dd/yyyy HH:mm'),
    },
    {
      title: 'Confirmed LP',
      data: () => (
        <Row>
          <InlineWrapper>
            <StyledEditableText
              value={formik.values.confirmedLP}
              onChange={(e) => formik.setFieldValue('confirmedLP', formatLicensePlate(e.target.value))}
            />
            <StyledSelect
              options={entryLPoptions}
              onChange={(value) => formik.setFieldValue('confirmedLPStatus', value?.value)}
              value={entryLPoptions.find((option) => option.value === formik.values.confirmedLPStatus)}
            />
          </InlineWrapper>
        </Row>
      ),
    },
    {
      title: 'Good',
      data: () => (
        <Checkbox
          checked={formik.values.good}
          onChange={(e, checked) => {
            formik.setFieldValue('good', checked);
          }}
        />
      ),
    },
  ];

  const vehicleInfoRowsMap: Array<{
    title: string;
    data: (item: ActivityType) => React.ReactNode;
  }> = [
    {
      title: 'LPR Status',
      data: ({ frontLpNum, rearLpNum }) => {
        if (!frontLpNum || !rearLpNum || !isNaN(Number(frontLpNum)) || !isNaN(Number(rearLpNum))) {
          return 'No LP';
        }

        return frontLpNum === rearLpNum ? 'Match' : 'Missmatch';
      },
    },
    {
      title: 'Confidence Level',
      data: ({ frontConfLevel }) => frontConfLevel,
    },
    {
      title: 'Front LP',
      data: ({ frontLpNum }) => (
        <Row>
          <StyledText>{frontLpNum}</StyledText>
          {/*<StyledSelect
            options={vehicleLPOptions}
            onChange={(value) => formik.setFieldValue('frontLPStatus', value?.value)}
            value={vehicleLPOptions.find((option) => option.value === formik.values.frontLPStatus)}
          />*/}
        </Row>
      ),
    },
    {
      title: 'Rear LP',
      data: ({ rearLpNum }) => (
        <Row>
          <StyledText>{rearLpNum}</StyledText>
          {/*<StyledSelect
            options={vehicleLPOptions}
            onChange={(value) => formik.setFieldValue('rearLPStatus', value?.value)}
            value={vehicleLPOptions.find((option) => option.value === formik.values.rearLPStatus)}
          />*/}
        </Row>
      ),
    },
  ];

  if (!activity) return null;

  return (
    <>
      <Wrapper>
        <GarageTitle
          name="Location"
          value={activity.garageId}
        />
        <GarageTitle
          name="Vehicle Information"
          value={activity.activityId}
        />
          <Gate>
            <Buttons
              onBack={() => setImageType('front')}
              onForward={() => setImageType('rear')}
              disableBack={imageType === 'front'}
              disableForward={imageType === 'rear'}
            >
              <Label text="Entry">
                <StyledImage
                  width="400px"
                  height="220px"
                  src={(imageType === 'front'
                    ? activity.frontImg
                    : activity.rearImg)
                    || ''}
                />
              </Label>
            </Buttons>
          </Gate>
          <Information>
            <StyledDataCard label="Entry Info">
              <StyledSimpleTable rowsMap={entryInfoRowsMap} item={activity} align="left" />
            </StyledDataCard>
            <StyledDataCard label="Vehicle Info">
              <StyledSimpleTable rowsMap={vehicleInfoRowsMap} item={activity} align="left" />
            </StyledDataCard>
          </Information>
        </Wrapper>
        <ButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
            onClick={formik.submitForm}
          >
            confirm
          </Button>
          <Button variant="text" color="primary" onClick={onClose}>cancel</Button>
        </ButtonsWrapper>
    </>
  );
};

const Wrapper = styled.div`
display: grid;
grid-template-columns: min-content auto;
grid-gap: 50px;
align-items: center;

@media (max-width: 960px) {
  grid-template-columns: 1fr;
}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InlineWrapper = styled.div`
  & > * {
    display: inline-block;
  }
`;

const StyledSelect = styled(Select)`
  max-width: 150px;
  line-height: 1;
`;

const StyledEditableText = styled(EditableText)`
  max-width: 150px;
  line-height: 1;
  display: flex;
`;

const StyledText = styled.span`
  width: 150px;
`;

const Gate = styled.div`
position: relative;
width: min-content;
margin: 0 auto;

@media (max-width: 460px) {
  width: 100%;
}
`;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `;

const Information = styled.div`
display: grid;
grid-template-rows: repeat(3, auto);
grid-row-gap: 15px;
`;
//   display: grid;
//   grid-template-rows: auto auto;
//   grid-gap: 20px;
// `;

const StyledDataCard = styled(DataCard)`
th {
  width: 100px;
}

@media (max-width: 460px) {
  grid-template-columns: 1fr;
}
`;
//   grid-template-columns: 100px 1fr;
// `;

// const CardContent = styled.div`

// `;

const StyledImage = styled(Image)`
  @media (max-width: 460px) {
    width: 100%;
  }
`;

const StyledSimpleTable = styled<React.FC<SimpleTableProps<ActivityType>>>(SimpleTable)`
  width: 100%;

  th {
    width: 120px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;

  > *:first-child {
    margin-right: 10px;
  } 
`;

export default Activity;
