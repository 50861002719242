/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getValidationTypesForTenantType } from '@zero5/garage-api';

import { mapValidationTypeList } from '../mappings/validationType';

type Response = ReturnType<typeof mapValidationTypeList>;

export const queryKey: QueryKey = 'validationTypesForTenantType';

const useValidationTypesForTenantTypeQuery = (
  tenantType: string,
  options?: UseQueryOptions <Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery<Response, unknown, Response, typeof queryKey>(
    queryKey,
    async () => {
      const response = await getValidationTypesForTenantType(tenantType);

      return mapValidationTypeList(response.validationTypes);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useValidationTypesForTenantTypeQuery;
