import React from 'react';
import { Redirect } from 'react-router';

import user from '@/api/user';

export const withUnauthorized = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => (props) => {
  const isLoggedIn = user.isLoggedIn();

  if (!isLoggedIn) return <Component {...props} />;

  return <Redirect to="/" />;
};
