import React from 'react';
import styled from 'styled-components';
import produce from 'immer';
import isEqual from 'lodash/isEqual';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import {
  Button, Checkbox, Row, Select, InputPrice, LoadingIndicator,
} from '@zero5/ui';
import toast from '@zero5/ui/lib/utils/toast';
import { centsToCost, costToCents } from '@zero5/ui/lib/utils/formatters/formatPrice';
import { capitalize } from '@zero5/ui/lib/utils/formatters/capitalize';

import useGarageHoursOpenQuery from '@/api/garage/useGarageHoursOpenQuery';
import useGarageOptionsQuery, { queryKey as garageOptionsQueryKey } from '@/api/queries/useGarageOptionsQuery';
import useUpdateGarageOptionsMutation from '@/api/mutations/useUpdateGarageOptionsMutation';
import { EventParkingFeeType, GarageDay, GarageOptions } from '@/api/models/garages';
import { GARAGE_HOURS_OPEN_QUERY, GARAGE_QUERY } from '@/api/garage/constants';
import useSetGarageHoursOpenMutation from '@/api/garage/useSetHoursOpenMutation';

import { selectCurrentGarage } from '@/store/selectors/workspace';

import { useFindCurrentAction } from '@/components/common/Role';
import RowControlButtons from '@/components/common/RowControlButtons';

import { feeOptions, GracePeriodOptions, hoursOptions, freePeriodOptions } from '@/utils/options';
import { handleError } from '@/utils/handleError';
import useDateModule from '@/utils/date/useDateModule';
import { Option } from '@/utils/getMinutesOptions';

import TimePicker from '../common/TimePicker';

import Title from './common/Title';
import Table, { HeadCell, TableCell } from './common/Table';
import { setHoursAndMinutes } from './utils/setHoursAndMinutes';
import { sortPolicyArray } from './utils/sortPolicyArray';
import { Policy } from './utils/types';

const calculateMaxTimeForEndPeriod = (idx: number, array: Policy[]): string => {
  return array.filter((policy, i) => array[idx].day === policy.day && i !== idx)
    .reduce((prevMaxTime, policy) => {
      if (Number(array[idx].start) < Number(policy.start)) {
        return Number(policy.start) < Number(prevMaxTime) ? policy.start : prevMaxTime;
      } else return prevMaxTime;
    }, '2359');
};

const calculateMinTimeForStartPeriod = (idx: number, array: Policy[]): string => {
  return array.filter((policy, i) => array[idx].day === policy.day && i !== idx)
    .reduce((prevMaxTime, policy) => {
      if (array[idx].end === '0000') return '0000';
      return Number(policy.end) > Number(prevMaxTime) ? policy.end : prevMaxTime;
    }, '0000');
};

const HoursAndFees = () => {
  const garageHoursOpenQuery = useGarageHoursOpenQuery();
  const garageOptionsQuery = useGarageOptionsQuery();

  if (garageHoursOpenQuery.isLoading || garageOptionsQuery.isLoading) return <LoadingIndicator />;

  return (
    <HoursAndFeesContent
      initialOptions={garageOptionsQuery.data!}
      initialPolicies={garageHoursOpenQuery.data!}
    />
  );
};

interface Props {
  initialOptions: GarageOptions;
  initialPolicies: Array<Policy>;
}

const HoursAndFeesContent: React.FC<Props> = ({
  initialOptions,
  initialPolicies,
}) => {
  const queryClient = useQueryClient();
  const findCurrentAction = useFindCurrentAction();

  const { formatTimeStampToString, z5DateModule } = useDateModule();

  const [policies, setPolicies] = React.useState<Array<Policy> | null>(initialPolicies);
  const [options, setOptions] = React.useState<GarageOptions | null>(initialOptions);

  const currentGarage = useSelector(selectCurrentGarage);

  const garageHoursOpenQuery = useGarageHoursOpenQuery({
    onSuccess: (data) => setPolicies(data),
  });
  const garageOptionsQuery = useGarageOptionsQuery({
    onSuccess: (data) => setOptions(data),
  });

  const setGarageHoursOpenMutation = useSetGarageHoursOpenMutation();
  const updateGarageOptionsMutation = useUpdateGarageOptionsMutation();

  const saveHandler = React.useCallback(async () => {
    try {
      if (policies!.some((policy) => parseInt(policy.start, 10) >= parseInt(policy.end, 10))) {
        throw new Error('Garage opening hours cannot overlap');
      }

      setGarageHoursOpenMutation.mutateAsync({
        hoursOpen: policies!,
      });

      if (options && !isEqual(options, garageOptionsQuery.data!)) {
        await updateGarageOptionsMutation.mutateAsync(options);
      }

      await queryClient.invalidateQueries(garageOptionsQueryKey);

      await queryClient.refetchQueries([GARAGE_QUERY, GARAGE_HOURS_OPEN_QUERY]);

      toast('success', 'Changes saved successfully!');
    } catch (error) {
      handleError(error, 'Error while saving policies!');
    }
  }, [
    policies,
    setGarageHoursOpenMutation,
    options,
    garageOptionsQuery.data,
    queryClient,
    updateGarageOptionsMutation,
  ]);

  const cancelHandler = React.useCallback(() => {
    setPolicies(garageHoursOpenQuery.data!);
    setOptions(garageOptionsQuery.data!);
  }, [garageHoursOpenQuery.data, garageOptionsQuery.data]);

  const dirty = React.useMemo(() => {
    return !isEqual(options, garageOptionsQuery.data)
    || !isEqual(policies, garageHoursOpenQuery.data);
  }, [garageOptionsQuery.data, garageHoursOpenQuery.data, policies, options]);

  const dataLoading = React.useMemo(() => {
    return setGarageHoursOpenMutation.isLoading
    || updateGarageOptionsMutation.isLoading
    || garageHoursOpenQuery.isRefetching
    || garageOptionsQuery.isRefetching;
  }, [
    garageHoursOpenQuery.isRefetching,
    garageOptionsQuery.isRefetching,
    setGarageHoursOpenMutation.isLoading,
    updateGarageOptionsMutation.isLoading,
  ]);

  const moneySectionHidden = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:hoursAndFees:money') === 'h';
  }, [findCurrentAction]);

  const moneySectionReadOnly = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:hoursAndFees:money') === 'r';
  }, [findCurrentAction]);

  const hoursSectionReadOnly = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:hoursAndFees:hours') === 'r';
  }, [findCurrentAction]);

  const addPolicyHandler = (idx: number, day: GarageDay) => {
    setPolicies((prev) => {
      const unsorted = produce(prev, (draft) => {
        draft!.splice(
          idx + 1,
          0,
          {
            day,
            start: '0000',
            end: '0000',
            cost: 0,
            isClosed: false,
            garageId: currentGarage?.garageId!,
            freeTimeMin: 0,
          },
        );
      });
      return sortPolicyArray(unsorted!);
    });
  };
  const deletePolicyHandler = (idx: number) => {
    setPolicies((prev) => {
      const unsorted = produce(prev, (draft) => {
        delete draft![idx];
      })?.filter((item) => Boolean(item));

      return unsorted!;
    },
    );
  };

  const changeHoursHandler = (
    date: Date | [Date | null, Date | null] | null,
    idx: number,
    timeField: 'start' | 'end',
  ) => {
    if (date instanceof Date){
      setPolicies((prev) => {
        const unsorted = produce(prev, (draft) => {
          draft![idx][timeField] = formatTimeStampToString(date.getTime(), 'HHmm');
        });
        return sortPolicyArray(unsorted!);
      });
    }
  };

  return (
    <>
      <Title>Hours and Fees</Title>
      <Table minWidth="655px">
        <tr>
          <HeadCell />
          <HeadCell style={{ width: 240 }}>Time</HeadCell>
          {!moneySectionHidden && (
          <HeadCell>Hourly Rate</HeadCell>
          )}
          {options?.isMultipleFreePeriods && (<HeadCell>Free period</HeadCell>)}
        </tr>
        {policies?.map(({
          day, start, end, cost, isClosed, id, freeTimeMin,
        }, idx, array) => (
          <tr key={id}>
            <TableCell>{array[idx - 1]?.day === day ? '' : capitalize(day)}</TableCell>
            <TableCell>
              <TimePickerRow>
                <StyledTimePicker
                  pickerProps={{
                    disabled: hoursSectionReadOnly || isClosed,
                    selected: setHoursAndMinutes(start),
                    minTime: z5DateModule.fromUtc(setHoursAndMinutes(calculateMinTimeForStartPeriod(idx, array))
                      .getTime()).getTimezoneTimeAsLocalDate(),
                    maxTime: z5DateModule.fromUtc(setHoursAndMinutes(end === '0000' ? '2359' : end).getTime())
                      .getTimezoneTimeAsLocalDate(),
                    filterTime: (time) => {
                      let ret = true;
                      const formattedTime = formatTimeStampToString(time, 'HHmm');
                      array.filter((policy, i) => day === policy.day && i !== idx)
                        .forEach((policy) => {
                          if (Number(policy.start) <= Number(formattedTime)
                            && Number(formattedTime) < Number(policy.end)
                          ) ret = false;
                        });
                      return ret;
                    },
                    onChange: (date: Date | [Date | null, Date | null] | null) => {
                      changeHoursHandler(date, idx, 'start');
                    },
                  }}
                />
                -
                <StyledTimePicker
                  pickerProps={{
                    disabled: hoursSectionReadOnly || isClosed,
                    selected: setHoursAndMinutes(end),
                    minTime: z5DateModule.fromUtc(setHoursAndMinutes(start).getTime() + 1000 * 60)
                      .getTimezoneTimeAsLocalDate(),
                    maxTime: z5DateModule.fromUtc(setHoursAndMinutes(calculateMaxTimeForEndPeriod(idx, array))
                      .getTime()).getTimezoneTimeAsLocalDate(),
                    injectTimes: [
                      new Date(new Date(new Date().setHours(23)).setMinutes(59)),
                    ],
                    onChange: (date: Date | [Date | null, Date | null] | null) => {
                      changeHoursHandler(date, idx, 'end');
                    },
                  }}
                />
              </TimePickerRow>
            </TableCell>
            {!moneySectionHidden && (
            <TableCell>
              <InlineInputPrice
                value={centsToCost(cost)}
                onChangeValue={(value) => {
                  setPolicies((prev) => produce(prev, (draft) => {
                    draft![idx].cost = costToCents(value);
                  }));
                }}
                disabled={moneySectionReadOnly || isClosed}
              />
            </TableCell>
            )}
            {options?.isMultipleFreePeriods && (<TableCell >
              <FreePeriodSelect 
            options={freePeriodOptions}
            isDisabled={!options?.isFreeTimeMinApplicable}
            menuPortalTarget={document.body}
            value={freePeriodOptions.find((option) => option.value === freeTimeMin)}
            onChange={(option: Option) => {
              console.log(option, 'value option');
              
              setPolicies((prev) => produce(prev, (draft) => {
                draft![idx].freeTimeMin = option.value;
              }));
            }}
            />
              </TableCell>
            )}
            <TableCell>
              <PolicyControlsWrapper>
                <RowControlButtons
                  disabled={moneySectionReadOnly || isClosed}
                  withoutRemove={policies.filter(({ day: policyDay }) => day === policyDay)?.length <= 1}
                  onAdd={() => addPolicyHandler(idx, day)}
                  onRemove={() => deletePolicyHandler(idx)}
                />
                {array[idx - 1]?.day === day ? null : (
                  <TableCheckboxLabel>
                    <Checkbox
                      checked={isClosed}
                      onChange={(event, checked) => {
                        setPolicies((prev) => produce(prev, (draft) => {
                          draft!.forEach((item) => {
                            if (item.day !== day) return;

                            item.isClosed = checked;
                          });
                        }));
                      }}
                      disabled={hoursSectionReadOnly}
                    />
                    Closed
                  </TableCheckboxLabel>
                )}
              </PolicyControlsWrapper>
            </TableCell>
          </tr>
        ))}
        <tr>
          <TableCell>Grace Period</TableCell>
          <TableCell>
            <GracePeriodRow>
              <GracePeriodSelect
                options={GracePeriodOptions}
                placeholder=""
                isDisabled={hoursSectionReadOnly || !options?.isGracePeriodMinApplicable}
                menuPortalTarget={document.body}
                value={
                  !options?.isGracePeriodMinApplicable
                    ? ''
                    : GracePeriodOptions.find((option) => option.value === options?.gracePeriodMin)
                }
                onChange={(option: { label: string; value: number; }) => {
                  setOptions((prev) => produce(prev, (draft) => {
                    draft!.gracePeriodMin = option.value;
                  }));
                }}
              />
                 <LineCheckboxLabel>
                <Checkbox
                  checked={!options?.isGracePeriodMinApplicable}
                  onChange={(e, checked) => {
                    setOptions((prev) => produce(prev, (draft) => {
                      draft!.isGracePeriodMinApplicable = !checked;
                    }));
                  }}
                  disabled={hoursSectionReadOnly}
                />
                Not Applicable
              </LineCheckboxLabel>
            </GracePeriodRow>
          </TableCell>
        </tr>
         <tr>
         <TableCell>Free Period</TableCell>
         <TableCell colSpan={3}>
          <FreePeriodRow>
            <FreePeriodSelect 
            options={freePeriodOptions}
            isDisabled={!options?.isFreeTimeMinApplicable}
            menuPortalTarget={document.body}
            value={
              options !== null
                ? freePeriodOptions.find((option) => option.value === options.freeTimeMin)
                : ''
            }
            onChange={(option: { label: string; value: number; }) => {
              setOptions((prev) => produce(prev, (draft) => {
                draft!.freeTimeMin = option.value;
              }));
            }}
            />
              <LineCheckboxLabel style ={{ marginLeft: '12px' }}>
                <Checkbox
                  checked={!options?.isFreeTimeMinApplicable}
                  onChange={(e, checked) => {
                    setOptions((prev) => produce(prev, (draft) => {
                      draft!.isFreeTimeMinApplicable = !checked;
                    }));
                  }}
                />
                Not Applicable
              </LineCheckboxLabel>
              <LineCheckboxLabel style ={{ marginLeft: '12px' }}>
                <Checkbox
                  checked={options?.isMultipleFreePeriods}
                  onChange={(e, checked) => {
                    setOptions((prev) => produce(prev, (draft) => {
                      draft!.isMultipleFreePeriods = checked;
                    }));
                  }}
                />
                Set multiple Free Periods
              </LineCheckboxLabel>
              </FreePeriodRow>
              </TableCell>
         </tr>
      </Table>
      {!moneySectionHidden && (
        <>
          <Title>Other Fees</Title>
          <PolicyRow>
            <TextInputPrice
              label="Maximum Daily Fee"
              disabled={moneySectionReadOnly || !options?.isMaximumDailyFeeApplicable}
              value={!options?.isMaximumDailyFeeApplicable ? centsToCost(0) : centsToCost(options.maximumDailyFee)}
              onChangeValue={(value, sourceInfo) => {
                if (sourceInfo.source === 'prop') return;

                setOptions((prev) => produce(prev, (draft) => {
                  draft!.maximumDailyFee = costToCents(value);
                }));
              }}
            />
            <LineCheckboxLabel>
              <Checkbox
                checked={!options?.isMaximumDailyFeeApplicable}
                onChange={(e, checked) => {
                  setOptions((prev) => produce(prev, (draft) => {
                    draft!.isMaximumDailyFeeApplicable = !checked;
                  }));
                }}
                disabled={moneySectionReadOnly}
              />
              Not Applicable
            </LineCheckboxLabel>
          </PolicyRow>
          <PolicyRow>
            <ParkingFeeSelect
              label="Event Parking Fee"
              placeholder=""
              options={feeOptions}
              isDisabled={moneySectionReadOnly || !options?.isEventParkingFeeApplicable}
              value={
                !options?.isEventParkingFeeApplicable
                  ? ''
                  : feeOptions.find((option) => option.value === options?.eventParkingFeeType)
              }
              onChange={(option: { label: string; value: EventParkingFeeType; }) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.eventParkingFeeType = option.value;
                }));
              }}
            />
            <TextInputPrice
              value={!options?.isEventParkingFeeApplicable ? centsToCost(0) : centsToCost(options.eventParkingFee)}
              disabled={moneySectionReadOnly || !options?.isEventParkingFeeApplicable}
              onChangeValue={(value) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.eventParkingFee = costToCents(value);
                }));
              }}
            />
            <LineCheckboxLabel>
              <Checkbox
                checked={!options?.isEventParkingFeeApplicable}
                onChange={(e, checked) => {
                  setOptions((prev) => produce(prev, (draft) => {
                    draft!.isEventParkingFeeApplicable = !checked;
                  }));
                }}
                disabled={moneySectionReadOnly}
              />
              Not Applicable
            </LineCheckboxLabel>
          </PolicyRow>
          <InlineWrapper>
            Event parking fee will apply starting
            {' '}
            <HoursSelect
              options={hoursOptions}
              value={hoursOptions.find((option) => parseFloat(option.value) * 60 === options?.eventBeforeGraceMin)}
              onChange={(option) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.eventBeforeGraceMin = parseFloat(option!.value) * 60;
                }));
              }}
              menuPlacement="auto"
              isDisabled={moneySectionReadOnly}
            />
            {' '}
            before the event to
            {' '}
            <HoursSelect
              options={hoursOptions}
              value={hoursOptions.find((option) => parseFloat(option.value) * 60 === options?.eventAfterGraceMin)}
              onChange={(option) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.eventAfterGraceMin = parseFloat(option!.value) * 60;
                }));
              }}
              menuPlacement="auto"
              isDisabled={moneySectionReadOnly}
            />
            {' '}
            after the event.
          </InlineWrapper>
          <Row justifyContent="flex-end">
            {dirty && (
            <CancelButton
              color="primary"
              variant="text"
              onClick={cancelHandler}
              disabled={dataLoading}
            >
              Cancel
            </CancelButton>
            )}
            <Button
              id="parking-policy-save-btn"
              color="primary"
              variant="contained"
              onClick={saveHandler}
              loading={dataLoading}
              disabled={!dirty}
              // data-test="parking-policy-save-btn"
            >
              Save
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

const InlineInputPrice = styled(InputPrice)`
  min-width: 80px;
`;

const StyledTimePicker = styled(TimePicker)`
  width: 150px;
  & input { 
    text-align: center;
  }
`;

const FreePeriodSelect = styled(Select)`
  width: 150px;
`;

const PolicyControlsWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 15px;
`;

const CheckboxLabel = styled.label`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;
`;

const TableCheckboxLabel = styled(CheckboxLabel)`
  grid-column: 3 / 4;
`;

const LineCheckboxLabel = styled(CheckboxLabel)`
  padding: 10px 0;
  white-space: nowrap;
`;

const PolicyRow = styled(Row).attrs({
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  wrap: 'wrap',
})`
  margin-bottom: 20px;
  padding-left: 5px;

  & > * {
    margin: 5px;
  }
`;

const TextInputPrice = styled(InputPrice)`
  min-width: 120px;
`;

const InlineWrapper = styled.div`
  margin-bottom: 20px;
  padding-left: 5px;
  line-height: 40px;

  & > * {
    display: inline-block;
  }
`;

const ParkingFeeSelect = styled(Select)`
  min-width: 150px;
`;

const GracePeriodSelect = styled(Select)`
  margin-right:12px;
  width: 150px;
`;

const HoursSelect = styled(Select)`
  min-width: 150px;
  line-height: 1;
` as typeof Select;

const CancelButton = styled(Button)`
  margin-right: 10px;
`;

const TimePickerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GracePeriodRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FreePeriodRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default HoursAndFees;
