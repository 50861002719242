import debounce from 'lodash/debounce';
import React from 'react';

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
type Handler = (value: string) => void;
type Options = {
  wait: number;
};

const defaultOptions = {
  wait: 400,
};

/**
 * Move to UI package when it is updated to version 7.
 * Ideally update all use cases of debounced input change handler in all app UIs
 * 
 * @param options.wait {Number} The number of milliseconds to delay.
 */
export const useDebouncedInputChangeHandler = (
  handler: Handler,
  options: Options = defaultOptions,
) => {
  const {
    wait,
  } = options;

  const debouncedHandler = React.useMemo(
    () => debounce(handler, wait),
    [handler, wait],
  );

  const debouncedInputChangeHandler = React.useCallback((e: ChangeEvent) => {
    debouncedHandler(e.target.value);
  }, [debouncedHandler]);

  const debouncedTransformedInputChangeHandler  = React.useCallback((_e: ChangeEvent, transformed: string) => {
    debouncedHandler(transformed);
  }, [debouncedHandler]);

  return [debouncedInputChangeHandler, debouncedTransformedInputChangeHandler] as const;
};