import { Dispatch } from 'react';

import {
  CallSession,
  openGate,
  SessionResult,
  SessionResultType,
  updateEntryForActivity,
} from '@zero5/client-admin-api';
import { getSuggestedEntryActivitiesByLicensePlate } from '@zero5/activity-api';
import {
  CallApiProviderInterface,
  CreateActivityArgs,
  ActivityData,
  ActivityPair,
  ApsCallData,
  CalculatePaymentsForActivityTickets,
  EntryActivity,
  PaymentFeatures,
} from '@zero5/call-modal';

import { handleError } from '@/utils/handleError';

import { endCall, handleEndedCall } from '@controlCenter/store/actions/call';
import { mapCallData } from '@controlCenter/api/call/mappings';
import { SessionEndedError, SessionWasNotFound } from '@controlCenter/components/call/CaCallAdapter/errors';

export class CallApiProvider implements CallApiProviderInterface {
  private readonly sessionId: string;
  private readonly dispatch: Dispatch<unknown>;

  constructor(
    sessionId: string,
    dispatch: Dispatch<unknown>,
  ) {
    this.sessionId = sessionId;
    this.dispatch = dispatch;
  }

  handleError(error: unknown): void {
    handleError(error);
  }

  handleEndedCall(): void {
    this.dispatch(handleEndedCall());
  }

  async handleEndCall(requestId: string): Promise<void> {
    this.dispatch(endCall(requestId));
  }

  async setNote(note: string | null): Promise<void> {
    throw new Error('Not Implemented');
  }

  async createEntryActivity(data: CreateActivityArgs): Promise<EntryActivity> {
    const sessionResult = await CallSession.createEntryActivity({
      sessionId: this.sessionId,
      data,
    });

    return CallApiProvider.processSessionResult(sessionResult);
  }

  async getSuggestedEntryActivitiesByLicensePlate(licensePlate: string): Promise<ActivityData[]> {
    return (await getSuggestedEntryActivitiesByLicensePlate({ licensePlate }))
      .activities.map((activity) => activity.data);
  }

  async updateExitActivity(activityId: string, mainLp: string): Promise<ActivityPair> {
    const sessionResult = await CallSession.updateExitActivitySession({
      sessionId: this.sessionId,
      activityId,
      data: { mainLp },
    });

    const result = await CallApiProvider.processSessionResult(sessionResult);

    return {
      entryActivity: result.entryActivity?.data || null,
      exitActivity:  result.exitActivity,
    };
  }

  async updateEntryForActivity(
    exitActivityId: string,
    entryActivityId: string,
    deviceId: string,
    tickets?: CalculatePaymentsForActivityTickets,
    paymentFeatures?: PaymentFeatures,
  ): Promise<ApsCallData> {
    const sessionResult = await updateEntryForActivity({
      sessionId: this.sessionId,
      exitActivityId,
      entryActivityId,
      tickets,
      paymentFeatures,
    });

    const result = CallApiProvider.processSessionResult(sessionResult);
    return mapCallData(result);
  }

  async openGate(deviceId: string): Promise<void> {
    await openGate({ deviceId });
  }

  private static processSessionResult<T>(result: SessionResult<T>): T {
    switch (result.type) {
      case SessionResultType.SESSION_OK:
        return result.payload;

      case SessionResultType.SESSION_ENDED:
        throw new SessionEndedError();

      case SessionResultType.SESSION_NOT_FOUND:
        throw new SessionWasNotFound();
    }
  }
}
