import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InfiniteData, useQueryClient } from 'react-query';
import flow from 'lodash/flow';
import assertNever from 'assert-never';


import { WsConnectionKeeper } from '@zero5/ui-utils';
import {
  ActivityDataWitType,
  ActivityType,
  connectAdmin,
  DeviceActionMessage,
  GateStateType,
  RegularControlCenterActionType,
} from '@zero5/client-admin-api';

import { defaultPerPage, queryKey } from '@/api/queries/useActivitiesQuery';
import user from '@/api/user';
import { mapActivityWithType, mapEntryActivityToActivityData } from '@/api/mappings/activity';
import { Activity } from '@/api/models/activities';
import { eventQueryKeys } from '@/api/event/queryKeys';

import { activitiesActions } from '@/store/actions/activities';
import { deviceActions } from '@/store/actions/device';

import { useFindCurrentAction } from '@/components/common/Role';

import { withAuth } from '@/utils/hocs/withAuth';
import { withAnyRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { loadImage } from '@/utils/image';

import { DEV_GARAGE_ID } from '@/devConstants';
import { mapUpdatedIssueActivity } from '@/controlCenter/api/activity/mappings';
import { callActions, endCall } from '@/controlCenter/store/actions/call';
import { mapCall } from '@/controlCenter/api/call/mappings';

function getAccessToken(): Promise<string> {
  return user.getAccessToken();
}

const WebSocket: React.FC = () => {
  const findCurrentAction = useFindCurrentAction();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const putActivityToQueue = (activityDataWitType: ActivityDataWitType) => {
    const socketActivity: Activity = mapActivityWithType(activityDataWitType);
    if (socketActivity.frontImg) {
      loadImage(socketActivity.frontImg);
    }
    if (socketActivity.rearImg) {
      loadImage(socketActivity.rearImg);
    }
    dispatch(activitiesActions.addNewActivitiesToQueue(socketActivity));
  };

  const updateActivityInCache = (activityDataWitType: ActivityDataWitType)=>{
    queryClient.setQueryData<InfiniteData<Activity[]> | undefined>([queryKey, defaultPerPage], (prev) => {
      if (prev){
        for (let index = 0; index < prev.pages.length; index++) {
          const activityList = prev.pages[index];
          const arrIndex = activityList.findIndex((el => el.activityId === activityDataWitType.data.activityId));
          prev.pages[index][arrIndex] = {
            ...activityDataWitType.data,
            type: activityDataWitType.type,
          };
          return prev;
        }
      }

      return prev;
    });
  };

  const updateGateState = (msg: DeviceActionMessage) => {
    const { deviceId, isLocked, status } = msg.payload;
    if (status === GateStateType.DOWN) {
      dispatch(deviceActions.setGateControlInDeviceList(true, deviceId, isLocked));
    }
    if (status === GateStateType.UP) {
      dispatch(deviceActions.setGateControlInDeviceList(false, deviceId, isLocked));
    }
    if (status === GateStateType.ERROR) {
      dispatch(deviceActions.setErrorInDevice(deviceId));
    }
  };

  useEffect(() => {
    const wsConnectionKeeper = new WsConnectionKeeper(
      connectAdmin,
    );
    wsConnectionKeeper.openConnection(
      getAccessToken,
      DEV_GARAGE_ID,
      (msg) => {
        switch (msg.type) {
          case 'ActivityActionMessage':
            putActivityToQueue(msg.payload);
            break;

          case 'EntryActivityCreatedActionMessage':
            putActivityToQueue({ data: msg.payload.entryActivity.data, type: ActivityType.IN });
            break;

          case 'ExitActivityCreatedActionMessage':
            putActivityToQueue({ data: msg.payload.activity.exitActivity, type:ActivityType.OUT });
            break;

          case 'EntryIssueActivityCreatedActionMessage':
            const issueActivity = mapUpdatedIssueActivity(msg.payload.issueActivity);
            dispatch(activitiesActions.addIssueActivitiesToQueue(issueActivity));
            break;

          case 'ActivityUpdatedActionMessage':
            updateActivityInCache(msg.payload);
            break;

          case 'EntryActivityUpdatedActionMessage':
            updateActivityInCache({ 
              data: mapEntryActivityToActivityData(msg.payload.entryActivity),
              type: ActivityType.IN,
            });
            break;

          case 'ExitActivityUpdatedActionMessage':
            updateActivityInCache({ data: msg.payload.activity.exitActivity, type: ActivityType.OUT });
            break;

          case 'ExitActivityResolvedActionMessage':
            updateActivityInCache({ data: msg.payload.activity.exitActivity, type: ActivityType.OUT });
            break;

          case 'DeviceActionMessage':
            updateGateState(msg);
            break;

          case 'EventUpdatedActionMessage': case 'EventCreatedActionMessage':
            queryClient.refetchQueries(eventQueryKeys.base);
            break;

          case RegularControlCenterActionType.SESSION_CREATED_ACTION_MESSAGE:
            dispatch(callActions.addCalls(mapCall(msg.payload.sessionId, msg.payload.sessionPayload)));
            break;

          case RegularControlCenterActionType.SESSION_ANSWERED_ACTION_MESSAGE:
          case RegularControlCenterActionType.SESSION_ENDED_ACTION_MESSAGE: {
            const requestId = msg.payload.sessionId;
            if (requestId){
              dispatch(callActions.removeCall(requestId));
              if (msg.type === RegularControlCenterActionType.SESSION_ENDED_ACTION_MESSAGE) {
                dispatch(endCall(requestId));
              }
            }
          }
            break;

          //TODO ENG-2149
          case 'RefreshGarageInfoActionMessage':
          case 'RefreshPermitsActionMessage':
          case 'UserDeletedActionMessage':
            break;

          default:
            assertNever(msg);
        }
      },
    );

    return () => wsConnectionKeeper.closeConnection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findCurrentAction, dispatch, queryClient]);

  return null;
};

export default flow(
  withAnyRole([
    'home',
    'parkingSettings',
    'controlCenter',
    'event',
  ]),
  withAuth(null),
  withLoading(null),
)(WebSocket);
