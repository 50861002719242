import React from 'react';
import styled from 'styled-components';

import PenIcon from '@/assets/icons/pen.inline.svg';

import ModalTitle from './ModalTitle';

interface Props {
  onClick: () => void;
  children: string;
}

/**
 * Move to UI package when it is updated to version 7
 */
const ModalTitleButton: React.FC<Props> = ({ onClick, children }) => {
  return (
    <StyledModalTitleButton
      as="button"
      onClick={onClick}
    >
      {children}
      <StyledPenIcon />
    </StyledModalTitleButton>
  );
};

const StyledModalTitleButton = styled(ModalTitle)`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;
`;

const StyledPenIcon = styled(PenIcon)`
  margin-left: 15px;
`;

export default ModalTitleButton;