import React, { Suspense } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';

import { Button, Card, Error, LoadingIndicator, Radio, Row } from '@zero5/ui';
import schemas from '@zero5/ui/lib/utils/validation/schemas';

import useWatchlistSettingsQuery from '@/api/watchlist/useWatchlistSettingsQuery';
import useWatchlistContactsQuery from '@/api/watchlist/useWatchlistContactsQuery';
import useSetWatchlistSettingsMutation from '@/api/watchlist/useSetWatchlistSettingsMutation';
import useSetWatchlistContactsMutation from '@/api/watchlist/useSetWatchlistContactsMutation';

import RowControlButtons from '../common/RowControlButtons';

import ContactInput from './ContactInput';
import { Contact } from './types';

const initialContact: Contact = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
  userRole: '',
};

const validationSchema = Yup.object().shape({
  gateBehavior: Yup.string().required().label('System Behavior'),
  contact: Yup.array().of(Yup.object().shape({
    email: schemas.emailSchema.test('is-user-found', 'User not found', (_value, context) => Boolean(context.parent.id)),
  })),
});

const SettingsContentLoader = () => {
  useWatchlistSettingsQuery();
  useWatchlistContactsQuery();

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <SettingsContent />
    </Suspense>
  );
};

const SettingsContent: React.FC = ({}) => {
  const watchlistSettingsQuery = useWatchlistSettingsQuery({
    suspense: true,
  });
  const watchlistContactsQuery = useWatchlistContactsQuery({
    suspense: true,
  });

  const setWatchlistSettingsMutation = useSetWatchlistSettingsMutation();
  const setWatchlistContactsMutation = useSetWatchlistContactsMutation();

  const formik = useFormik({
    initialValues: {
      gateBehavior: watchlistSettingsQuery.data?.settings.gateBehavior,
      contact: watchlistContactsQuery.data?.users.length ? watchlistContactsQuery.data.users.map((user) => ({
        id: user.userId,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        userRole: user.role?.label || '',
      })) : [initialContact],
    },
    onSubmit: async (values) => {
      const promises = [
        setWatchlistSettingsMutation.mutateAsync({
          gateBehavior: values.gateBehavior!,
        }),
        setWatchlistContactsMutation.mutateAsync({
          userIds: values.contact.filter(({ id }) => Boolean(id)).map(({ id }) => id!),
        }),
      ];

      await Promise.all(promises);
    },
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <SubPageTitle>Settings</SubPageTitle>
        <Section>
          <SectionTitle>System Behavior for watchlist-ed Vehicles</SectionTitle>
          <RadioWrapper>
            <Row justifyContent="flex-start" gap="20px">
              <RadioLabel>
                <Radio
                  name="gateBehavior"
                  value="DO_NOT_OPEN"
                  onChange={formik.handleChange}
                  checked={formik.values.gateBehavior === 'DO_NOT_OPEN'}
                />
                <LabelText>Do not open gates at entries</LabelText>
              </RadioLabel>
              <RadioLabel>
                <Radio
                  name="gateBehavior"
                  value="OPEN_AND_NOTIFY"
                  onChange={formik.handleChange}
                  checked={formik.values.gateBehavior === 'OPEN_AND_NOTIFY'}
                />
                <LabelText>Open gates at entries and alert the team</LabelText>
              </RadioLabel>
            </Row>
            {formik.touched.gateBehavior && (
              <Error>{formik.errors.gateBehavior}</Error>
            )}
          </RadioWrapper>
        </Section>
        <SectionTitle>Contact</SectionTitle>
        <SectionSubtitle>
          Contact needs to be a registered user to zero5 portal
        </SectionSubtitle>
        <ContactsGrid>
          {formik.values.contact.map((contact, idx) => (
            <RowGrid key={`${contact.id}-${idx}`} >
              <ContactInput
                contact={formik.values.contact[idx]}
                onChange={(newContact) => formik.setValues((prev) => ({
                  ...prev,
                  contact: [...prev.contact.slice(0, idx), newContact, ...prev.contact.slice(idx + 1)],
                }))}
                emailError={
                  (get(formik.touched, `contact[${idx}].email`) &&
                  get(formik.errors, `contact[${idx}].email`)) as string | undefined
                }
              />
              <StyledRowControlButtons
                onAdd={
                  () => formik.setValues((prev) => ({
                    ...prev,
                    contact: [...prev.contact, initialContact],
                  }))
                }
                onRemove={
                  () => formik.setValues((prev) => ({
                    ...prev,
                    contact: [...prev.contact.slice(0, idx), ...prev.contact.slice(idx + 1)],
                  }))
                }
                withoutRemove={formik.values.contact.length <= 1}
              />
            </RowGrid>
          ))}
        </ContactsGrid>
        <Row justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
            type="submit"
          >
            Save
          </Button>
        </Row>
      </form>
    </Card>
  );
};

const SubPageTitle = styled.h2`
  margin: 0;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

const SectionSubtitle = styled.p`
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
`;

const RadioLabel = styled.label`
  white-space: nowrap;
`;

const LabelText = styled.span`
  margin-left: 10px;
  vertical-align: middle;
`;

const RadioWrapper = styled.div`
  position: relative;
`;

const ContactsGrid = styled.div`
  display: grid;
  grid-gap: 20px;

  padding: 3px; // fixed inputs outline cutting
  padding-bottom: 14px; // fixed inputs error cutting
  margin-bottom: 50px;

  overflow-x: auto;
`;

const RowGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr auto;
  grid-gap: 10px;
  min-width: 760px;
`;

const StyledRowControlButtons = styled(RowControlButtons)`
  margin-top: auto;
  margin-bottom: 10px;
`;

export default SettingsContentLoader;