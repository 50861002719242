import {
  CountValidationsThisMonthResponseType,
  GetValidationTypeCompanyLimitResponseType,
  GetValidationTypeCompanyLimitsResponseType,
} from '@zero5/garage-api';

export const mapValidationTypeCompanyLimit = (
  response: GetValidationTypeCompanyLimitResponseType['limit'],
) => ({
  validationTypeGroupId: response.validationType.validationTypeGroupId,
  monthlyLimit: response.monthlyLimit,
  validationCount: response.validationCount,
});

export const mapValidationTypeCompanyLimits = (response: GetValidationTypeCompanyLimitsResponseType['limits']) =>
  response.map(mapValidationTypeCompanyLimit);

export const mapCountValidationsThisMonth = (
  response: CountValidationsThisMonthResponseType['validations'],
) => response.map((item) => ({
  validationTypeGroupId: item.validationTypeGroupId,
  validationsCount: item.validationsCount,
}));
