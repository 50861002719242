import React from 'react';
import styled from 'styled-components';

import {
  Button,
  Row,
} from '@zero5/ui';

import SummarySection from './SummarySection';
import DetailsSection from './DetailsSection';
import AccountNameSection from './AccountNameSection';
import ExportValidationHistorySection from './ExportValidationHistorySection';

interface Props {
  data: {
    companyId: number;
    fromDate: number;
    toDate: number;
  };
  onClose: () => void;
}

const AccountValidationHistoryModal: React.FC<Props> = ({
  data,
  onClose,
}) => {
  return (
    <Wrapper>
      <RowWithOffset>
        <TopLevelTitle>Validation Detail</TopLevelTitle>
        <ExportValidationHistorySection
          companyId={data.companyId}
          fromDate={data.fromDate}
          toDate={data.toDate}
        />
      </RowWithOffset>

      <AccountNameSection
        companyId={data.companyId}
        fromDate={data.fromDate}
      />

      <SummarySection
        companyId={data.companyId}
        fromDate={data.fromDate}
        toDate={data.toDate}
      />

      <Row>
        <TopLevelTitle>Details</TopLevelTitle>
      </Row>

      <DetailsSection
        companyId={data.companyId}
        fromDate={data.fromDate}
        toDate={data.toDate}
      />

      <ButtonRow justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={onClose}>Close</Button>
      </ButtonRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopLevelTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const RowWithOffset = styled(Row)`
  margin-bottom: 20px;
`;

const ButtonRow = styled(Row)`
  margin-top: auto;
`;

export default AccountValidationHistoryModal;
