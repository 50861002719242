/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InfiniteData, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';
import { Redirect } from 'react-router';
import { Tab, Tabs } from '@material-ui/core';
import { useFormik } from 'formik';

import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { DateRange } from '@zero5/ui/lib/Chart';
import { CategoryCard, LoadingIndicator, ButtonIcon, BigCategoryCard, LineChart } from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import { Activity, GatewayType, SearchFormikState } from '@/api/models/activities';
import useActivitiesQuery, {
  defaultPerPage,
  queryKey,
} from '@/api/queries/useActivitiesQuery';
import useGetGarageQuery from '@/api/garage/useGetGarageQuery';
import useGetActivitiesAggregatedCount from '@/api/queries/useGetActivitiesAggregatedCount';
import useTotalRevenueTodayQuery from '@/api/revenue/useTotalRevenueTodayQuery';
import useSearchedActivitiesQuery from '@/api/activity/useSearchedActivitiesQuery';

import { selectHomeSettings } from '@/store/selectors/settings';
import { settingsActions } from '@/store/actions/settings';
import { selectNewActivitiesQueue } from '@/store/selectors/activities';
import { activitiesActions } from '@/store/actions/activities';

import Page from '@/components/common/page';
import ActivityInformationModal from '@/components/modals/ActivityInformationModal';
import Role, { useFindCurrentAction } from '@/components/common/Role';
import ActivityList from '@/components/activities/ActivityList';
import ActivitySearchRow from '@/components/activities/ActivitySearchRow';
import CenterTypography from '@/components/common/CenterTypography';
import RangePicker from '@/components/common/RangePicker';
import RevenueTrendChart from '@/components/revenue/RevenueTrend';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

import CloseIcon from '@/assets/close.inline.svg';

interface Props {}

enum TabsEnum {
  LATEST_ACTIVITIES,
  SEARCHED_ACTIVITIES,
}

const initialValues: SearchFormikState = {
  mainLp: undefined,
  gatewayType: GatewayType.both,
  startDate: new Date(),
  endDate: new Date(),
  location: '',
  startTime: null,
  endTime: null,
};

const Home: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [tab, setTab] = useState<TabsEnum>(TabsEnum.LATEST_ACTIVITIES);
  
  const formik = useFormik<SearchFormikState>({
    initialValues,
    onSubmit: async () => {
      setTab(TabsEnum.SEARCHED_ACTIVITIES);
      remove();
      refetch();
    },
  });

  const findCurrentAction = useFindCurrentAction();

  const [vehicleInformation, setVehicleInformation] = React.useState<Activity | null>(null);
  // eslint-disable-next-line max-len
  const [isActivityInformationModalOpen, closeActivityInformationModal, openActivityInformationModal] = useToggle(false);
  const [isNewActivitiesBoxShow, closeNewActivitiesBox] = useToggle(true);
  const { revenueDateRange, trafficDateRange } = useSelector(selectHomeSettings);

  const {
    data: TrafficTrendData,
    isLoading: trafficTrendIsLoading,
    mutateAsync: activityMutateAsync,
  } = useGetActivitiesAggregatedCount();

  useEffect(() => {
    if (trafficDateRange
      && trafficDateRange.start
      && trafficDateRange.end
      && findCurrentAction('home:trends') !== 'h'
      && !isGarageLoading) {
      activityMutateAsync(trafficDateRange);
    }
  }, [trafficDateRange]);

  const revenueDateRangeChangeHandler = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setHomeSettings({
      revenueDateRange: newDateRange,
    }));
  }, [dispatch]);

  const trafficDateRangeChangeHandler = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setHomeSettings({
      trafficDateRange: newDateRange,
    }));
  }, [dispatch]);

  // TODO: rework loading indication...
  const {
    data: activitiesData,
    isLoading: isActivitiesLoading,
    fetchNextPage: fetchNextActivities,
    hasNextPage: hasMoreActivities,
    isFetchingNextPage: isFetchingNextActivities,
  } = useActivitiesQuery();

  const {  
    data: searchedActivtities,
    refetch, 
    remove,
    isFetching: isSearchLoading,
    fetchNextPage: fetchNextSearchedActivtiesActivities,
    hasNextPage: hasMoreSearchedActivities,
    isFetchingNextPage: isFetchingNextSearchedActivities,
  } = useSearchedActivitiesQuery(formik.values, { enabled: false });

  const { data: garageData, isLoading: isGarageLoading } = useGetGarageQuery();
  const { data: todayRevenue, isLoading: todayRevenueLoading } = useTotalRevenueTodayQuery();
  const newActivities = useSelector(selectNewActivitiesQueue);

  const onShowNewActivities = () => {
    queryClient.setQueryData<InfiniteData<Activity[]>>([queryKey, defaultPerPage], (prev) => {
      if (prev) {
        prev.pages[0].unshift(...newActivities);
      }
      return prev!;
    });
    dispatch(activitiesActions.clearNewActivitiesQueue());
  };

  // TODO: attribute "changes" need to be calculated depending on their title
  const cardsData = [
    {
      title: 'Total Spots',
      data: garageData ? garageData.capacity : emptyFieldStub,
      subtext: {
        href: '/guidance-map',
        label: 'View map',
      },
      widgetId: 'dashboard:card:totalSpots',
    },
    {
      title: 'Open Spots',
      data: emptyFieldStub,
      widgetId: 'dashboard:card:openSpots',
    },
    {
      title: 'Today’s Revenue',
      data: formatPrice(todayRevenue || 0),
      // changes: 0.0,
      subtext: {
        href: '/revenue',
        label: 'See breakdown',
      },
      widgetId: 'dashboard:card:todaysRevenue',
    },
    // {
    //   title: 'Notifications',
    //   data: '-',
    //   changes: 0.0,
    //   subtext: {
    //     href: '',
    //     label: 'View details',
    //   },
    // },
  ];

  const trafficValueArray = TrafficTrendData?.values || [];
  const trafficDateArray = TrafficTrendData?.dates || [];

  return (
    <Page>
      <Role widgetId="home:stats" action="r">
        <CardsWrapper>
          {cardsData.map(({ widgetId, ...props }) => (
            <Role widgetId={widgetId} action="r">
              <CategoryCard key={props.title} {...props} loading={isGarageLoading || todayRevenueLoading} />
            </Role>
          ))}
        </CardsWrapper>
      </Role>
      <Role widgetId="home:trends" action="r">
        <ChartsWrapper>
          <RevenueTrendChart
            dateRange={revenueDateRange}
            onDateRangeChange={revenueDateRangeChangeHandler}
          />
          <BigCategoryCard
            title="Traffic Trend"
            gap="10px"
            headerContent={
              <RangePicker
                dateRange={trafficDateRange}
                onChange={trafficDateRangeChangeHandler}
                hideShortcutsSize="1450px"
              />
            }
            data-test="home-traffic-trend"
          >
            <LineChart
              data={trafficValueArray}
              labels={trafficDateArray}
              isLoading={trafficTrendIsLoading}
            />
          </BigCategoryCard>
        </ChartsWrapper>
      </Role>
      <ActivitySearchRow formik={formik} isLoading={isSearchLoading} />
      <Role widgetId="home:activities" action="r">
        <>
          <StyledTabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(ignoredEvent, newValue) => setTab(newValue)}
          >
            <Tab label="Recent Activities" />
            <Tab label="Search Result" />
          </StyledTabs>
          {tab === TabsEnum.LATEST_ACTIVITIES && (
            <ListWrapper>
              {isActivitiesLoading && <LoadingIndicator />}
              {activitiesData && (
                <ActivityList
                  activities={activitiesData.pages.flat()}
                  onActivitySelect={(activity) => {
                    setVehicleInformation(activity);
                    openActivityInformationModal();
                  }}
                  hasMoreActivities={hasMoreActivities}
                  onLoadMore={() => fetchNextActivities()}
                  isLoading={isFetchingNextActivities}
                />
              )}
              <CenterTypography
                isLoading={isActivitiesLoading}
                data={activitiesData?.pages.flat()}
                text={'No data to show'}
              />
            </ListWrapper>
          )}
          {tab === TabsEnum.SEARCHED_ACTIVITIES && (
            <ListWrapper>
              {(isSearchLoading && !isFetchingNextSearchedActivities)  && <LoadingIndicator />}
              {searchedActivtities  && !(isSearchLoading && !isFetchingNextSearchedActivities) && (
                <ActivityList
                  activities={searchedActivtities.pages.flat()}
                  onActivitySelect={(activity) => {
                    setVehicleInformation(activity);
                    openActivityInformationModal();
                  }}
                  hasMoreActivities={hasMoreSearchedActivities}
                  isLoading={isFetchingNextSearchedActivities}
                  onLoadMore={() => fetchNextSearchedActivtiesActivities()}
                />
              )}
              <CenterTypography
                isLoading={ isSearchLoading }
                data={searchedActivtities?.pages.flat()}
                text={'No data to show'}
              />
            </ListWrapper>
          )}
          <ActivityInformationModal
            activity={vehicleInformation!}
            open={isActivityInformationModalOpen}
            onClose={closeActivityInformationModal}
          />
        </>
      </Role>
      {isNewActivitiesBoxShow && Boolean(newActivities.length) && (
        <NewActivitiesBox>
          <a onClick={onShowNewActivities}>Load New Activities</a>
          <StyledButtonIcon
            Icon={CloseIcon}
            onClick={closeNewActivitiesBox}
            data-test="closeButtonIcon"
          />
        </NewActivitiesBox>
      )}
    </Page>
  );
};

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;
`;

const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 30px / 2));
  grid-gap: 30px;
  margin-bottom: 35px;
  min-height: 400px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }

  @media (max-width: 520px) {
    min-height: 300px;
  }
`;


const NewActivitiesBox = styled.div`
  position: fixed;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  left: 0;
  right: 0;
  top: 60px;
  margin: auto;
  width: 165px;
  padding: 12px 12px 13px 13px;
  background: #cbf4c9;
  border-radius: 3px;
  z-index: 1000;
  color: #0e6245;

  & > a {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ListWrapper = styled.div`
  min-height: 200px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  width: auto;
  height: 16px;
  margin: 0 0 0 auto;
  & svg {
    fill: #0e6245;
  }
`;

export default flow(
  withGarage,
  // Redirect to CC. Remove this when CC will be separated
  withRole('home', <Redirect to="/control-center/entry-activities-list" />),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(Home);
