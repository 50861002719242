import styled from 'styled-components';

/**
 * Remove when UI package is updated to version 7
 */
const ModalTitle = styled.h4`
  font-size: 18px;
  line-height: 1.2;
  font-family: inherit;
  font-weight: 700;
  white-space: nowrap;
  margin: 0;
`;

export default ModalTitle;
