import React from 'react';

import { TenantTypeName } from '@/api/permit/models';

import StatusRectangle from '@/components/common/StatusRectangle';

interface Props {
  type: TenantTypeName;
}

const mapAccountTypeToStatus: Record<TenantTypeName, { color: string; label: string; }> = {
  Management: {
    color: '#CBE2D1',
    label: 'Management',
  },
  Restaurant: {
    color: '#F6D9CB',
    label: 'Restaurant',
  },
  Security: {
    color: '#F6DFE8',
    label: 'Security',
  },
  Entertainment: {
    color: '#D0D0E8',
    label: 'Entertainment',
  },
  Corporate: {
    color: '#F0F0F0',
    label: 'Corporate',
  },
  Retail: {
    color: '#B6C4FE',
    label: 'Retail',
  },
  Public: {
    color: '#F7EEC9',
    label: 'Public',
  },
  Hotel: {
    color: '#D3ECF0',
    label: 'Hotel',
  },
};

const AccountType: React.FC<Props> = ({ type }) => {
  const statusProps = mapAccountTypeToStatus[type];

  return (
    <StatusRectangle color={statusProps.color} label={statusProps.label} />
  );
};

export default AccountType;