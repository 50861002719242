import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import throttle from 'lodash/throttle';
import { useStates } from 'react-us-states';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Input,
  InputPhone,
  Modal,
  ModalProps,
  Row,
  Select,
  Link,
} from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';
import { createDriver, findDriverByEmail } from '@zero5/user-api';
import schemas from '@zero5/ui/lib/utils/validation/schemas';
import { PermitPaymentType } from '@zero5/permit-api';
import formatLicensePlate from '@zero5/ui/lib/utils/formatters/formatLicensePlate';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useDriverByEmailQuery from '@/api/queries/useDriverByEmail';
import useVehicleByLpNumQuery from '@/api/queries/useVehicleByLpNum';
import useCreatePermitMutation from '@/api/permit/useCreatePermitMutation';
import useGetRemainingPermitsQuery from '@/api/permit/useGetRemainingPermitsQuery';
import usePermitTypeListQuery from '@/api/permitType/useGetPermitTypeListQuery';

import InputLoading from '@/components/common/InputLoading';
import Switch from '@/components/Switch';
import DatePicker from '@/components/common/DatePicker';

import { handleError } from '@/utils/handleError';
import useDateModule from '@/utils/date/useDateModule';
import { cyrillicPattern } from '@/utils/regex';

import CalendarIcon from '@/assets/icons/calendar_icon.svg';

interface Props extends Omit<ModalProps, 'children'> {}

const PermitAddModal: React.FC<Props> = ({ onClose, ...props }) => {
  return (
    <StyledModal title="New Permit" fullScreenSize="580px" onClose={onClose} {...props}>
      <Content onClose={onClose} />
    </StyledModal>
  );
};

type ContentProps = Pick<Props, 'onClose'>;

const validationSchema = Yup.object().shape({
  vehicleMake: Yup.string()
    .label('Vehicle Make')
    .required(),
  licensePlate: Yup.string()
    .max(15, 'Too Long!')
    .label('License Plate')
    .required(),
  state: schemas.nameSchema
    .label('State'),
  vehicleModel: Yup.string()
    .max(15, 'Too Long!')
    .label('Vehicle Model')
    .required(),
  vehicleColor: Yup.string()
    .max(15, 'Too Long!')
    .label('Vehicle Color')
    .required(),
  firstName: schemas.nameSchema
    .label('First Name'),
  lastName: schemas.nameSchema
    .label('Last Name'),
  email: schemas.emailSchema,
  permitType: Yup.string()
    .label('Permit Type')
    .required(),
  startDate: schemas.dateSchema
    .label('Start Date')
    .required(),
  phone: schemas.phoneSchema,
});

const NoOptionsMessage: React.FC = () => {
  const history = useHistory();
  const onNewPermitType = useCallback(() => {
    history.push('/parking-policy');
    setTimeout(() => {
      document.getElementById('permitPolicyTab')?.click();
      document.getElementById('newPermitTypeButton')?.click();
    }, 0);
  }, [history]);
  return (
    <StyledNoOptionsMessage>
      No existing permit types. Create it <Link onClick={onNewPermitType}>here</Link>
    </StyledNoOptionsMessage>
  );
};

const Content: React.FC<ContentProps> = ({ onClose }) => {
  const createPermitMutation = useCreatePermitMutation({
    onSuccess: () => onClose(),
  });

  const { convertDateToTimezoneUtc } = useDateModule();

  const states = useStates();

  const permitTypes = usePermitTypeListQuery();

  const { data: remainingPermits } = useGetRemainingPermitsQuery();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      sendLink: false,
      permitType: '',
      startDate: new Date(),
      licensePlate: '',
      state: '',
      vehicleMake: '',
      vehicleModel: '',
      vehicleColor: '',
      isAutoRenewEnabled: false,
    },
    onSubmit: async (values) => {
      try {
        let driverId;

        if (values.email) {
          const { driver } = await findDriverByEmail(values.email);
          if (driver) {
            driverId = driver.driverId;
          } else {
            const { driver: newDriver } = await createDriver(values);
            driverId = newDriver.driverId;
          }
        }
        
        await createPermitMutation.mutateAsync({
          permitType: values.permitType,
          paymentType: PermitPaymentType.INVOICE,
          startTime: convertDateToTimezoneUtc(values.startDate),
          driverId,
          lpNum: values.licensePlate,
          lpState: values.state,
          vehicleColor: values.vehicleColor,
          vehicleMake: values.vehicleMake,
          vehicleModel: values.vehicleModel,
          isAutoRenewEnabled: values.isAutoRenewEnabled,
        });
      } catch (error) {
        handleError(error);
      }
    },
    validationSchema,
  });
  const transformedInputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {      
      if (!cyrillicPattern.test(value)){
        formik.setFieldValue(e.target.name, value);
      }
    }, [formik],
  );

  const {
    data: vehicleData,
    isLoading: isVehicleLoading,
    refetch: vehicleRefetch,
    isSuccess: isVehicleSuccess,
  } = useVehicleByLpNumQuery(
    formik.values.licensePlate, { enabled: false },
  );

  const isVehicleFieldEnabled = useMemo(
    () => isVehicleLoading || !isVehicleSuccess || (isVehicleSuccess && !!vehicleData?.vehicle),
    [isVehicleLoading, isVehicleSuccess, vehicleData],
  );

  const {
    data: driverData,
    isLoading: isDriverLoading,
    refetch: driverRefetch,
    isSuccess: isDriverSuccess,
  } = useDriverByEmailQuery(
    formik.values.email, { enabled: false },
  );
  const isDriverFieldDisabled = useMemo(
    () => isDriverLoading || !isDriverSuccess || (isDriverSuccess && !!driverData?.driver),
    [isDriverLoading, isDriverSuccess, driverData],
  );

  const throttleVehicle = throttle(vehicleRefetch, 1000);

  useEffect(() => {
    if (!formik.errors.email && formik.values.email.length > 1) {
      driverRefetch();
    }
  }, [formik.values.email, formik.errors.email]);

  useEffect(() => {
    if (!formik.errors.licensePlate && formik.values.licensePlate.length > 1) {
      throttleVehicle();
    }
  }, [formik.values.licensePlate, formik.errors.licensePlate]);

  useEffect(() => {
    if (driverData) {
      formik.setValues((values) => ({
        ...values,
        firstName: driverData.driver?.firstName ?? '',
        lastName: driverData.driver?.lastName ?? '',
        phone: driverData.driver?.phoneNumber ?? '',
        driverId: driverData.driver?.driverId ?? '',
      }));
    }
  }, [driverData]);

  useEffect(() => {
    if (vehicleData) {
      formik.setValues((values) => ({
        ...values,
        vehicleColor: vehicleData.vehicle?.color ?? '',
        vehicleMake: vehicleData.vehicle?.make ?? '',
        vehicleModel: vehicleData.vehicle?.model ?? '',
        state: vehicleData.vehicle?.lpState ?? '',
      }));
    }
  }, [vehicleData]);

  const getPermitPriceHandler = () => {
    if (permitTypes.data && formik.values.permitType) {
      const response = permitTypes.data
        .find((permitType) => permitType.name === formik.values.permitType);
      return formatPrice(response?.price || 0);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <ContentWrapper data-test="create-permit-modal">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <SectionTitle>
            Permit Information
          </SectionTitle>
          <Grid>
            <StyledSelect
              columns={6}
              label="Permit Type"
              testDataId="create-permit-modal-type"
              isDisabled={formik.values.sendLink}
              error={Boolean(formik.touched.permitType && formik.errors.permitType)}
              helperText={formik.errors.permitType}
              options={permitTypes.data?.map((x) => ({
                label: x.name,
                value: x.name,
              }))}
              onChange={(x) => {
                formik.setFieldValue('permitType', x?.value);
              }}
              isLoading={permitTypes.isLoading}
              components={{
                NoOptionsMessage,
              }}
            />
            <StyledDatePicker
              columns={6}
              pickerProps={{
                disabled: formik.values.sendLink,
                placeholderText: 'mm/dd/yy',
                minDate: new Date(),
                selected: formik.values.startDate,
                onChange: (selectedDate: Date) => {
                  formik.setFieldValue('startDate', selectedDate);
                },
              }}
              label="Start Date"
              error={Boolean(formik.touched.startDate && formik.errors.startDate)}
              helperText={formik.errors.startDate as string}
              endAdornment={<IconInput src={CalendarIcon} alt="" />}
            />
            <SwitchWrapper columns={6} data-test="create-permit-modal-auto-renew">
              <Switch
                label="Auto Renew"
                value={formik.values.isAutoRenewEnabled}
                onClick={() => {
                  formik.setFieldValue('isAutoRenewEnabled', !formik.values.isAutoRenewEnabled);
                }}
              />
            </SwitchWrapper>
            <PriceWrapper columns={6} isDisplay={Boolean(formik.values.permitType)}>
              <PriceLabel>Price:</PriceLabel>
              <PriceValue data-test="create-permit-modal-price">
                {getPermitPriceHandler()}
              </PriceValue>
            </PriceWrapper>
          </Grid>
          <SectionTitle>
            Vehicle Information
          </SectionTitle>
          <Grid>
            <StyledInput
              columns={6}
              label="License Plate Number"
              disabled={formik.values.sendLink || (permitTypes.data && Boolean(permitTypes.data.length === 0))}
              value={formik.values.licensePlate}
              name="licensePlate"
              id="licensePlate"
              error={Boolean(formik.touched.licensePlate && formik.errors.licensePlate)}
              helperText={formik.errors.licensePlate}
              onChange={(e, value) => {
                transformedInputChangeHandler(e, formatLicensePlate(value));
              }}
              endAdornment={(<InputLoading isLoading={isVehicleLoading} />)}
              transform="uppercase"
            />
            <StyledSelect
              columns={6}
              label="State"
              name="state"
              id="state"
              isDisabled={isVehicleFieldEnabled}
              error={Boolean(formik.touched.state && formik.errors.state)}
              helperText={formik.errors.state}
              onChange={(x) => {
                formik.setFieldValue('state', x?.value);
              }}
              value={{
                label: formik.values.state,
                value: formik.values.state,
              }}
              options={states.map((x) => ({
                label: x.abbreviation,
                value: x.abbreviation,
              }))}
            />
            <StyledInput
              columns={4}
              label="Vehicle Make"
              disabled={isVehicleFieldEnabled}
              value={formik.values.vehicleMake}
              name="vehicleMake"
              id="vehicleMake"
              error={Boolean(formik.touched.vehicleMake && formik.errors.vehicleMake)}
              helperText={formik.errors.vehicleMake}
              onChange={transformedInputChangeHandler}
              transform="uppercase"
            />
            <StyledInput
              columns={4}
              label="Vehicle Model"
              name="vehicleModel"
              value={formik.values.vehicleModel}
              id="vehicleModel"
              disabled={isVehicleFieldEnabled}
              error={Boolean(formik.touched.vehicleModel && formik.errors.vehicleModel)}
              helperText={formik.errors.vehicleModel}
              onChange={transformedInputChangeHandler}
              transform="uppercase"
            />
            <StyledInput
              columns={4}
              name="vehicleColor"
              id="vehicleColor"
              value={formik.values.vehicleColor}
              disabled={isVehicleFieldEnabled}
              label="Vehicle Color"
              error={Boolean(formik.touched.vehicleColor && formik.errors.vehicleColor)}
              helperText={formik.errors.vehicleColor}
              onChange={transformedInputChangeHandler}
              transform="capitalize"
            />
          </Grid>
          <SectionTitle>
            Driver Information
          </SectionTitle>
          <Grid>
            <StyledInput
              id="email"
              name="email"
              label="Email"
              onChange={transformedInputChangeHandler}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.errors.email}
              columns={6}
              endAdornment={(<InputLoading isLoading={isDriverLoading} />)}
              transform="lowercase"
              disabled={(permitTypes.data && Boolean(permitTypes.data.length === 0))}
            />
            <StyledInputPhone
              id="phone"
              name="phone"
              disabled={isDriverFieldDisabled}
              label="Phone Number"
              value={formik.values.phone}
              onChangeValue={(newPhone) => formik.setFieldValue('phone', newPhone)}
              error={Boolean(formik.touched.phone && formik.errors.phone)}
              helperText={formik.errors.phone}
              columns={6}
            />
            <StyledInput
              id="firstName"
              name="firstName"
              disabled={isDriverFieldDisabled}
              label="First Name"
              onChange={transformedInputChangeHandler}
              value={formik.values.firstName}
              error={Boolean(formik.touched.firstName && formik.errors.firstName)}
              helperText={formik.errors.firstName}
              columns={6}
              transform="capitalize"
            />
            <StyledInput
              id="lastName"
              name="lastName"
              disabled={isDriverFieldDisabled}
              label="Last Name"
              onChange={transformedInputChangeHandler}
              value={formik.values.lastName}
              error={Boolean(formik.touched.lastName && formik.errors.lastName)}
              helperText={formik.errors.lastName}
              columns={6}
              transform="capitalize"
            />
          </Grid>
          <Row justifyContent="flex-end">
            <StyledButton
              loading={formik.isSubmitting}
              variant="contained"
              color="primary"
              type="submit"
              data-test="create-permit-modal-save-btn"
              disabled={
                  formik.isSubmitting
                  || !formik.dirty
                  || isDriverLoading
                  || isVehicleLoading
                  || !remainingPermits
                }
            >
              Save
            </StyledButton>
            <Button
              onClick={onClose}
              variant="text"
              color="primary"
            >
              Cancel
            </Button>            
          </Row>
        </ContentWrapper>
      </form>
    </>
  );
};

const StyledModal = styled(Modal)`
  @media (max-width: 1220px) {
    margin: auto 30px;
  }

  @media (max-width: 800px) {
    width: 90%;
    margin: 30px auto;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin: auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  @media (max-width: 800px) {
    padding-left: 0;
    padding-top: 20px;
    border-top: 1px solid ${vars.palette.border};
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  align-items: end;
  margin-bottom: 20px;
`;

const columnStyles = css<{ columns: number; }>`
  grid-column: span ${({ columns }) => columns};
  grid-row: span 1;

  @media (max-width: 520px) {
    grid-column: span 12;
  }
`;

const StyledInput = styled(Input)`
  ${columnStyles}
`;

const StyledInputPhone = styled(InputPhone)`
  ${columnStyles}
`;

const StyledSelect = styled(Select)`
  ${columnStyles}
` as typeof Select;

const StyledDatePicker = styled(DatePicker)`
  ${columnStyles}
`;

const IconInput = styled.img`
  padding: 0 10px;
`;

const StyledButton = styled(Button)`
  min-width:100px;
  margin-left: 10px;
`;

const SwitchWrapper = styled.div`
  ${columnStyles}
  display: flex;
  align-items: center;
`;

const PriceWrapper = styled.div<{ columns: number; isDisplay: boolean; }>`
 ${columnStyles}
 margin-left: 5px;
 display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
 justify-content: space-between;
`;

const PriceLabel = styled.span`
font-size: 13px;
font-weight: normal;
margin-top: 15px;
line-height: 19px;
`;

const PriceValue = styled.span`
font-style: normal;
font-weight: normal;
font-size: 30px;
line-height: 41px;
text-align: right;

color: #000000;
`;

const StyledNoOptionsMessage = styled.div`
  padding: 10px;
  & > a {
    color: ${vars.palette.primary};
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: ${vars.palette.primary};
    }
  }
`;

export default PermitAddModal;
