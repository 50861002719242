import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateEventRequirements } from '@zero5/garage-api';

import { eventQueryKeys } from '@/api/event/queryKeys';

type Response = void;

const useUpdateEventReqMutation = (
  options: UseMutationOptions<Response, Error, {
    eventId: number;
    requiredValetSpaces: number;
    requiredParkingSpaces: number;
    valetPrice: number;
    parkingPrice: number;
  }, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'updateEventReq',
    async (params) => {
      await updateEventRequirements({
        ...params,
      });

      return;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(eventQueryKeys.base);
        options?.onSuccess?.(...params);
      },
    },
  );
};
export default useUpdateEventReqMutation;
