import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
import flow from 'lodash/flow';

import {
  Row,
  Button,
} from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { EventStatus } from '@zero5/garage-api';

import { Event as EventType } from '@/api/models/events';

import Page from '@/components/common/page';
import EventAddModal from '@/components/modals/EventAddModal';
import EventEditModal from '@/components/modals/EventEditModal';
import Role, { useFindCurrentAction } from '@/components/common/Role';
import EventsDataCards from '@/components/events/EventsDataCards';
import EventsTable from '@/components/events/EventsTable';

import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

interface Props {}

const upcomingEventsStatuses = [EventStatus.CONFIRMED];
const requestedEventsStatuses = [EventStatus.REQUESTED, EventStatus.APPROVED_ADMIN];

const Events: React.FC<Props> = () => {
  const findCurrentAction = useFindCurrentAction();

  const [tab, setTab] = React.useState(0);
  const [currentEvent, setCurrentEvent] = useState<EventType>();
  const [isEditModalOpen, closeEditModal, openEditModal] = useToggle(false);
  const [isCreateModalOpen, closeCreateModal, openCreateModal] = useToggle(false);

  useEffect(() => {
    if (findCurrentAction('event:upcomingEvent') === 'h') {
      setTab(1);
    }
    if (findCurrentAction('event:requestEvent') === 'h') {
      setTab(0);
    }
  }, [findCurrentAction]);

  const changeTabHandler = React.useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  }, []);

  return (
      <Page>
        <Role widgetId="event:stats" action="r">
          <EventsDataCards />
        </Role>
        <Role widgetId="event" action="w">
          <StyledRow justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={openCreateModal}
              data-test="open-create-modal-event"
            >
              Create Event
            </Button>
          </StyledRow>
        </Role>
        {!(findCurrentAction('event:requestEvent') === 'h'
            && findCurrentAction('event:upcomingEvent') === 'h') && (
            <>
              <StyledTabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={tab}
                  onChange={changeTabHandler}
              >
                <Role widgetId="event:upcomingEvent" action="r">
                  <Tab label="Upcoming Events" />
                </Role>
                <Role widgetId="event:requestEvent" action="r">
                  <Tab label="Event Requests" />
                </Role>
              </StyledTabs>
              <Role widgetId="event" action="r">
                <EventsTable
                  statuses={tab === 0 ? upcomingEventsStatuses : requestedEventsStatuses}
                  onEventSelect={(event) => {
                    setCurrentEvent(event);
                    openEditModal();
                  }}
                /> 
              </Role>
            </>
        )}
        <Role widgetId="event" action="w">
          <EventAddModal open={isCreateModalOpen} onClose={closeCreateModal} />
        </Role>
        <Role widgetId="event" action="r">
          <EventEditModal
            open={isEditModalOpen}
            currentEvent={currentEvent!}
            onClose={closeEditModal}
          />
        </Role>
      </Page>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;
`;

export default flow(
  withGarage,
  withRole('events'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(Events);
